import axios from 'axios';
import {mainUrl} from './Urls';
import {ROUTES} from '../utils/ROUTES';
import Cookies from 'js-cookie';
import {COOKIES, COOKIE_EXPIRY_TIME} from '../utils/cookie-names';

const api = axios.create({
	baseURL: mainUrl,
});

// Use sessionStorage as fallback when cookies are disabled
const redirectAttemptKey = 'redirect_attempt';
let isRedirectInProgress = false;

const setRedirectFlag = () => {
	try {
		Cookies.set(COOKIES.redirect_attempt, 'true', COOKIE_EXPIRY_TIME.FIFTEEN_MINS);
	} catch (e) {
		// If cookies fail, use sessionStorage or memory variable
		try {
			sessionStorage.setItem(redirectAttemptKey, 'true');
		} catch (e) {
			// If sessionStorage also fails, use in-memory flag
			isRedirectInProgress = true;
		}
	}
};

const getRedirectFlag = () => {
	try {
		return Cookies.get(COOKIES.redirect_attempt);
	} catch (e) {
		try {
			return sessionStorage.getItem(redirectAttemptKey);
		} catch (e) {
			return isRedirectInProgress;
		}
	}
};

const clearRedirectFlag = () => {
	try {
		Cookies.remove(COOKIES.redirect_attempt);
	} catch (e) {
		try {
			sessionStorage.removeItem(redirectAttemptKey);
		} catch (e) {
			isRedirectInProgress = false;
		}
	}
};

const saveCurrentPath = () => {
	try {
		Cookies.set(COOKIES.unauthorized_redirect, window.location.pathname, COOKIE_EXPIRY_TIME.FIFTEEN_MINS);
	} catch (e) {
		try {
			sessionStorage.setItem('unauthorized_redirect', window.location.pathname);
		} catch (e) {
			// If all storage methods fail, we'll just redirect without saving the path
		}
	}
};

api.interceptors.response.use(
	(response) => response,
	(error) => {
		// Check if we're already in a redirect loop
		const isInRedirectLoop = getRedirectFlag();

		if (error.response && error.response.status === 401 && !isInRedirectLoop) {
			// Set a flag to track that we've attempted a redirect
			setRedirectFlag();
			saveCurrentPath();

			// Redirect to authentication
			window.location.href = ROUTES.AUTHENTICATE;
			return new Promise(() => {}); // Prevent further promise chain
		}

		// Clear the redirect attempt flag for non-401 errors
		if (error.response && error.response.status !== 401) {
			clearRedirectFlag();
		}

		// The existing check for bad authorization header
		if (
			error.response &&
			error.response.data &&
			error.response.data.detail === 'Authorization header must contain two space-delimited values' &&
			error.response.data.code === 'bad_authorization_header'
		) {
			window.location.href = ROUTES.AUTHENTICATE;
			return new Promise(() => {}); // Prevent further promise chain
		}

		return Promise.reject(error);
	}
);
export default api;
