import React, {useEffect, useState} from 'react';
import {getCompanyInsightsApi} from '../../api/companyInsightsApi';
import {Card, Row, Col, Statistic, Alert} from 'antd';
import {LineChart} from '@mui/x-charts/LineChart';

interface InsightsData {
	total_metrics: {
		total_jobs_posted: number;
		total_active_jobs: number;
		total_completed_jobs: number;
		total_applications: number;
		total_hired: number;
	};
	monthly_data: {
		month: string;
		jobs_posted: number;
		applications_received: number;
		hires_made: number;
	}[];
	job_categories: {[key: string]: number};
	location_metrics: {[key: string]: number};
}

export default function CompanyInsights() {
	const [insights, setInsights] = useState<InsightsData | null>(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchCompanyInsights = async () => {
			try {
				setLoading(true);
				const data = await getCompanyInsightsApi();
				setInsights(data);
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};
		fetchCompanyInsights();
	}, []);

	if (!insights) return null;

	const recentMonths = insights.monthly_data.slice(0, 6).reverse();

	return (
		<Card title="Company Insights" className="w-full lg:w-[780px] max-w-full">
			<div className=" flex flex-col gap-2">
				<div className="flex flex-wrap gap-2">
					<div className="flex-1 min-w-[250px]">
						<Card style={{height: '100%'}}>
							<Statistic title="Total Jobs Posted" value={insights.total_metrics.total_jobs_posted} />
						</Card>
					</div>
					<div className="flex-1 min-w-[250px]">
						<Card style={{height: '100%'}}>
							<Statistic title="Active Jobs" value={insights.total_metrics.total_active_jobs} />
						</Card>
					</div>
					<div className="flex-1 min-w-[250px]">
						<Card style={{height: '100%'}}>
							<Statistic title="Completed Jobs" value={insights.total_metrics.total_completed_jobs} />
						</Card>
					</div>
					<div className="flex-1 min-w-[250px]">
						<Card style={{height: '100%'}}>
							<Statistic title="Total Applications" value={insights.total_metrics.total_applications} />
						</Card>
					</div>
				</div>

				<Card title="6 Month Activity" size="small">
					<LineChart
						height={300}
						margin={{left: 40, right: 40}}
						slotProps={{
							legend: {
								hidden: true,
							},
						}}
						series={[
							{
								data: recentMonths.map((m) => m.jobs_posted),
								label: 'Jobs Posted',
							},
							{
								data: recentMonths.map((m) => m.applications_received),
								label: 'Applications',
							},
						]}
						xAxis={[
							{
								data: recentMonths.map((m) => m.month.split(' ')[0]),
								scaleType: 'point',
							},
						]}
						sx={{
							'& .MuiChartsAxis-tickLabel': {
								fontSize: '0.75rem',
							},
							'& .MuiChartsLegend-label': {
								fontSize: '0.75rem',
							},
						}}
					/>
				</Card>
				<div className="flex flex-col md:flex-row gap-2">
					<div className="w-full md:w-1/2">
						<Card title="Top Job Categories" size="small">
							{Object.entries(insights.job_categories)
								.sort(([, a], [, b]) => b - a)
								.slice(0, 5)
								.map(([category, count]) => (
									<div key={category} className="flex justify-between py-1">
										<span>{category}</span>
										<span>{count}</span>
									</div>
								))}
						</Card>
					</div>
					<div className="w-full md:w-1/2">
						<Card title="Location Distribution" size="small">
							{Object.entries(insights.location_metrics)
								.sort(([, a], [, b]) => b - a)
								.map(([location, count]) => (
									<div key={location} className="flex justify-between py-1">
										<span>{location}</span>
										<span>{count}</span>
									</div>
								))}
						</Card>
					</div>
				</div>
			</div>
		</Card>
	);
}
