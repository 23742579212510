import axios from 'axios';
import {mainUrl} from './Urls';
import {saveFCMTokenApi} from './notificationsApi';
import {capitalize} from '@mui/material';
import {GoogleAuthProvider, createUserWithEmailAndPassword, getAuth, sendEmailVerification, signInWithPopup, signOut} from 'firebase/auth';

export const signUpApi = async (email, password) => {
	try {
		const response = await axios.post(`${mainUrl}/auth/register/`, {
			email: email,
			password: password,
			terms_and_conditions: true,
			username: email,
		});

		return {success: true, email: email};
	} catch (error) {
		console.log(error.response.data);
		let errorMessage = 'An error occurred during the sign-up process.';
		if (error.response.data.errors.email) {
			errorMessage = capitalize(error.response.data.errors.email[0]);
		}
		if (error.response) {
			errorMessage = error.response.data?.email || error.response.data?.detail || errorMessage;
		} else if (error.request) {
			errorMessage = 'No response was received from the server.';
		} else {
			errorMessage = error.message || errorMessage;
		}
		return {success: false, error: errorMessage};
	}
};

export const loginApi = async (email, password) => {
	try {
		const response = await axios.post(mainUrl + '/auth/login/', {
			password: password,
			email: email,
		});
		// saveFCMTokenApi();
		return response;
	} catch (error) {
		console.error('Error during login:', error);
		throw error;
	}
};

export const loginApi2 = async (email, password) => {
	try {
		const response = await axios.post(mainUrl + '/auth/login2/', {
			password: password,
			email: email,
		});
		// saveFCMTokenApi();
		return response;
	} catch (error) {
		console.error('Error during login:', error);
		throw error;
	}
};

export const googleAuthApi = async (authToken) => {
	try {
		const response = await axios.post(mainUrl + '/social_auth/google/', {
			auth_token: authToken.credential,
		});
		// saveFCMTokenApi();
		return response;
	} catch (error) {
		console.error('Error during Google authentication:', error);
		throw error;
	}
};

export const getUser = async () => {
	try {
		const response = await axios.get(mainUrl + '/auth/me/', {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});

		// saveFCMTokenApi();
		return response;
	} catch (error) {
		console.error('Error fetching user:', error);
		throw error;
	}
};

export const getPageUserApi = async (userId) => {
	try {
		const {data} = await axios.get(`${mainUrl}/auth/user/${userId}/`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		return data;
	} catch (error) {
		console.error(error);
	}
};

// FIREBASE AUTH ////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const firebaseGoogleAuthApi = async () => {
	const auth = getAuth();
	const provider = new GoogleAuthProvider();

	try {
		const result = await signInWithPopup(auth, provider);
		const user = result.user;
		const idToken = await user.getIdToken();
		const response = await axios.post(mainUrl + '/auth/firebase-auth/', {
			idToken: idToken,
		});
		console.log(response.data);

		return response.data;
	} catch (error) {
		console.error('Error during Google sign-in:', error);
	}
};

export const firebaseLogout = async () => {
	try {
		const auth = getAuth();
		await signOut(auth);
	} catch (error) {
		console.error('Error during sign-out:', error);
	}
};

export const signUpWithEmailPassword = async (email, password) => {
	const auth = getAuth();
	try {
		const userCredential = await createUserWithEmailAndPassword(auth, email, password);
		console.log('User created:', userCredential.user);
		if (userCredential.user) {
			await sendEmailVerification(userCredential.user);
			console.log('Verification email sent');
		}
	} catch (error) {
		console.error('Error signing up:', error);
	}
};
