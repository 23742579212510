import {Card, Button, Alert} from 'antd';
import {AutoAwesome} from '@mui/icons-material';
import {permColors} from '../../utils/colors';
import {CloseOutlined} from '@ant-design/icons';
import React, {useState, useEffect} from 'react';
import {isJobSeeker} from '../../utils/variables/userVariables';
import {COUNTRY_CODE} from '../../api/locationApi';
import AiInfoModal from './ai-info-modal';

export function AiJobMatchCardAd({user, country}) {
	const [isVisible, setIsVisible] = useState(true);
	const [isClosing, setIsClosing] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleCloseModal = () => {
		setIsModalVisible(false);
	};

	useEffect(() => {
		// Check if the card has been closed before
		const cardClosed = localStorage.getItem('aiJobMatchCardClosed') === 'true';
		if (cardClosed) {
			setIsVisible(false);
		}
	}, []);

	const handleClose = (e) => {
		e.stopPropagation(); // Prevent card click event
		setIsClosing(true);
		localStorage.setItem('aiJobMatchCardClosed', 'true');
		// Allow the fade and scale transition to complete before hiding the card
		setTimeout(() => {
			setIsVisible(false);
		}, 300);
	};

	if (!isVisible || isJobSeeker(user) || country === COUNTRY_CODE.PHILIPPINES) return null;

	return (
		<Card
			className="mb-4 border border-dashed border-gray-300 hover:border-blue-400 transition-all cursor-pointer w-full 2xl:w-[500px] max-w-full text-left"
			size="small"
			style={{
				opacity: isClosing ? 0 : 1,
				transform: isClosing ? 'scale(0)' : 'scale(1)',
				transition: 'opacity 0.3s ease, transform 0.3s ease',
			}}>
			<div className="flex items-start gap-3 p-2">
				<div className="bg-blue-50 p-3 rounded-full">
					<AutoAwesome style={{color: permColors.antBlue, fontSize: '24px'}} />
				</div>
				<div className="flex-1">
					<div className="text-lg font-semibold mb-1 text-left">AI-Powered Candidate Feedback</div>
					<p className="text-sm font-medium text-antBlue bg-lightBlue/50 p-2 rounded-md mb-2 w-fit">Included with every Basic and Premium Job Ad</p>
					<p className="text-sm text-gray-600">Enhance your hiring process, receive detailed feedback on candidate suitability, powered by AI.</p>
					<AiInfoModal buttonClassName="mt-2" />
					{/* <Button type="link" className="bg-backgroundGreyBlue mt-2" onClick={() => setIsModalVisible(true)}>
						Learn More
					</Button> */}
				</div>
				<CloseOutlined className="cursor-pointer hover:text-red" onClick={handleClose} />
			</div>
		</Card>
	);
}

// user type is employer, if no user then check country - if country is philippines then dont show
