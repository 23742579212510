import {EyeOutlined, MessageOutlined, SendOutlined} from '@ant-design/icons';
import {QuestionMark, Send, ThumbDown, ThumbUp} from '@mui/icons-material';
import {Alert} from '@mui/material';
import {Alert as AntAlert, Button} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import MessagePopup from '../../components/Popups/MessagePopup';
import {useState} from 'react';
import {formatDate2} from '../../utils/DateFunctions';
import {ROUTES} from '../../utils/ROUTES';

const fitMappings = {
	Good_fit: {
		message: 'Good fit',
		severity: 'success',
	},
	Not_a_fit: {
		message: 'Not a fit',
		severity: 'error',
	},
	default: {
		message: 'No decision provided',
		severity: 'info',
	},
};

export function returnApplicantFit(candidateFit) {
	const {message, severity} = fitMappings[candidateFit] || fitMappings.default;
	return <AntAlert banner type={severity} className="flex p-1 px-2 text-sm font-normal rounded-md mt-1" message={message} showIcon />;
}

export function returnApplicantFitSmall(candidateFit) {
	const {message, severity} = fitMappings[candidateFit] || fitMappings.default;
	return <AntAlert banner type={severity} className="flex p-1 px-2 text-xs rounded-md mt-1 whitespace-nowrap" message={message} showIcon />;
}

export function returnApplicantSelectThumbs(submitCandidateFitChange, selectedApplicant, loading) {
	const noOp = () => {};
	const handleClick = loading ? noOp : submitCandidateFitChange;

	return (
		<div className={`flex items-center thumb-group w-fit ${loading ? 'loading' : ''}`}>
			<div
				onClick={() => handleClick('Good_fit')}
				className={`p-2 px-3 thumb thumb-1 ${selectedApplicant.candidate_fit === 'Good_fit' ? 'thumb-selected' : ''} ${loading ? 'thumb-disabled' : ''}`}>
				<ThumbUp />
			</div>
			<div
				onClick={() => handleClick('Undecided')}
				className={`p-2 px-3 thumb thumb-2 ${selectedApplicant.candidate_fit === 'Undecided' ? 'thumb-selected' : ''} ${loading ? 'thumb-disabled' : ''}`}>
				<QuestionMark />
			</div>
			<div
				onClick={() => handleClick('Not_a_fit')}
				className={`p-2 px-3 thumb thumb-3 ${selectedApplicant.candidate_fit === 'Not_a_fit' ? 'thumb-selected' : ''} ${loading ? 'thumb-disabled' : ''}`}>
				<ThumbDown />
			</div>
		</div>
	);
}

export function ReturnApplicantOtherButtons({selectedApplicant}) {
	const navigate = useNavigate();
	const [popup, setPopup] = useState(false);

	return (
		<div className="flex items-center gap-2 sm:ml-5 flex-wrap">
			<p className="text-sm text-gray-500">Applied {formatDate2(selectedApplicant.created_at)}</p>
			<Button icon={<SendOutlined />} onClick={() => setPopup(true)}>
				Message
			</Button>
			<Link to={`${ROUTES.USER_PROFILE.replace(':userId', selectedApplicant.freelancer.id)}`} className="text-sm text-blue-500 hover:text-blue-700 hover:underline">
				View Profile
			</Link>
			<MessagePopup
				showPopup={popup}
				closePopup={() => setPopup(false)}
				title={'Send a message to ' + selectedApplicant?.freelancer?.profile?.first_name}
				textArea={true}
				buttonText={'Send'}
				user={selectedApplicant?.freelancer?.id}
				disabledButtonText={'Sent'}
			/>
		</div>
	);
}
