import axios from 'axios';
import Cookies from 'js-cookie';

const getCountryApi = async () => {
	try {
		const response = await axios.get('https://api.country.is');
		return response.data.country;
	} catch (error) {
		console.error('Error fetching country code:', error);
		return 'USD';
	}
};

export const COUNTRY_CODE = {
	CHINA: 'CN',
	INDIA: 'IN',
	UNITED_STATES: 'US',
	INDONESIA: 'ID',
	PAKISTAN: 'PK',
	BRAZIL: 'BR',
	NIGERIA: 'NG',
	BANGLADESH: 'BD',
	RUSSIA: 'RU',
	MEXICO: 'MX',
	JAPAN: 'JP',
	ETHIOPIA: 'ET',
	PHILIPPINES: 'PH',
	EGYPT: 'EG',
	VIETNAM: 'VN',
	DEMOCRATIC_REPUBLIC_OF_THE_CONGO: 'CD',
	TURKEY: 'TR',
	IRAN: 'IR',
	GERMANY: 'DE',
	FRANCE: 'FR',
	UNITED_KINGDOM: 'GB',
	THAILAND: 'TH',
	SOUTH_AFRICA: 'ZA',
	TANZANIA: 'TZ',
	ITALY: 'IT',
	MYANMAR: 'MM',
	KENYA: 'KE',
	SOUTH_KOREA: 'KR',
	COLOMBIA: 'CO',
	SPAIN: 'ES',
	AUSTRALIA: 'AU',
	CANADA: 'CA',
};

export const COUNTRY_RANDOM_CODES = {
	CHINA: 'a7b9c2d4e6', // CHINA
	INDIA: 'f3g5h8i1j0', // INDIA
	UNITED_STATES: 'k2l4m6n8p0', // UNITED_STATES
	INDONESIA: 'q9r7s5t3u1', // INDONESIA
	PAKISTAN: 'v2w4x6y8z0', // PAKISTAN
	BRAZIL: 'a1b3c5d7e9', // BRAZIL
	NIGERIA: 'f2g4h6i8j0', // NIGERIA
	BANGLADESH: 'k3l5m7n9p1', // BANGLADESH
	RUSSIA: 'q2r4s6t8u0', // RUSSIA
	MEXICO: 'v3w5x7y9z1', // MEXICO
	JAPAN: 'a4b6c8d0e2', // JAPAN
	ETHIOPIA: 'f5g7h9i1j3', // ETHIOPIA
	PHILIPPINES: 'k6l8m0n2p4', // PHILIPPINES
	EGYPT: 'q7r9s1t3u5', // EGYPT
	VIETNAM: 'v8w0x2y4z6', // VIETNAM
	DEMOCRATIC_REPUBLIC_OF_THE_CONGO: 'a9b1c3d5e7', // DEMOCRATIC_REPUBLIC_OF_THE_CONGO
	TURKEY: 'f0g2h4i6j8', // TURKEY
	IRAN: 'k1l3m5n7p9', // IRAN
	GERMANY: 'q0r2s4t6u8', // GERMANY
	FRANCE: 'v1w3x5y7z9', // FRANCE
	UNITED_KINGDOM: 'a2b4c6d8e0', // UNITED_KINGDOM
	THAILAND: 'f3g5h7i9j1', // THAILAND
	SOUTH_AFRICA: 'k4l6m8n0p2', // SOUTH_AFRICA
	TANZANIA: 'q5r7s9t1u3', // TANZANIA
	ITALY: 'v6w8x0y2z4', // ITALY
	MYANMAR: 'a7b9c1d3e5', // MYANMAR
	KENYA: 'f8g0h2i4j6', // KENYA
	SOUTH_KOREA: 'k9l1m3n5p7', // SOUTH_KOREA
	COLOMBIA: 'q0r2s4t6u8', // COLOMBIA
	SPAIN: 'v9w1x3y5z7', // SPAIN
	AUSTRALIA: 'a0b2c4d6e8', // AUSTRALIA
	CANADA: 'f1g3h5i7j9', // CANADA
};

const bannedCountries = [
	COUNTRY_RANDOM_CODES.INDIA,
	COUNTRY_RANDOM_CODES.PAKISTAN,
	COUNTRY_RANDOM_CODES.EGYPT,
	COUNTRY_RANDOM_CODES.IRAN,
	COUNTRY_RANDOM_CODES.MYANMAR,
	COUNTRY_RANDOM_CODES.INDONESIA,
	COUNTRY_RANDOM_CODES.BANGLADESH,
];

const RANDOM_COOKIE_NAME = 'asdkahb13bj2b2jbakdb';

export const getCountryCode = async () => {
	const countryCode = Cookies.get('countryCode');
	if (countryCode) {
		return countryCode;
	} else {
		const country = await getCountryApi();
		Cookies.set('countryCode', country, {expires: 20});
		const randomCode = Object.entries(COUNTRY_CODE).find(([key, value]) => value === country)?.[0];
		if (randomCode && COUNTRY_RANDOM_CODES[randomCode]) {
			Cookies.set(RANDOM_COOKIE_NAME, COUNTRY_RANDOM_CODES[randomCode], {expires: 20});
		}

		return country;
	}
};

export const isBannedCountry = () => {
	const bannedCountry = Cookies.get(RANDOM_COOKIE_NAME);
	if (bannedCountry) {
		return bannedCountries.includes(bannedCountry);
	}
	return false;
};
