import {atom} from 'jotai';

export const billingHistoryAtom = atom<any[]>([]);
export const billingHistoryTriggeredAtom = atom<boolean>(false);

export const jobsAtom = atom<any[]>([]);
export const jobsTriggeredAtom = atom<boolean>(false);

export const closedJobsAtom = atom<any[]>([]);
export const closedJobsTriggeredAtom = atom<boolean>(false);

export const conversationsAtom = atom<any[]>([]);
export const conversationsTriggeredAtom = atom<boolean>(false);
export const displayedConversationsAtom = atom<any[]>([]);

export const cardDetailsAtom = atom<any>(null);
export const paymentMethodTriggeredAtom = atom<boolean>(false);

interface InsightsData {
	total_metrics: {
		total_jobs_posted: number;
		total_active_jobs: number;
		total_completed_jobs: number;
		total_applications: number;
		total_hired: number;
	};
	monthly_data: {
		month: string;
		jobs_posted: number;
		applications_received: number;
		hires_made: number;
	}[];
	job_categories: {[key: string]: number};
	location_metrics: {[key: string]: number};
}

export const companyInsightsAtom = atom<InsightsData | null>(null);
export const companyInsightsTriggeredAtom = atom<boolean>(false);
