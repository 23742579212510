import React from 'react';
import './LandingPage.scss';
import {capitalizeFirstLetter} from '../../utils/TextAlterations';
import {daysAgo} from '../../utils/DateFunctions';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import {permColors} from '../../utils/colors';
import {Button, message, Tag} from 'antd';
import {CopyOutlined} from '@ant-design/icons';
import {ROUTES} from '../../utils/ROUTES';
import {AttachMoney} from '@mui/icons-material';
import {formatCurrency} from '../../utils/currency';

interface JobCardProps {
	id: string;
	job: any;
	isSelected: boolean;
	onJobSelect: any;
	authenticatedButtons?: any;
}

export function JobCard({id, job, isSelected, onJobSelect, authenticatedButtons}: JobCardProps) {
	return (
		<React.Fragment>
			<div
				id={id}
				onClick={() => onJobSelect(job)}
				className={`transition-all duration-300 hover:shadow-[0px_0px_0px_1px_#082e6e] border border-[#e0e0e0] cursor-pointer text-left p-6 rounded-lg flex flex-col justify-between gap-1 w-full 2xl:w-[500px] max-w-full ${
					isSelected ? 'shadow-[0px_0px_0px_2px_#3da9fc] hover:shadow-[0px_0px_0px_2px_#3da9fc]' : ''
				}`}>
				<div className="flex items-center justify-between gap-2 mb-4">
					<div className="rounded-full border border-gray-200 p-2 w-fit">
						{!job?.created_by_image ? <WorkOutlineOutlinedIcon className="working-icon " /> : <img src={job?.created_by_image} alt="profile" className="working-icon p-0" />}
					</div>
					<div className="flex flex-row items-end gap-2">
						<Button
							className="rounded-md min-h-fit"
							onClick={() => {
								navigator.clipboard.writeText(window.location.origin + ROUTES.JOB_PAGE.replace(':jobId', job.id));
								message.success('Job URL copied to clipboard', 2);
							}}
							icon={<CopyOutlined />}>
							Copy URL
						</Button>
						{authenticatedButtons && authenticatedButtons(job)}
					</div>
				</div>

				<p className="text-sm sm:text-base font-semibold flex items-center gap-2" style={{color: permColors.charcoal}}>
					{job?.company_name && capitalizeFirstLetter(job?.company_name)}
					<span className="text-sm sm:text-base font-light opacity-80" style={{color: permColors.grey}}>
						{job.created_at !== null ? `Posted ${daysAgo(job.created_at)} day${daysAgo(job.created_at) === 1 ? '' : 's'} ago` : ''}
					</span>
				</p>
				<span className="text-xl sm:text-2xl font-medium mb-2" style={{color: permColors.darkCharcoal}}>
					{capitalizeFirstLetter(job.title)}
				</span>
				<div className="flex items-center flex-wrap gap-2">
					<Tag className="text-sm m-0">{job.engagement_type}</Tag>
					<Tag className="text-sm m-0">{job.category}</Tag>
					<Tag className="text-sm m-0">{job.job_type}</Tag>
				</div>
				<hr className="mt-4 mb-2" />
				<div className="flex items-center justify-between">
					<div>
						{job?.payment_rate_from && job?.show_salary_on_job && (
							<div className="flex items-center gap-0">
								<span className="text-sm" style={{color: permColors.charcoal}}>
									<span className="font-semibold">Salary:</span> {formatCurrency(job?.payment_rate_from, job?.payment_rate_to, job?.payment_rate_type, job?.payment_rate_currency)}
								</span>
							</div>
						)}

						<p className="text-sm text-charcoal">
							<span className="mr-1 font-semibold">Company location:</span>
							{job.country}
						</p>
					</div>
				</div>
			</div>
			<br />
		</React.Fragment>
	);
}
