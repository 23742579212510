import React from 'react';
import './SmallItems.scss';
import {Link} from 'react-router-dom';
import logoCircleLight from '../../assets/logos/logoCircleLight.png';
import logoCircleDark from '../../assets/logos/logoCircleDark.png';
import {permColors} from '../../utils/colors';

export function FullLogo({fontWeight = 600, letterSpacing = ''} = {}) {
	return (
		<div className="fullLogo">
			<Link to="/" className="flex items-center gap-1">
				<img className="fullLogoImg" src={logoCircleDark} alt="permworks-logo" />
				<span style={{fontWeight: fontWeight, letterSpacing: letterSpacing}}>{'Permworks'}</span>
			</Link>
		</div>
	);
}

export function FullLogoSmall({fontWeight = 500, letterSpacing = '', className = '', color = permColors.darkBlue} = {}) {
	return (
		<div className={'fullLogoSmall ' + className}>
			<Link to="/" className="flex items-center gap-1">
				<img className="fullLogoImg" src={logoCircleDark} alt="permworks-logo" />
				<span style={{fontWeight: fontWeight, letterSpacing: letterSpacing, color: color}}>Permworks</span>
			</Link>
		</div>
	);
}
export function FullLogoSmallNav({fontWeight = 500, letterSpacing = '', className = '', color = permColors.darkBlue} = {}) {
	return (
		<div className={className}>
			<Link to="/" className="flex items-center gap-2">
				<img className="w-4 h-4 object-contain" src={logoCircleDark} alt="permworks-logo" />
				<span className="text-[18px]" style={{fontWeight: fontWeight, letterSpacing: letterSpacing, color: color}}>
					Permworks
				</span>
			</Link>
		</div>
	);
}
export function FullLogoSmallNavLight({fontWeight = 500, letterSpacing = '', className = '', color = permColors.white} = {}) {
	return (
		<div className={className}>
			<Link to="/" className="flex items-center gap-2">
				<img className="w-4 h-4 object-contain" src={logoCircleLight} alt="permworks-logo" />
				<span className="text-[18px]" style={{fontWeight: fontWeight, letterSpacing: letterSpacing, color: color}}>
					Permworks
				</span>
			</Link>
		</div>
	);
}
export function FullLogoSmallNavDashboard({fontWeight = 500, letterSpacing = '', className = '', color = permColors.darkBlue} = {}) {
	return (
		<div className={className}>
			<Link to="/" className="flex items-center gap-2">
				<img className="w-4 h-4 object-contain" src={logoCircleDark} alt="permworks-logo" />
				<span className="text-[18px]" style={{fontWeight: fontWeight, letterSpacing: letterSpacing, color: color}}>
					Permworks
				</span>
			</Link>
		</div>
	);
}

export function LightLogo({fontWeight = 600, letterSpacing = '', textVisibility = true, style = {}} = {}) {
	return (
		<div className="lightLogo" style={style}>
			<Link to="/" className="flex items-center gap-1">
				<img className="lightLogoImg" src={logoCircleLight} alt="permworks-logo" />
				{textVisibility && <span style={{fontWeight: fontWeight, letterSpacing: letterSpacing}}>Permworks</span>}
			</Link>
		</div>
	);
}

export function LightFullLogoSmall({fontWeight = 500, letterSpacing = '', style} = {}) {
	return (
		<div className="lightFullLogoSmall" style={style}>
			<Link to="/" className="flex items-center gap-1">
				<img className="fullLogoImg" src={logoCircleLight} alt="permworks-logo" />
				<span style={{fontWeight: fontWeight, letterSpacing: letterSpacing}}>Permworks</span>
			</Link>
		</div>
	);
}

export function LightLogoLarger() {
	return (
		<div className="lightLogoLarger">
			<Link to="/" className="flex items-center gap-1">
				<img className="lightLogoLargerImg" src={logoCircleLight} alt="permworks-logo" />
				<span>Permworks</span>
			</Link>
		</div>
	);
}
