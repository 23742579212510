import React, {useState} from 'react';
import {Layout, Menu, Button} from 'antd';
import Navbar from '../../components/Navbars/Navbar2';
import {UserSwitchOutlined, FileTextOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined} from '@ant-design/icons';
import {useMediaQuery} from '@material-ui/core';
import Logs from '../../components/admin-components/Logs';
import Ghosting from '../../components/admin-components/Ghosting';
import UserActivityDashboard from '../../components/admin-components/UserData';
import ErrorLogs from '../../components/admin-components/ErrorLogs';
import BooleanSearch from '../../components/admin-components/BooleanSearch';
const {Content, Sider} = Layout;

export default function AdminPage() {
	const [selectedKey, setSelectedKey] = useState('ghost');
	const [collapsed, setCollapsed] = useState(false);
	const isMobile = useMediaQuery('(max-width: 768px)');

	const items = [
		{
			key: 'ghost',
			icon: <UserSwitchOutlined />,
			label: 'Ghost Mode',
		},
		{
			key: 'logs',
			icon: <FileTextOutlined />,
			label: 'System Logs',
		},
		{
			key: 'user-data',
			icon: <UserOutlined />,
			label: 'User Data',
		},
		{
			key: 'error-logs',
			icon: <FileTextOutlined />,
			label: 'Error Logs',
		},
		{
			key: 'boolean-tool',
			icon: <FileTextOutlined />,
			label: 'Boolean Tool',
		},
		!isMobile && {
			key: 'collapse',
			icon: collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />,
			label: collapsed ? 'Expand menu' : 'Collapse menu',
			onClick: () => setCollapsed(!collapsed),
		},
	];

	const renderContent = () => {
		switch (selectedKey) {
			case 'ghost':
				return <Ghosting />;
			case 'logs':
				return <Logs />;
			case 'user-data':
				return <UserActivityDashboard />;
			case 'error-logs':
				return <ErrorLogs />;
			case 'boolean-tool':
				return <BooleanSearch />;
			default:
				return null;
		}
	};

	return (
		<div>
			<Navbar style={{borderBottom: '1px solid #e0e0e090'}} />
			<Layout className="h-[calc(100dvh-80px)] overflow-y-auto">
				{!isMobile && (
					<Sider width={200} theme="light" className="" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} trigger={null}>
						<Menu className="p-1" selectedKeys={[selectedKey]} style={{height: '100%'}} onClick={({key}) => key !== 'collapse' && setSelectedKey(key)} items={items} />
					</Sider>
				)}
				<Layout className="bg-white">
					{isMobile && <Menu mode="horizontal" selectedKeys={[selectedKey]} onClick={({key}) => key !== 'collapse' && setSelectedKey(key)} items={items} />}
					<Content className="bg-white p-3 flex justify-start ">{renderContent()}</Content>
				</Layout>
			</Layout>
		</div>
	);
}
