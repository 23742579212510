import React from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar2 from '../../components/Navbars/Navbar2';
import './HowItWorks.scss';
import {Helmet} from 'react-helmet';
import firstSegment from '../../assets/images/firstSegment.png';
import peopleLaughing from '../../assets/images/peopleLaugh.jpeg';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import {useMediaQuery} from '@mui/material';
import {EXTERNAL_ROUTES, ROUTES} from '../../utils/ROUTES';
import {Link} from 'react-router-dom';
import {ArrowRightOutlined} from '@ant-design/icons';
import {AutoAwesome} from '@mui/icons-material';
import {permColors} from '../../utils/colors';
import AiInfoModal from '../../components/landing-page-items/ai-info-modal';

const SegmentPart = ({Icon, title, text}) => {
	return (
		<div className="segment-part mb-4">
			<Icon className="icon" />
			<div className="segment-part-text">
				<h3 className="text-lg font-semibold text-darkCharcoal">{title}</h3>
				<p className="text-sm text-charcoal">{text}</p>
			</div>
		</div>
	);
};

const HowItWorks = () => {
	const matches = useMediaQuery('(min-width: 1050px)');
	return (
		<div>
			<Helmet>
				<title>How It Works | Permworks</title>
				<meta name="description" content="Learn how Permworks operates. Understand the process of discovering jobs or posting job listings, and how to connect with global talent." />
			</Helmet>
			<Navbar2 />
			<div className="how-it-works-container">
				<h1 className="text-2xl md:text-3xl font-normal text-darkCharcoal mb-8">
					How it works for <span className="font-semibold">Employers</span>
				</h1>
				<div className="first-segment my-8">
					<div className="first-segment-content mx-4">
						<h2 className="mb-2 text-xl font-semibold text-darkCharcoal">Job Ad Posting</h2>
						<p className="mb-4 text-base text-charcoal">Post an ad on Permworks jobs board for any role</p>
						<SegmentPart
							Icon={WorkOutlineIcon}
							title="1. Post a job ad"
							text="Create an account and post detailed job listings within minutes for any role in the Philippines. Our streamlined process makes it easy to specify requirements, responsibilities and qualifications."
							className="mb-4"
						/>
						<SegmentPart
							Icon={PeopleAltOutlinedIcon}
							title="2. Screen and select"
							text="Review applications through your personalized employer dashboard. Message candidates directly and efficiently manage your recruitment pipeline all in one place."
							className="mb-4"
						/>
						<SegmentPart Icon={ThumbUpAltOutlinedIcon} title="3. Hire" text="Once you've found the perfect candidate, extend an offer and begin the onboarding process." className="mb-6" />

						<div className="mb-6 p-3 bg-lightBlue/50 rounded-md border border-blue-200">
							<div className="flex items-start gap-2">
								<AutoAwesome style={{color: permColors.antBlue, fontSize: '20px'}} className="mt-1" />
								<div>
									<p className="text-lg font-semibold mb-1 text-left">AI-Powered Candidate Feedback</p>
									<p className="text-sm text-gray-600">Enhance your hiring process, receive detailed feedback on candidate suitability, powered by AI.</p>
									<p className="text-sm text-antBlue">
										Included with every <span className="font-medium">Basic</span> and <span className="font-medium">Premium</span> Job Ad
									</p>
									<AiInfoModal buttonClassName="mt-2" />
								</div>
							</div>
						</div>

						<Link to={ROUTES.POST_JOB} className="mt-4">
							<div className="flex items-center gap-2 group hover:text-blue-500 duration-300">
								Post a job
								<ArrowRightOutlined className="transition-transform duration-300 group-hover:translate-x-2" />
							</div>
						</Link>
					</div>
					{matches && <img src={firstSegment} alt="first segment" className="first-segment-image" />}
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default HowItWorks;
