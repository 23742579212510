import React from 'react';
import {Select as AntSelect, Button, Select} from 'antd';
import {countries} from '../../utils/arrayData/CountryArray';
import {datePostedOptions} from '../../utils/arrayData/JobsData';
import {isFilterOnAtom} from '../../utils/atoms/filterAtoms';
import {useAtom} from 'jotai';
import {CloseOutlined, DeleteOutlined} from '@ant-design/icons';

const {Option} = AntSelect;

const options = countries.map((country) => {
	return {value: country};
});

const JobFilters = ({
	category,
	setCategory,
	jobType,
	setJobType,
	monthlySalary,
	setMonthlySalary,
	engagementType,
	setEngagementType,
	categoryOptionsArray,
	jobTypeOptionsArray,
	monthlySalaryOptions,
	engagementOptions,
	searchFunction,
	countryFilter,
	setCountryFilter,
	datePosted,
	setDatePosted,
	jobLoading,
	clearFilter,
}) => {
	return (
		<div className="filterContainer flex">
			{/* Date Posted Filter */}
			{/* <Select
				style={{width: '100px', flexGrow: 1}}
				placeholder="Date posted"
				className=""
				value={datePosted}
				onChange={(value) => setDatePosted(value === '' ? undefined : value)}
				disabled={jobLoading}>
				<Option value="">Date posted</Option>
				{datePostedOptions.map((dP) => (
					<Option key={dP} value={dP}>
						{dP}
					</Option>
				))}
			</Select> */}

			{/* Category Filter */}
			<Select
				style={{width: '250px', flexGrow: 1}}
				placeholder="Select Category"
				className=""
				value={category}
				onChange={(value) => setCategory(value === '' ? undefined : value)}
				disabled={jobLoading}>
				<Option value="">Select Category</Option>
				{categoryOptionsArray.map((cat) => (
					<Option key={cat} value={cat}>
						{cat}
					</Option>
				))}
			</Select>

			{/* Job Type Filter */}
			<Select style={{width: '200px', flexGrow: 1}} placeholder="Select Job Type" value={jobType} onChange={(value) => setJobType(value === '' ? undefined : value)} disabled={jobLoading}>
				<Option value="">Select Job Type</Option>
				{jobTypeOptionsArray.map((type) => (
					<Option key={type} value={type}>
						{type}
					</Option>
				))}
			</Select>

			{/* Monthly Salary Filter */}
			{/* <Select
				style={{width: '250px', flexGrow: 1}}
				placeholder="Select Monthly Salary (USD)"
				value={monthlySalary}
				onChange={(value) => setMonthlySalary(value === '' ? undefined : value)}
				disabled={jobLoading}>
				<Option value="">Select Monthly Salary</Option>
				{monthlySalaryOptions.map((salary) => (
					<Option key={salary} value={salary}>
						{salary}
					</Option>
				))}
			</Select> */}

			{/* Engagement Type Filter */}
			<Select
				style={{width: '250px', flexGrow: 1}}
				placeholder="Select Engagement Type"
				value={engagementType}
				onChange={(value) => setEngagementType(value === '' ? undefined : value)}
				disabled={jobLoading}>
				<Option value="">Select Engagement Type</Option>
				{engagementOptions.map((engagement) => (
					<Option key={engagement} value={engagement}>
						{engagement}
					</Option>
				))}
			</Select>

			{/* Country Filter */}
			<Select
				placeholder="Filter by Countries"
				style={{width: '250px', flexGrow: 1}}
				value={countryFilter?.length > 0 ? countryFilter : undefined}
				onChange={(value) => setCountryFilter(value || [])}
				mode="multiple"
				allowClear
				disabled={jobLoading}>
				{options.map((option) => (
					<Option key={option.value} value={option.value} disabled={option.value === '----'} className={option.value === '----' ? 'opacity-30' : ''}>
						{option.value}
					</Option>
				))}
			</Select>
			{(category || jobType || monthlySalary || engagementType || countryFilter.length > 0 || datePosted) && (
				<Button type="primary" className="w-full sm:w-fit min-h-[40px]" onClick={clearFilter} icon={<CloseOutlined />}>
					Clear
				</Button>
			)}
		</div>
	);
};

export default JobFilters;
