import React, {useEffect, useState} from 'react';
import './DashboardComponent.scss';
import {Link, useNavigate} from 'react-router-dom';
import {getMyJobsApi, deleteJobApi} from '../../api/jobsApi';
import {textFormatter, capitalizeFirstLetterFirstLetter, capitalizeFirstLetter} from '../../utils/TextAlterations';
import LoadingPartial from '../Loaders/LoadingPartial';
import {Table, Button, Space, Popconfirm, Typography, Input, Select, Tooltip, Card, Tag} from 'antd';
import {filterByJobType, filterBySearchTerm} from '../../utils/filters';
import {DeleteOutlined, EditOutlined, EyeFilled} from '@ant-design/icons';
import {ROUTES} from '../../utils/ROUTES';
import {useAtom} from 'jotai';
import {jobsAtom, jobsTriggeredAtom} from '../../utils/atoms/dashboardAtoms';
import {triggerFetchingJobsAfterCloseAtom} from '../../utils/atoms';
import ApplyJobButton from '../ApplyJob/ApplyJobButton';
import {Queue, WorkOutline} from '@mui/icons-material';

export default function EmployerJob() {
	const [jobs, setJobs] = useAtom(jobsAtom);
	const [jobsTriggered, setJobsTriggered] = useAtom(jobsTriggeredAtom);
	const [triggerFetchingJobsAfterClose, setTriggerFetchingJobsAfterClose] = useAtom(triggerFetchingJobsAfterCloseAtom);
	const [loading, setLoading] = useState(true);
	const [pageNumber, setPageNumber] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');
	const [jobTypeFilter, setJobTypeFilter] = useState('all');
	const navigate = useNavigate();

	useEffect(() => {
		const resizeObserverError = window.addEventListener('error', (e) => {
			if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
				e.stopImmediatePropagation();
			}
		});
		if (!jobsTriggered) {
			fetchMyJobs();
		} else {
			setLoading(false);
		}

		if (triggerFetchingJobsAfterClose) {
			fetchMyJobs();
			setTriggerFetchingJobsAfterClose(false);
		}
	}, [triggerFetchingJobsAfterClose]);

	const fetchMyJobs = async () => {
		setLoading(true);
		const response = await getMyJobsApi();
		setLoading(false);
		if (response) {
			console.log('responsible', response);
			setJobs(response);
		}
		setJobsTriggered(true);
	};

	const navigatePostJob = () => {
		navigate(ROUTES.POST_JOB);
	};

	const columns = [
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			render: (text, record) => (
				<Link className="underline underline-offset-1 text-antBlue hover:text-antBlue/70" to={`${ROUTES.JOB_PAGE.replace(':jobId', record.id)}`}>
					{capitalizeFirstLetter(text)}
				</Link>
			),
		},
		{
			title: 'Company name',
			dataIndex: 'company_name',
			key: 'company_name',
			render: capitalizeFirstLetter,
		},
		{
			title: 'Type',
			dataIndex: 'job_type',
			key: 'job_type',
		},
		{
			title: 'Engagement',
			dataIndex: 'engagement_type',
			key: 'engagement_type',
		},
		{
			title: 'Subscription type',
			dataIndex: 'subscription_type',
			key: 'subscription_type',
			render: (text) => <Tag color={text === 'free' ? 'default' : text === 'basic' ? 'blue' : 'gold'}>{text}</Tag>,
		},
		{
			title: 'Total applications',
			dataIndex: 'applicants',
			key: 'applicants',
			render: (text, record) => <Link to={ROUTES.APPLICANTS.replace(':jobId', record.id)}>{!record?.use_external_link && record.applicants.length}</Link>,
		},
		{
			title: 'Unique external clicks',
			dataIndex: 'unique_external_link_clicks',
			key: 'unique_external_link_clicks',
			render: (text, record) => <Link to={ROUTES.APPLICANTS.replace(':jobId', record.id)}>{record?.use_external_link && record?.unique_external_link_clicks}</Link>,
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<div className="flex items-center gap-2">
					<ApplyJobButton job={record} />
					<Button icon={<EyeFilled />} onClick={() => navigate(ROUTES.APPLICANTS.replace(':jobId', record.id))}>
						View all applicants
					</Button>
				</div>
			),
		},
	];

	const filteredJobs = jobs.filter(filterBySearchTerm(searchTerm)).filter(filterByJobType(jobTypeFilter));

	if (loading) {
		return <LoadingPartial />;
	}

	return (
		<div className="dashboardComponent">
			<Card classNames={{body: 'p-2'}} className="mb-2">
				<div className="flex justify-between items-center gap-2 flex-wrap">
					<h2 className="flex items-center gap-2 px-2 text-lg font-semibold text-darkCharcoal">
						<WorkOutline className="text-base" /> My open jobs
					</h2>
					<div className="flex flex-wrap gap-2">
						<Input type="text" placeholder="Search by job title" onChange={(e) => setSearchTerm(e?.target?.value?.toLowerCase?.() || '')} className="p-2 w-[250px] mobile-width-100" />
						<Select
							defaultValue="all"
							className="grow sm:w-[250px]"
							options={[
								{label: 'All Job Types', value: 'all'},
								{label: 'Full Time', value: 'full-time'},
								{label: 'Part Time', value: 'part-time'},
							]}
							onChange={(e) => setJobTypeFilter(e || 'all')}
						/>
						<Button onClick={navigatePostJob} className="min-h-[40px] grow" icon={<Queue className="text-base" />}>
							Post a job
						</Button>
					</div>
				</div>
			</Card>
			<Table
				bordered
				dataSource={filteredJobs}
				columns={columns}
				rowKey={(record) => record?.id || Math.random().toString()}
				pagination={{current: pageNumber, onChange: setPageNumber}}
				scroll={{x: true}}
			/>
		</div>
	);
}
