import React, {useContext, useState, useRef, useEffect} from 'react';
import {UserContext} from '../../utils/UserContext';
import {editProfileApi, editProfileImage} from '../../api/profileApi';
import {countries} from '../../utils/arrayData/CountryArray';
import {Helmet} from 'react-helmet';
import {Layout, Typography, Form, Input, Select, Button, Avatar, Upload, message, Spin, Card} from 'antd';
import {EditOutlined, SaveOutlined, CloseOutlined, UploadOutlined, LockOutlined} from '@ant-design/icons';
import {permColors} from '../../utils/colors';
import NewPasswordPopup from '../../components/AuthComponents/NewPasswordPopup';
import Navbar from '../../components/Navbars/Navbar2';
import {isJobSeeker} from '../../utils/variables/userVariables';
import Footer from '../../components/Footer/Footer';
import CompanyInsights from './CompanyInsights';

const {Content} = Layout;
const {Title, Text} = Typography;

export default function EditProfile() {
	const [currentUser] = useContext(UserContext);
	const [form] = Form.useForm();
	const [edit, setEdit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [popup, setPopup] = useState(false);
	const [imageUrl, setImageUrl] = useState(currentUser?.profile?.profile_image);

	useEffect(() => {
		if (currentUser?.profile) {
			form.setFieldsValue({
				company_name: currentUser.profile.company_name,
				first_name: currentUser.profile.first_name,
				last_name: currentUser.profile.last_name,
				country: currentUser.profile.country,
			});
		}
	}, [currentUser, form]);

	const handleImageChange = async (info) => {
		if (info.file.status === 'uploading') {
			setLoading(true);
			return;
		}
		if (info.file.status === 'done') {
			try {
				await editProfileImage(info.file.originFileObj);
				message.success('Profile image updated successfully');
				window.location.reload();
			} catch (error) {
				message.error('Failed to update profile image');
			} finally {
				setLoading(false);
			}
		}
	};

	const uploadButton = (
		<div style={{width: 100, height: 100}} className="flex flex-col items-center justify-center border-2 border-dashed rounded-full">
			{loading ? <Spin /> : <UploadOutlined style={{fontSize: '24px'}} />}
			<div className="mt-2 text-xs">{isJobSeeker(currentUser) ? 'Upload Photo' : 'Upload Logo'}</div>
		</div>
	);

	const handleSubmit = async (values) => {
		setLoading(true);
		try {
			await editProfileApi(values);
			message.success('Profile updated successfully');
			setEdit(false);
			window.location.reload();
		} catch (error) {
			message.error('Failed to update profile');
		} finally {
			setLoading(false);
		}
	};

	return (
		<Layout style={{minHeight: '100dvh', backgroundColor: permColors.backgroundGreyBlue}}>
			<Helmet>
				<title>Edit Profile | Permworks</title>
				<meta name="description" content="Update your Permworks profile. Keep your skills, experience, and other details up to date." />
			</Helmet>
			<Navbar />
			<div className="flex flex-col lg:flex-col gap-2 max-w-full mx-auto p-2">
				<Card className="w-full lg:w-[780px] max-w-full h-fit">
					<div className="flex justify-between items-start gap-2 mb-4">
						<h1 className="text-xl text-darkCharcoal font-semibold">Company Profile</h1>
						{!edit && (
							<div className="flex flex-col md:flex-row gap-2">
								<Button type="primary" icon={<EditOutlined />} onClick={() => setEdit(true)}>
									Edit Profile
								</Button>
								{currentUser?.auth_provider === 'email' && (
									<Button type="default" className="w-fit" icon={<LockOutlined />} onClick={() => setPopup(true)}>
										Change Password
									</Button>
								)}
							</div>
						)}
						{edit && (
							<div className="flex flex-col md:flex-row gap-2">
								<Button type="primary" icon={<SaveOutlined />} htmlType="submit" loading={loading} onClick={form.submit} disabled={!edit || loading}>
									Save Changes
								</Button>
								<Button icon={<CloseOutlined />} onClick={() => setEdit(false)} disabled={!edit || loading}>
									Cancel
								</Button>
							</div>
						)}
					</div>
					<Card>
						<Spin spinning={loading}>
							<Form form={form} layout="vertical" onFinish={handleSubmit} initialValues={currentUser?.profile}>
								<div className="flex items-start space-x-8">
									<Upload
										name="avatar"
										listType="picture-circle"
										className="avatar-uploader"
										showUploadList={false}
										accept="image/png,image/jpeg,image/heic,image/heif"
										onChange={handleImageChange}
										customRequest={({onSuccess}) => setTimeout(() => onSuccess('ok'), 0)}>
										{imageUrl ? <Avatar size={100} src={imageUrl} className="hover:opacity-80" /> : uploadButton}
									</Upload>

									<div className="flex-1">
										{edit ? (
											<div className="flex flex-col w-full">
												<Form.Item name="company_name" label="Company name" rules={[{required: true}]}>
													<Input className="w-full" />
												</Form.Item>
												<Form.Item name="first_name" label="First name" rules={[{required: true}]}>
													<Input className="w-full" />
												</Form.Item>
												<Form.Item name="last_name" label="Last name" rules={[{required: true}]}>
													<Input className="w-full" />
												</Form.Item>
												<Form.Item name="country" label="Country" rules={[{required: true}]}>
													<Select className="w-full">
														{countries.map((country) => (
															<Select.Option key={country} value={country}>
																{country}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</div>
										) : (
											<div className="space-y-4">
												<div>
													<div className="text-gray-500 text-xs">Company Name</div>
													<div className="font-medium">{currentUser?.profile?.company_name || 'N/A'}</div>
												</div>
												<div>
													<div className="text-gray-500 text-xs">Email</div>
													<div className="font-medium">{currentUser?.email || 'N/A'}</div>
												</div>
												<div>
													<div className="text-gray-500 text-xs">Name</div>
													<div className="font-medium">
														{currentUser?.profile?.first_name} {currentUser?.profile?.last_name}
													</div>
												</div>
												<div>
													<div className="text-gray-500 text-xs">Country</div>
													<div className="font-medium">{currentUser?.profile?.country || 'N/A'}</div>
												</div>
											</div>
										)}
									</div>
								</div>
							</Form>
						</Spin>
					</Card>
				</Card>
				<CompanyInsights />
				{/* <Button type="default" size="large" danger>
					Close account
				</Button> */}
			</div>
			<NewPasswordPopup showPopup={popup} closePopup={() => setPopup(false)} />
			<Footer backgroundColor={permColors.white} />
		</Layout>
	);
}
