import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Card, Spin, Tabs, Select, DatePicker, Button, Table, Tooltip} from 'antd';
import {UserOutlined, TeamOutlined, RiseOutlined, CalendarOutlined, BarChartOutlined, PieChartOutlined, LineChartOutlined} from '@ant-design/icons';
import {LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer} from 'recharts';
import {mainUrl} from '../../api/Urls';

const {TabPane} = Tabs;
const {RangePicker} = DatePicker;
const {Option} = Select;

// Color palette for charts
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

const UserActivityDashboard = () => {
	const [loading, setLoading] = useState(true);
	const [activityStats, setActivityStats] = useState(null);
	const [retentionData, setRetentionData] = useState(null);
	const [chartData, setChartData] = useState(null);
	const [chartType, setChartType] = useState('daily');
	const [chartDays, setChartDays] = useState(30);

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		fetchChartData();
	}, [chartType, chartDays]);

	const fetchData = async () => {
		setLoading(true);
		try {
			const [statsResponse, retentionResponse] = await Promise.all([
				axios.get(`${mainUrl}/auth/admin/user-activity-stats/`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
					},
				}),
				axios.get(`${mainUrl}/auth/admin/user-retention/`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
					},
				}),
			]);

			setActivityStats(statsResponse.data);
			setRetentionData(retentionResponse.data);

			await fetchChartData();
		} catch (error) {
			console.error('Error fetching dashboard data:', error);
		} finally {
			setLoading(false);
		}
	};

	const fetchChartData = async () => {
		try {
			const response = await axios.get(`${mainUrl}/auth/admin/chart-data/?type=${chartType}&days=${chartDays}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			});
			setChartData(response.data);
		} catch (error) {
			console.error('Error fetching chart data:', error);
		}
	};

	if (loading || !activityStats) {
		return (
			<div className="flex justify-center items-center h-96">
				<Spin size="large" tip="Loading dashboard data..." />
			</div>
		);
	}

	// Format data for auth provider pie chart
	const authProviderData = activityStats.auth_providers.map((item) => ({
		name: item.auth_provider || 'Unknown',
		value: item.count,
	}));

	// Format data for EOR type pie chart
	const eorTypeData = activityStats.eor_type_distribution.map((item) => ({
		name: item.eor_type || 'Unknown',
		value: item.count,
	}));

	// Format data for activity by title
	const activityByTitleData = activityStats.activity_by_title.map((item) => ({
		name: item.title,
		value: item.count,
	}));

	return (
		<div className=" max-h-full overflow-y-auto w-full">
			<h1 className="text-2xl font-bold mb-6 text-gray-800">User Activity Dashboard</h1>

			<div className="grid grid-cols-1 lg:grid-cols-4 gap-4 mb-6">
				<StatCard title="Active Users (24h)" value={activityStats.active_users.last_24_hours} icon={<UserOutlined />} color="bg-blue-500" />
				<StatCard title="Active Users (7d)" value={activityStats.active_users.last_7_days} icon={<TeamOutlined />} color="bg-green-500" />
				<StatCard title="Active Users (30d)" value={activityStats.active_users.last_30_days} icon={<TeamOutlined />} color="bg-yellow-500" />
				<StatCard title="Total Users" value={activityStats.user_totals.total_users} icon={<TeamOutlined />} color="bg-purple-500" />
			</div>

			<Tabs defaultActiveKey="1" className="bg-white rounded-lg shadow p-4 mb-6">
				<TabPane
					tab={
						<span>
							<LineChartOutlined /> User Activity
						</span>
					}
					key="1">
					<div className="mb-4 flex flex-wrap gap-4">
						<Select defaultValue={chartType} onChange={setChartType} className="w-32">
							<Option value="daily">Daily</Option>
							<Option value="weekly">Weekly</Option>
							<Option value="monthly">Monthly</Option>
						</Select>
						<Select defaultValue={chartDays} onChange={setChartDays} className="w-32">
							<Option value={7}>Last 7 days</Option>
							<Option value={30}>Last 30 days</Option>
							<Option value={90}>Last 90 days</Option>
							<Option value={180}>Last 180 days</Option>
						</Select>
					</div>

					{chartData && (
						<div className="h-80">
							<ResponsiveContainer width="100%" height="100%">
								<LineChart
									data={chartData.labels.map((date, index) => ({
										date,
										registrations: chartData.datasets[0].data[index],
										logins: chartData.datasets[1].data[index],
									}))}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis
										dataKey="date"
										tick={{fontSize: 12}}
										tickFormatter={(value) => {
											if (chartType === 'daily') {
												return new Date(value).toLocaleDateString('en-US', {month: 'short', day: 'numeric'});
											} else if (chartType === 'weekly') {
												return new Date(value).toLocaleDateString('en-US', {month: 'short', day: 'numeric'});
											} else {
												return new Date(value).toLocaleDateString('en-US', {month: 'short', year: 'numeric'});
											}
										}}
									/>
									<YAxis />
									<RechartsTooltip
										formatter={(value, name) => [value, name === 'registrations' ? 'New Registrations' : 'User Logins']}
										labelFormatter={(label) =>
											new Date(label).toLocaleDateString('en-US', {
												weekday: 'short',
												year: 'numeric',
												month: 'short',
												day: 'numeric',
											})
										}
									/>
									<Legend />
									<Line type="monotone" dataKey="registrations" name="New Registrations" stroke="#8884d8" activeDot={{r: 8}} />
									<Line type="monotone" dataKey="logins" name="User Logins" stroke="#82ca9d" />
								</LineChart>
							</ResponsiveContainer>
						</div>
					)}
				</TabPane>

				<TabPane
					tab={
						<span>
							<PieChartOutlined /> User Distribution
						</span>
					}
					key="2">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
						<Card title="Authentication Providers" className="shadow-sm">
							<div className="h-80">
								<ResponsiveContainer width="100%" height="100%">
									<PieChart>
										<Pie
											data={authProviderData}
											cx="50%"
											cy="50%"
											labelLine={false}
											outerRadius={80}
											fill="#8884d8"
											dataKey="value"
											nameKey="name"
											label={({name, percent}) => `${name}: ${(percent * 100).toFixed(0)}%`}>
											{authProviderData.map((entry, index) => (
												<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
											))}
										</Pie>
										<RechartsTooltip formatter={(value, name) => [value, name]} />
										<Legend />
									</PieChart>
								</ResponsiveContainer>
							</div>
						</Card>

						<Card title="EOR Type Distribution" className="shadow-sm">
							<div className="h-80">
								<ResponsiveContainer width="100%" height="100%">
									<PieChart>
										<Pie
											data={eorTypeData}
											cx="50%"
											cy="50%"
											labelLine={false}
											outerRadius={80}
											fill="#8884d8"
											dataKey="value"
											nameKey="name"
											label={({name, percent}) => `${name}: ${(percent * 100).toFixed(0)}%`}>
											{eorTypeData.map((entry, index) => (
												<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
											))}
										</Pie>
										<RechartsTooltip formatter={(value, name) => [value, name]} />
										<Legend />
									</PieChart>
								</ResponsiveContainer>
							</div>
						</Card>
					</div>
				</TabPane>

				<TabPane
					tab={
						<span>
							<BarChartOutlined /> Activity Metrics
						</span>
					}
					key="3">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
						<Card title="New User Registrations" className="shadow-sm">
							<div className="h-80">
								<ResponsiveContainer width="100%" height="100%">
									<BarChart
										data={[
											{name: 'Last 24h', value: activityStats.new_registrations.last_24_hours},
											{name: 'Last 7d', value: activityStats.new_registrations.last_7_days},
											{name: 'Last 30d', value: activityStats.new_registrations.last_30_days},
											{name: 'Last 90d', value: activityStats.new_registrations.last_90_days},
										]}
										margin={{top: 20, right: 30, left: 20, bottom: 5}}>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis dataKey="name" />
										<YAxis />
										<RechartsTooltip />
										<Bar dataKey="value" name="Users" fill="#8884d8" />
									</BarChart>
								</ResponsiveContainer>
							</div>
						</Card>

						<Card title="Top Activity Types" className="shadow-sm">
							<div className="h-80">
								<ResponsiveContainer width="100%" height="100%">
									<BarChart data={activityByTitleData} layout="vertical" margin={{top: 20, right: 30, left: 100, bottom: 5}}>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis type="number" />
										<YAxis type="category" dataKey="name" width={80} tick={{fontSize: 12}} />
										<RechartsTooltip />
										<Bar dataKey="value" name="Count" fill="#82ca9d" />
									</BarChart>
								</ResponsiveContainer>
							</div>
						</Card>
					</div>
				</TabPane>

				<TabPane
					tab={
						<span>
							<RiseOutlined /> User Retention
						</span>
					}
					key="4">
					{retentionData && retentionData.cohorts && (
						<div className="overflow-x-auto">
							<Table
								dataSource={retentionData.cohorts.map((cohort, index) => ({
									key: index,
									cohort_date: cohort.cohort_date,
									user_count: cohort.user_count,
									...cohort.retention.reduce((acc, ret) => {
										acc[`week_${ret.week}`] = `${ret.count} (${ret.rate.toFixed(1)}%)`;
										return acc;
									}, {}),
								}))}
								columns={[
									{
										title: 'Cohort',
										dataIndex: 'cohort_date',
										key: 'cohort_date',
										render: (text) =>
											new Date(text).toLocaleDateString('en-US', {
												year: 'numeric',
												month: 'short',
												day: 'numeric',
											}),
									},
									{
										title: 'Users',
										dataIndex: 'user_count',
										key: 'user_count',
									},
									...[...Array(12)].map((_, i) => ({
										title: `Week ${i + 1}`,
										dataIndex: `week_${i + 1}`,
										key: `week_${i + 1}`,
										render: (text) => text || '-',
									})),
								]}
								scroll={{x: 1300}}
								pagination={false}
								className="shadow-sm"
							/>
						</div>
					)}
				</TabPane>
			</Tabs>

			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
				<MetricCard
					title="User Engagement"
					metrics={[
						{label: 'Profile Completion Rate', value: `${activityStats.user_engagement.profile_completion_rate.toFixed(1)}%`},
						{label: 'Users with Profile', value: activityStats.user_engagement.users_with_profile},
						{label: 'Verification Rate', value: `${activityStats.user_totals.verification_rate.toFixed(1)}%`},
					]}
				/>

				<MetricCard
					title="Job Statistics"
					metrics={[
						{label: 'Total Jobs', value: activityStats.job_stats.total_jobs},
						{label: 'Active Jobs', value: activityStats.job_stats.active_jobs},
						{label: 'Completed Jobs', value: activityStats.job_stats.completed_jobs},
					]}
				/>

				<MetricCard
					title="User Totals"
					metrics={[
						{label: 'Total Users', value: activityStats.user_totals.total_users},
						{label: 'Verified Users', value: activityStats.user_totals.verified_users},
						{label: 'Active Users (90d)', value: activityStats.active_users.last_90_days},
					]}
				/>
			</div>

			<Card title="Daily Active Users Trend (Last 30 Days)" className="shadow mb-6">
				<div className="h-80">
					<ResponsiveContainer width="100%" height="100%">
						<LineChart data={activityStats.daily_active_users_trend} margin={{top: 20, right: 30, left: 20, bottom: 5}}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="date" tick={{fontSize: 12}} tickFormatter={(value) => new Date(value).toLocaleDateString('en-US', {month: 'short', day: 'numeric'})} />
							<YAxis />
							<RechartsTooltip
								labelFormatter={(label) =>
									new Date(label).toLocaleDateString('en-US', {
										weekday: 'long',
										year: 'numeric',
										month: 'long',
										day: 'numeric',
									})
								}
							/>
							<Line type="monotone" dataKey="count" name="Active Users" stroke="#8884d8" activeDot={{r: 8}} />
						</LineChart>
					</ResponsiveContainer>
				</div>
			</Card>

			<div className="flex justify-end">
				<Button type="primary" onClick={fetchData}>
					Refresh Data
				</Button>
			</div>
		</div>
	);
};

// Stat Card Component
const StatCard = ({title, value, icon, color}) => (
	<Card className="shadow-sm">
		<div className="flex items-center">
			<div className={`${color} text-white p-3 rounded-lg mr-4`}>{icon}</div>
			<div>
				<p className="text-gray-500 text-sm">{title}</p>
				<p className="text-2xl font-bold">{value.toLocaleString()}</p>
			</div>
		</div>
	</Card>
);

// Metric Card Component
const MetricCard = ({title, metrics}) => (
	<Card title={title} className="shadow-sm">
		<div className="space-y-4">
			{metrics.map((metric, index) => (
				<div key={index} className="flex justify-between items-center">
					<span className="text-gray-600">{metric.label}</span>
					<span className="font-semibold">{typeof metric.value === 'number' ? metric.value.toLocaleString() : metric.value}</span>
				</div>
			))}
		</div>
	</Card>
);

export default UserActivityDashboard;
