import api from './interceptor';
import {mainUrl} from './Urls';

export async function getCompanyInsightsApi() {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};

		const response = await api.get(`${mainUrl}/auth/company/insights/`, config);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function getJobInsightsApi(jobId) {
	console.log('api job id ', {jobId});
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};

		const response = await api.get(`${mainUrl}/auth/job/insights/${jobId.jobId}/`, config);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}
