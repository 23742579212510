import {useState, useContext, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {FullLogoSmallNavDashboard} from '../../components/Logos/Logo';
import Inbox, {AvatarWithLoading} from '../../components/Dashboard/Inbox';
import BillingHistory from '../../components/Dashboard/BillingHistory';
import Subscriptions from '../../components/Dashboard/Subscriptions';
import Payments from '../../components/Dashboard/Payments';
import EmployerJob from '../../components/Dashboard/EmployerJob';
import {UserContext} from '../../utils/UserContext';
import AppliedJobs from '../../components/Dashboard/AppliedJobs';
import {useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import SavedJobs from '../../components/Dashboard/SavedJobs/SavedJobs';
import Notifications from '../../components/Dashboard/Notifications/Notifications';
import {markAllAsReadApi} from '../../api/notificationsApi';
import {capitalize} from '@mui/material';
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import SubscriptionsOutlinedIcon from '@material-ui/icons/SubscriptionsOutlined';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import {ROUTES} from '../../utils/ROUTES';
import {AccountBalance, LogoutOutlined, MenuOutlined, CloseOutlined, OpenInNew, WorkOffOutlined, AddCircleOutline, Queue, QuestionAnswerOutlined, ContactSupportOutlined} from '@mui/icons-material';
import {isEmployer, isJobSeeker} from '../../utils/variables/userVariables';
import ClosedJobs from '../../components/Dashboard/ClosedJobs';
import Team from '../../components/Dashboard/EOR/Team';
import TimeOff from '../../components/Dashboard/EOR/TimeOff';
import Onboarding from '../../components/Dashboard/EOR/Onboarding';
import {useAtom} from 'jotai';
import {selectedAtom, teamsAtom, unreadNotificationsAtom} from '../../utils/atoms';
import {Badge, Button, Divider, Dropdown, Input, Tooltip} from 'antd';
import './Dashboard.scss';
import {BarChartOutlined, EditOutlined, SearchOutlined, UserOutlined} from '@ant-design/icons';
import CompanyInsightsDashboard from '../../components/Dashboard/CompanyInsightsDashboard';

const SELECTED_MENU = 'bg-white shadow-border-chill-background text-antBlue';
const UNSELECTED_MENU = 'text-darkCharcoal hover:bg-backgroundGrey';
const MOBILE_MENU_OPEN = 'translate-x-0 opacity-100 visible';
const MOBILE_MENU_CLOSED = '-translate-x-full lg:translate-x-0 opacity-0 lg:opacity-100 invisible lg:visible';
const MENU_BUTTON_BASE = 'w-full flex font-normal items-center px-4 py-2 text-sm rounded-md transition-all duration-200 ease-in-out transform';

export default function Dashboard() {
	const {state} = useLocation();
	const [selected, setSelected] = useAtom(selectedAtom);
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const navigate = useNavigate();
	const [unreadNotifications, setUnreadNotifications] = useAtom(unreadNotificationsAtom);
	const [teams, setTeams] = useAtom(teamsAtom);
	const [live] = useState(false);

	async function logout() {
		try {
			await new Promise((resolve) => {
				localStorage.setItem('currentUser', '');
				localStorage.setItem('accessToken', '');
				localStorage.setItem('token', '');
				setCurrentUser('');
				resolve();
			});
			window.location.href = ROUTES.AUTHENTICATE;
		} catch (error) {
			console.error(error);
		}
	}

	async function menuSelector(menuName) {
		setSelected(menuName);
		sessionStorage.setItem('selected', menuName);
		setMobileMenuOpen(false);

		if (menuName === 'notifications' && unreadNotifications > 0) {
			try {
				await markAllAsReadApi();
				setUnreadNotifications(0);
			} catch (error) {
				console.error('Error marking notifications as read:', error);
			}
		}
	}

	useEffect(() => {
		const stored = sessionStorage.getItem('selected');
		if (!stored && isEmployer(currentUser)) {
			setSelected('companyInsights');
			sessionStorage.setItem('selected', 'companyInsights');
		}
	}, [currentUser, setSelected]);

	const getMenuItems = () => {
		const commonItems = [{name: 'Inbox', key: 'inbox', icon: <InboxOutlinedIcon className="text-base" />}];

		if (isEmployer(currentUser)) {
			const employerItemsInitial = [{name: 'Company insights', key: 'companyInsights', icon: <BarChartOutlined className="text-base" />}];

			const employerItems = [
				{
					name: 'My open jobs',
					key: 'jobs',
					icon: <WorkOutlineIcon className="text-base" />,
					onClick: () => menuSelector('jobs'),
					subItems: [
						{name: 'Browse Jobs', key: 'browsejobs', icon: <WorkOutlineIcon className="text-base" />, to: ROUTES.BROWSE_JOBS, external: true},
						{name: 'Post Job', key: 'postJob', icon: <AddCircleOutline className="text-base" />, to: ROUTES.POST_JOB, external: true},
					],
				},
				{name: 'My closed jobs', key: 'closedJobs', icon: <WorkOffOutlined className="text-base" />},
				{name: 'Payments', key: 'payments', icon: <PaymentOutlinedIcon className="text-base" />},
				{name: 'Subscriptions', key: 'subscriptions', icon: <SubscriptionsOutlinedIcon className="text-base" />},
				{name: 'Billing history', key: 'billingHistory', icon: <AccountBalance className="text-base" />},
				{name: 'Notifications', key: 'notifications', icon: <NotificationsOutlinedIcon className="text-base" />},
			];
			return [...employerItemsInitial, ...commonItems, ...employerItems];
		}

		if (isJobSeeker(currentUser)) {
			const jobSeekerItems = [
				{name: 'Applied jobs', key: 'appliedJobs', icon: <WorkOutlineIcon className="text-base" />},
				{name: 'Saved jobs', key: 'savedJobs', icon: <WorkOutlineIcon className="text-base" />},
				{name: 'Notifications', key: 'notifications', icon: <NotificationsOutlinedIcon className="text-base" />},
			];
			return [...commonItems, ...jobSeekerItems];
		}

		return commonItems;
	};

	const jobSeekerNavLinks = [{name: 'Browse jobs', key: 'browsejobs', icon: <SearchOutlined className="text-base" />, to: ROUTES.BROWSE_JOBS, external: true, type: 'jobseeker'}];

	const employerNavLinks = [{name: 'Post Job', key: 'postJob', icon: <Queue className="text-base" />, to: ROUTES.POST_JOB, external: true, type: 'employer'}];

	return (
		<div className="bg-[#f9fbfc] flex flex-col h-dvh">
			<Helmet>
				<title>Dashboard | Permworks</title>
				<meta name="description" content="Your personal dashboard. Manage your jobs, applications, and profile." />
			</Helmet>

			{/* Top Navbar */}
			<nav className="lg:hidden bg-white border-b border-gray-200">
				<div className="mx-auto px-4 sm:px-6 lg:px-7">
					<div className="flex justify-between py-2">
						<div className="flex items-center">
							<button onClick={() => setMobileMenuOpen(!mobileMenuOpen)} className="lg:hidden p-2 transition-all duration-300 ease-in-out hover:scale-110">
								<div className={`transition-all duration-300 ease-in-out transform ${mobileMenuOpen ? 'rotate-180' : 'rotate-0'}`}>
									{mobileMenuOpen ? <CloseOutlined className="h-6 w-6 text-charcoal" /> : <MenuOutlined className="h-6 w-6 text-charcoal" />}
								</div>
							</button>
							<FullLogoSmallNavDashboard className="transition-opacity duration-200 hover:opacity-80" />
						</div>

						<div className="flex items-center gap-6">
							<div className="hidden md:flex items-center gap-6">
								{(isJobSeeker(currentUser) ? jobSeekerNavLinks : isEmployer(currentUser) ? employerNavLinks : []).map((link) => (
									<Button key={link.key} type="primary" href={link.to} icon={link.icon}>
										{link.name}
									</Button>
								))}
							</div>
							<Dropdown
								menu={{
									items: [
										{
											key: '1',
											label: 'Edit Profile',
											icon: <EditOutlined />,
											onClick: () => navigate(ROUTES.EDIT_PROFILE),
										},
										{
											key: '2',
											label: 'Logout',
											icon: <LogoutOutlined />,
											onClick: () => {
												logout();
											},
										},
									],
								}}
								trigger={['click', 'hover']}>
								<div className="flex items-center cursor-pointer transition-transform duration-200 ">
									<AvatarWithLoading
										className="h-8 w-8 rounded-full transition-transform duration-200"
										alt={`${currentUser?.profile?.first_name ?? 'Unknown'} ${currentUser?.profile?.last_name ?? 'Applicant'}`}
										src={currentUser?.profile?.profile_image ?? '/static/images/avatar/default.jpg'}
									/>
									<span className="ml-2 hidden md:block text-sm font-medium text-charcoal">
										{capitalize(currentUser?.profile?.first_name)} {capitalize(currentUser?.profile?.last_name)}
									</span>
								</div>
							</Dropdown>
						</div>
					</div>
				</div>
			</nav>

			<div className="flex flex-1 overflow-hidden">
				{/* Sidebar */}
				<aside
					className={`fixed bg-[#f9fbfc] lg:static transform transition-all duration-300 ease-in-out ${
						mobileMenuOpen ? MOBILE_MENU_OPEN : MOBILE_MENU_CLOSED
					} lg:block w-full lg:w-64 overflow-y-auto h-[calc(100dvh-60px)] lg:h-full z-50`}>
					<div className="h-full overflow-y-auto px-3 py-4 flex flex-col">
						<FullLogoSmallNavDashboard className="transition-opacity duration-200 hover:opacity-80 ml-3 hidden lg:block" />
						<div className=" text-antBlue text-xs ml-9  hidden lg:flex items-center gap-1">{isEmployer(currentUser) ? 'Employer dashboard' : 'Jobseeker dashboard'}</div>

						<hr className="my-5 ml-3 mr-1 opacity-10 text-grey hidden lg:block" />

						<div className="mb-4">
							<h3 className="px-4 text-xs font-normal text-antBlue ">Dashboard menu</h3>
							<ul className="mt-2 space-y-1">
								{getMenuItems().map((item) => (
									<li key={item.key}>
										{item.subItems ? (
											<div>
												<button onClick={item.onClick} className={`${MENU_BUTTON_BASE} ${selected === item.key ? SELECTED_MENU : UNSELECTED_MENU}`}>
													{item.icon}
													<span className="ml-3">{item.name}</span>
												</button>
												{selected === item.key && (
													<ul className="ml-6 mt-1 space-y-1">
														{item.subItems.map((subItem) => (
															<li key={subItem.key}>
																<Link
																	to={subItem.to}
																	target="_blank"
																	rel="noopener noreferrer"
																	className={`${MENU_BUTTON_BASE} ${selected === subItem.key ? SELECTED_MENU : UNSELECTED_MENU}`}>
																	{subItem.icon}
																	<span className="ml-3">{subItem.name}</span>
																	{subItem.external && <OpenInNew className="ml-auto text-base text-linkBlue" />}
																</Link>
															</li>
														))}
													</ul>
												)}
											</div>
										) : (
											<button onClick={() => menuSelector(item.key)} className={`${MENU_BUTTON_BASE} ${selected === item.key ? SELECTED_MENU : UNSELECTED_MENU}`}>
												{item.icon}
												<span className="ml-3">{item.name}</span>
												{item.name === 'Notifications' && unreadNotifications > 0 && (
													<Badge count={unreadNotifications} className="ml-auto transition-all duration-200 animate-pulse" />
												)}
											</button>
										)}
									</li>
								))}
							</ul>
						</div>

						<div className="mt-auto">
							{(isJobSeeker(currentUser) ? jobSeekerNavLinks : isEmployer(currentUser) ? employerNavLinks : []).map((link) => (
								<Button key={link.key} type="primary" className="w-full mb-2" href={link.to} icon={link.icon}>
									{link.name}
								</Button>
							))}
							<Link to={ROUTES.FAQ} target="_blank" rel="noopener noreferrer" className={`${MENU_BUTTON_BASE} text-grey`}>
								<QuestionAnswerOutlined className="text-base" />
								<span className="ml-3">Frequent questions</span>
								<OpenInNew className="ml-auto text-sm text-grey" />
							</Link>
							<Link to={ROUTES.CONTACT_US} target="_blank" rel="noopener noreferrer" className={`${MENU_BUTTON_BASE}  text-grey`}>
								<ContactSupportOutlined className="text-base" />
								<span className="ml-3">Get in touch</span> <OpenInNew className="ml-auto text-sm text-grey" />
							</Link>
							<Tooltip title="Go to profile page" placement="right">
								<div className="flex items-center cursor-pointer w-fit transition-transform hover:opacity-80 duration-200 my-3 ml-3" onClick={() => navigate(ROUTES.EDIT_PROFILE)}>
									<AvatarWithLoading
										className="h-9 w-9 rounded-full transition-transform duration-200 border-2 border-backgroundGrey p-1 text-base"
										alt={`${currentUser?.profile?.first_name ?? 'Unknown'} ${currentUser?.profile?.last_name ?? 'Applicant'}`}
										src={currentUser?.profile?.profile_image ?? '/static/images/avatar/default.jpg'}
									/>
									<span className="ml-2  text-sm font-medium text-charcoal">
										{capitalize(currentUser?.profile?.first_name)} {capitalize(currentUser?.profile?.last_name)}
									</span>
								</div>
							</Tooltip>
							<div className="pt-4 border-t border-gray-200">
								<Button type="default" onClick={logout} icon={<LogoutOutlined className="text-base" />} className="w-full">
									Logout
								</Button>
								{/* <a
								href={ROUTES.EOR_DASHBOARD}
								className="w-full flex items-center px-4 py-2 text-sm text-charcoal hover:bg-backgroundGrey rounded-md transition-all duration-200 ease-in-out transform hover:scale-[1.02]">
								<DashboardOutlined className="text-base" />
								<span className="ml-3">EOR Dashboard</span>
							</a> */}
							</div>
						</div>
					</div>
				</aside>

				{/* Main Content */}
				<main className="flex-1 p-4 max-h-full overflow-y-auto transition-all duration-300 ease-in-out bg-white shadow-border-chill-background rounded-md mt-1 ml-1 lg:mt-3 lg:ml-3 mr-1 mb-1 lg:mr-4 lg:mb-4">
					{selected === 'companyInsights' && <CompanyInsightsDashboard />}
					{selected === 'inbox' && <Inbox />}
					{selected === 'payments' && isEmployer(currentUser) && <Payments />}
					{selected === 'subscriptions' && <Subscriptions />}
					{selected === 'billingHistory' && <BillingHistory />}
					{selected === 'notifications' && <Notifications />}
					{selected === 'savedJobs' && isJobSeeker(currentUser) && <SavedJobs />}
					{selected === 'jobs' && isEmployer(currentUser) && <EmployerJob />}
					{selected === 'closedJobs' && isEmployer(currentUser) && <ClosedJobs />}
					{selected === 'appliedJobs' && isJobSeeker(currentUser) && <AppliedJobs />}
					{selected === 'team' && <Team setMenuToTeam={menuSelector} />}
					{selected === 'timeOff' && <TimeOff />}
					{selected === 'onboarding' && <Onboarding setMenuToTeam={menuSelector} />}
				</main>
			</div>
		</div>
	);
}
