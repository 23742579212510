import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './ConfirmEmail.scss';
import Navbar from '../../components/Navbars/Navbar2';
import {useLocation} from 'react-router-dom';
import {mainUrl} from '../../api/Urls';
import {Helmet} from 'react-helmet';
import {Input, Spin, Form, Alert, Button, Card, message} from 'antd';
import Footer from '../../components/Footer/Footer';
import {permColors} from '../../utils/colors';
import {MailOutlined} from '@ant-design/icons';

export default function ConfirmEmail() {
	const [isTimedOut, setIsTimedOut] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const location = useLocation();
	const [email, setEmail] = useState(location?.state?.email || '');
	const [confirmationSent, setConfirmationSent] = useState(false);
	const [error, setError] = useState('');
	const [form] = Form.useForm();

	useEffect(() => {
		if (isTimedOut) {
			const timeoutId = setTimeout(() => {
				setIsTimedOut(false);
			}, 120000);
			return () => clearTimeout(timeoutId);
		}
	}, [isTimedOut]);

	const requestConfirmation = async () => {
		setIsLoading(true);
		setError('');
		try {
			await axios.post(mainUrl + '/auth/resend-email-verify/', {
				email: email,
			});
			setIsTimedOut(true);
			setConfirmationSent(true);
		} catch (error) {
			if (error.response?.data?.error) {
				form.setFields([
					{
						name: 'email',
						errors: [error.response.data.error],
					},
				]);
				setError(error.response.data.error);
				setIsTimedOut(true);
			} else {
				setError(error.message || 'An error occurred while sending the confirmation email. Please try again.');
			}
		} finally {
			setIsLoading(false);
		}
	};

	const customerConfirm = async (values) => {
		setIsLoading(true);
		setError('');
		try {
			await axios.post(mainUrl + '/auth/resend-email-verify/', {
				email: values.email,
			});
			setIsTimedOut(true);
			setConfirmationSent(true);
			setEmail(values.email);
		} catch (error) {
			if (error.response?.data?.error) {
				form.setFields([
					{
						name: 'email',
						errors: [error.response.data.error],
					},
				]);
			} else {
				setError(error.message || 'An error occurred while sending the confirmation email. Please try again.');
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div>
			<Helmet>
				<title>Confirm Email | Permworks</title>
				<meta name="description" content="Confirm your email address to complete your registration. Please check your inbox for the confirmation link." />
			</Helmet>
			<Navbar />
			<div className="flex justify-center items-center min-h-[calc(100vh-400px)] bg-backgroundGrey p-3">
				<Card>
					<h1 className="text-2xl font-semibold text-darkCharcoal mb-2">Confirm Email</h1>

					{error && <Alert message={error} type="error" showIcon className="mb-3" />}

					{email ? (
						<div className="flex flex-col gap-2">
							<h2 className="text-xl font-bold text-darkCharcoal">Thank you for signing up!</h2>
							<p className="text-charcoal">Please confirm your email address to complete your account registration.</p>
							<p className="text-charcoal">Once you confirm your email, you will be able to access all the features of our site.</p>
							<Alert
								className="rounded-md"
								message={
									<p className="text-charcoal text-sm">
										A confirmation email has been sent to <span className="font-bold">{email}</span>
									</p>
								}
								type="info"
								showIcon
								banner
							/>
							<Button onClick={requestConfirmation} disabled={isTimedOut || isLoading} loading={isLoading}>
								Resend Confirmation
							</Button>
						</div>
					) : (
						<>
							<p className="text-charcoal mb-2 text-sm">Please enter your email address to receive a confirmation email.</p>
							<Form form={form} onFinish={customerConfirm} layout="vertical">
								<Form.Item
									className="mb-2"
									name="email"
									rules={[
										{
											required: true,
											message: 'Please input your email!',
										},
										{
											type: 'email',
											message: 'Please enter a valid email!',
										},
									]}>
									<Input prefix={<MailOutlined className="opacity-50 mr-2" />} type="email" placeholder="Email Address" disabled={isLoading} />
								</Form.Item>
								<Form.Item>
									<Button type="primary" className="w-full" htmlType="submit" disabled={isTimedOut || isLoading || confirmationSent} loading={isLoading}>
										Send Email
									</Button>
								</Form.Item>
							</Form>
						</>
					)}

					{confirmationSent && (
						<Alert
							message="Email Sent"
							description={
								<>
									<p>A new confirmation email has been sent</p>
									<p>Please check your spam folder if you don't see it in your inbox</p>
								</>
							}
							type="success"
							className="my-2"
							showIcon
						/>
					)}
					{isTimedOut && <Alert message="Please wait 2 minutes before requesting another confirmation email" type="warning" showIcon />}
				</Card>
			</div>
			<Footer backgroundColor={permColors.white} />
		</div>
	);
}
