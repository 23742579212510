import React, {useEffect, useState, useContext} from 'react';
import {getPageUserApi} from '../../api/authApi';
import {useParams} from 'react-router-dom';
import uploadCircle from '../../assets/images/uploadCircle.png';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbars/Navbar2';
import {UserContext} from '../../utils/UserContext';
import Loading from '../../components/Loaders/Loading';
import {capitalizeFirstLetter, textFormatter} from '../../utils/TextAlterations';
import {formatDate} from '../../utils/DateFunctions';
import MessagePopup from '../../components/Popups/MessagePopup';
import './OtherProfile.scss';
import {Helmet} from 'react-helmet';
import {Card, Avatar, Tag, Button, Alert, Image} from 'antd';
import {EyeFilled, EyeInvisibleOutlined, InfoOutlined, MailOutlined, TagOutlined, TagsFilled} from '@ant-design/icons';
import {permColors} from '../../utils/colors';
import {DocumentScanner, Info, PlaceOutlined, School, Work, WorkOutline} from '@mui/icons-material';

export default function OtherProfile() {
	const [user, setUser] = useState({});
	const {userId} = useParams();
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [loading, setLoading] = useState(true);
	const [popup, setPopup] = useState(false);

	function closePopup() {
		setPopup(false);
	}

	useEffect(() => {
		async function fetchPageUser() {
			const res = await getPageUserApi(userId);
			setUser(res);
			console.log(res);
			setLoading(false);
		}
		fetchPageUser();
	}, []);

	if (loading) {
		return <Loading />;
	}

	const PermAlert = ({message, type}) => {
		return <Alert message={message} type={type} banner showIcon className="w-fit rounded-md p-3" />;
	};

	return (
		<div className="min-h-screen flex flex-col justify-between bg-backgroundGreyBlue">
			<Helmet>
				<title>{`${user?.profile?.first_name}'s Profile | Permworks`}</title>
				<meta name="description" content={`View ${user?.profile?.first_name}'s profile on Permworks. Explore their skills, experience, and more.`} />
			</Helmet>
			<Navbar />

			<main className="w-full max-w-[1160px] mx-auto px-4 py-5 mb-10">
				<div className="flex flex-wrap gap-3">
					{/* Left Column */}
					<div className="w-full lg:w-[350px] flex flex-col gap-3 flex-grow md:flex-grow-0">
						<Card className="bg-white" title={<h1 className="text-xl font-bold text-darkCharcoal md:text-left">Profile</h1>}>
							<div className="flex gap-4">
								<Image
									preview={{
										mask: (
											<div className="flex items-center justify-center flex-col">
												<EyeFilled />
												View
											</div>
										),
										maskClassName: 'flex items-center justify-center rounded-full',
									}}
									width={64}
									height={64}
									src={user?.profile?.profile_image}
									fallback={uploadCircle}
									style={{
										borderRadius: '50%',
										objectFit: 'cover',
									}}
								/>{' '}
								<div>
									<h2 className="mb-1 text-base font-semibold text-darkCharcoal">
										{capitalizeFirstLetter(user?.profile?.first_name)} {capitalizeFirstLetter(user?.profile?.last_name)}
									</h2>
									<p className="flex gap-2 items-center text-charcoal">
										<PlaceOutlined className="text-base" /> {user?.profile?.country ?? 'Unknown'}
									</p>

									{user?.id !== currentUser.id && (
										<Button type="default" icon={<MailOutlined />} block onClick={() => setPopup(true)} className="mt-2">
											Message
										</Button>
									)}
								</div>
							</div>
						</Card>

						<Card
							className="bg-white"
							title={
								<h3 className="text-lg font-semibold text-darkCharcoal flex items-center gap-2">
									<TagsFilled className="text-base text-antBlue" />
									Skills
								</h3>
							}>
							{user?.tag?.length > 0 ? (
								<div className="flex flex-wrap gap-2">
									{user?.tag?.map((skill, index) => (
										<Tag key={index} className="bg-backgroundGreyBlue text-charcoal m-0" icon={<TagOutlined className="text-charcoal" />}>
											{skill?.name}
										</Tag>
									))}
								</div>
							) : (
								<PermAlert message="No skills listed" type="info" />
							)}
						</Card>
					</div>

					{/* Right Column */}
					<div className="flex-grow w-full md:w-[650px]">
						<Card
							className="bg-white"
							title={
								<h2 className="text-lg font-semibold text-darkCharcoal flex items-center gap-2">
									<Info className="text-antBlue text-lg" /> General Information
								</h2>
							}>
							<div className="space-y-6">
								<div>
									<h3 className="text-lg font-semibold mb-4 text-darkCharcoal flex items-center gap-2">
										<Work className="text-lg" /> Work Experience
									</h3>
									{user?.work_experience?.length > 0 ? (
										<div className="space-y-6">
											{user?.work_experience?.map((work, index) => (
												<div key={index} className="border-l-4 border-blue-500 pl-4">
													<h4 className="text-lg font-semibold text-darkCharcoal">{capitalizeFirstLetter(work?.company_name)}</h4>
													<p className="font-medium text-charcoal">{capitalizeFirstLetter(work?.position_title)}</p>
													<p className="text-charcoal">{work?.country}</p>
													<p className="text-grey text-sm">
														{work?.from_year} {work?.from_month} - {work?.currently_working ? 'Present' : `${work?.to_year} ${work?.to_month}`}
													</p>
													<div
														className="mt-2 text-charcoal text-sm"
														dangerouslySetInnerHTML={{
															__html: textFormatter(work?.description),
														}}
													/>
												</div>
											))}
										</div>
									) : (
										<PermAlert message="No work experience listed" type="info" />
									)}
								</div>

								<hr className="my-6 opacity-10" />

								<div>
									<h3 className="text-lg font-semibold mb-4 text-darkCharcoal flex items-center gap-2">
										<School className="text-lg" /> Education
									</h3>
									{user?.education?.length > 0 ? (
										<div className="space-y-6">
											{user?.education?.map((item, index) => (
												<div key={index} className="border-l-4 border-green-500 pl-4">
													<h4 className="text-lg font-semibold text-darkCharcoal">{item?.area_of_study && capitalizeFirstLetter(item?.area_of_study)}</h4>
													<p className="font-medium text-charcoal">{item?.school_name && capitalizeFirstLetter(item?.school_name)}</p>
													<p className="text-charcoal">{item?.education_qualification}</p>
													<p className="text-grey text-sm">
														{item?.year_from} - {item?.year_to}
													</p>
													<div
														className="mt-2 text-charcoal text-sm"
														dangerouslySetInnerHTML={{
															__html: textFormatter(item?.description),
														}}
													/>
												</div>
											))}
										</div>
									) : (
										<PermAlert message="No education listed" type="info" />
									)}
								</div>

								<hr className="my-6 opacity-10" />

								<div>
									<h3 className="text-lg font-semibold mb-4 text-darkCharcoal flex items-center gap-2">
										<DocumentScanner className="text-lg" /> Portfolio
									</h3>
									{user?.portfolio?.length > 0 ? (
										<div className="space-y-4">
											{user?.portfolio?.map((work, index) => (
												<div key={index} className="border-l-4 border-purple-500 pl-4">
													<h4 className="text-lg font-semibold text-darkCharcoal">{work?.title}</h4>
													<p className="text-charcoal text-sm">{work?.description}</p>
													<p className="text-charcoal">{work?.service_type}</p>
													<p className="text-charcoal">{work?.category}</p>
													<p className="text-grey text-sm">{work && `Completed on the ${formatDate(work?.completion_date)}`}</p>
												</div>
											))}
										</div>
									) : (
										<PermAlert message="No portfolio items listed" type="info" />
									)}
								</div>
							</div>
						</Card>
					</div>
				</div>
			</main>

			<MessagePopup
				showPopup={popup}
				closePopup={closePopup}
				title={'Send a message to ' + user?.profile?.first_name}
				textArea={true}
				buttonText={'Send'}
				user={user.id}
				disabledButtonText={'Sent'}
			/>

			{loading && <Loading />}
			<Footer backgroundColor={permColors.white} />
		</div>
	);
}
