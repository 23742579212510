import React, {useEffect, useState} from 'react';
import {Table, Card, Typography, message, Modal, Alert, Checkbox, Button, Collapse} from 'antd';
import {requestAdminLogs} from '../../api/admin-api';
import {useGhosting} from './Ghosting';
import {BiGhost} from 'react-icons/bi';

const {Title} = Typography;

interface ContentType {
	id: number;
	app_label: string;
	model: string;
}

interface User {
	id: string;
	email: string;
	username: string;
}

interface ActivityType {
	content_type: string | null;
	action: string;
}

interface ContentObjectDetails {
	type?: string;
	id?: string;
	str_representation?: string;
	attributes?: Record<string, any>;
	error?: string;
}

interface LogEntry {
	id: string;
	recipient: User | null;
	actor: User | null;
	title: string;
	message: string;
	content_type: ContentType | null;
	object_id: string;
	content_object_details: ContentObjectDetails | null;
	created_at: string;
	updated_at: string;
	activity_type: ActivityType;
}

const LOG_TITLES = [
	// Job related
	{label: 'New Job Created', value: 'New Job Created'},
	{label: 'Job Updated', value: 'Job Updated'},
	{label: 'Job application submitted', value: 'Job application submitted'},
	{label: 'External job clicked', value: 'External job clicked'},
	{label: 'Job impression', value: 'Job impression'},
	// Profile related
	{label: 'New UserProfile Created', value: 'New UserProfile Created'},
	{label: 'UserProfile Updated', value: 'UserProfile Updated'},
	// Portfolio related
	{label: 'New Portfolio Created', value: 'New Portfolio Created'},
	{label: 'Portfolio Updated', value: 'Portfolio Updated'},
	{label: 'CV Parsed', value: 'CV Parsed'},
	{label: 'Google Index success', value: 'Job sent to Google Index'},
	// ... add other titles as needed
];

const Logs: React.FC = () => {
	const [logs, setLogs] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [selectedLog, setSelectedLog] = useState<LogEntry | null>(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalLogs, setTotalLogs] = useState(0);
	const [selectedTitles, setSelectedTitles] = useState<string[]>([]);
	const [appliedTitles, setAppliedTitles] = useState<string[]>([]);
	const {handleGhost} = useGhosting();

	const columns = [
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: 'Message',
			dataIndex: 'message',
			key: 'message',
			ellipsis: true,
		},
		{
			title: 'User',
			key: 'user',
			render: (record: LogEntry) => record.recipient?.email || 'N/A',
		},
		{
			title: 'Created At',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (timestamp: string) => new Date(timestamp).toLocaleString(),
		},
	];

	const handleRowClick = (record: LogEntry) => {
		setSelectedLog(record);
		setIsModalVisible(true);
	};

	const LogDetailModal = () => (
		<Modal title="Log Details" open={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null} width={800} className="top-1 sm:top-10">
			{selectedLog && (
				<div>
					<p>
						<strong>Title:</strong> {selectedLog.title}
					</p>
					Message:
					<Alert message={selectedLog.message} type="info" />
					<p className="flex items-center gap-1 flex-wrap mt-1">
						<strong>User:</strong> {selectedLog.recipient?.email || 'N/A'}
						{selectedLog.recipient?.email && (
							<Button type="primary" className="w-full mb-1 sm:mb-0 sm:w-fit" size="small" icon={<BiGhost />} onClick={() => handleGhost({email: selectedLog.recipient?.email})}>
								Ghost User
							</Button>
						)}
					</p>
					<p>
						<strong>Created At:</strong> {new Date(selectedLog.created_at).toLocaleString()}
					</p>
					<p>
						<strong>Updated At:</strong> {new Date(selectedLog.updated_at).toLocaleString()}
					</p>
					{selectedLog.content_type && (
						<p>
							<strong>Content Type:</strong> {selectedLog.content_type.app_label}.{selectedLog.content_type.model}
						</p>
					)}
					<p>
						<strong>Object ID:</strong> {selectedLog.object_id || 'N/A'}
					</p>
					{selectedLog.content_object_details && (
						<>
							<p>
								<strong>Object Type:</strong> {selectedLog.content_object_details.type || 'N/A'}
							</p>
							{selectedLog.content_object_details.str_representation && (
								<p>
									<strong>Object Description:</strong> {selectedLog.content_object_details.str_representation}
								</p>
							)}
							{selectedLog.content_object_details.error && (
								<p>
									<strong>Object Error:</strong> {selectedLog.content_object_details.error}
								</p>
							)}
							{selectedLog.content_object_details.attributes && (
								<div>
									<strong>Object Attributes:</strong>
									<pre>{JSON.stringify(selectedLog.content_object_details.attributes, null, 2)}</pre>
								</div>
							)}
						</>
					)}
					<p>
						<strong>Activity Type:</strong> {selectedLog.activity_type.action}
					</p>
				</div>
			)}
		</Modal>
	);

	const fetchLogs = async (page = 1) => {
		try {
			setLoading(true);
			const response = await requestAdminLogs(page, appliedTitles);
			if (response?.data) {
				setLogs(response.data.results);
				setTotalLogs(response.data.count);
			}
		} catch (error) {
			message.error('Failed to fetch activity logs!');
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchLogs(currentPage);
	}, [currentPage, appliedTitles]);

	const handleTableChange = (pagination: any) => {
		setCurrentPage(pagination.current);
	};

	const handleTitleChange = (checkedValues: string[]) => {
		setSelectedTitles(checkedValues);
	};

	const handleApplyFilter = () => {
		setAppliedTitles(selectedTitles);
		setCurrentPage(1);
	};

	const handleClearFilters = () => {
		setSelectedTitles([]);
		setAppliedTitles([]);
		setCurrentPage(1);
	};

	return (
		<Card className="w-full overflow-x-auto">
			<Collapse
				className="mb-4  bg-white rounded-lg border"
				bordered={false}
				items={[
					{
						key: '1',
						label: 'Filter Options',
						children: (
							<div>
								<Checkbox.Group
									options={LOG_TITLES}
									value={selectedTitles}
									onChange={handleTitleChange}
									className="flex flex-wrap gap-3 p-3 bg-white rounded-md shadow-border-no-background transition-colors duration-200"
								/>
								<div className="mt-3 space-x-3">
									<Button type="primary" onClick={handleApplyFilter} disabled={JSON.stringify(selectedTitles) === JSON.stringify(appliedTitles)}>
										Apply Filters
									</Button>
									<Button onClick={handleClearFilters} disabled={selectedTitles.length === 0 && appliedTitles.length === 0}>
										Clear Filters
									</Button>
								</div>
							</div>
						),
					},
				]}
			/>
			<Table
				dataSource={logs}
				columns={columns}
				rowKey="id"
				loading={loading}
				pagination={{
					pageSize: 50,
					current: currentPage,
					total: totalLogs,
					onChange: (page) => setCurrentPage(page),
				}}
				onRow={(record) => ({
					onClick: () => handleRowClick(record),
					style: {cursor: 'pointer'},
				})}
				scroll={{x: 1000}}
				onChange={handleTableChange}
				size="small"
			/>
			<LogDetailModal />
		</Card>
	);
};

export default Logs;
