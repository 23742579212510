import React, {useEffect, useState} from 'react';
import {Card, Statistic, Progress, Button, Empty, Spin} from 'antd';
import {ArrowUpOutlined, ArrowDownOutlined, BarChartOutlined} from '@ant-design/icons';
import {RefreshTwoTone} from '@mui/icons-material';
import {LinearProgress, Tooltip, useMediaQuery} from '@mui/material';
import {getCompanyInsightsApi} from '../../api/companyInsightsApi';
// Recharts imports for both the line and pie charts
import {LineChart as RechartsLineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip as RechartsTooltip, Legend, PieChart as RechartsPieChart, Pie, Cell, ResponsiveContainer} from 'recharts';

interface InsightsData {
	total_metrics: {
		total_jobs_posted: number;
		total_active_jobs: number;
		total_completed_jobs: number;
		total_applications: number;
		total_hired: number;
	};
	application_metrics: {
		pending_applications: number;
		accepted_applications: number;
		declined_applications: number;
	};
	monthly_data: {
		month: string;
		jobs_posted: number;
		applications_received: number;
		hires_made: number;
	}[];
	job_categories: {[key: string]: number};
	job_types: {[key: string]: number};
	candidate_fit_metrics: {
		good_fit: number;
		not_a_fit: number;
		undecided: number;
	};
	location_metrics: {[key: string]: number};
	subscription_metrics: {[key: string]: number};
}

export default function CompanyInsightsDashboard() {
	const [insights, setInsights] = useState<InsightsData | null>(null);
	const [loading, setLoading] = useState(true);
	const [refreshing, setRefreshing] = useState(false);
	const isMobile = useMediaQuery('(max-width: 768px)');

	const fetchCompanyInsights = async () => {
		try {
			setRefreshing(true);
			const data = await getCompanyInsightsApi();
			setInsights(data);
		} catch (error) {
			console.error('Failed to fetch company insights:', error);
		} finally {
			setLoading(false);
			setRefreshing(false);
		}
	};

	useEffect(() => {
		fetchCompanyInsights();
	}, []);

	if (loading) {
		return (
			<div className="flex justify-center items-center h-64">
				<Spin size="large" tip="Loading insights..." />
			</div>
		);
	}

	if (!insights) {
		return (
			<div className="p-4">
				<Empty description="No insights data available" image={Empty.PRESENTED_IMAGE_SIMPLE} />
				<div className="flex justify-center mt-4">
					<Button type="primary" onClick={fetchCompanyInsights} icon={<RefreshTwoTone />}>
						Refresh Data
					</Button>
				</div>
			</div>
		);
	}

	// Get the last 6 months of data and reverse to show chronologically
	const recentMonths = [...insights.monthly_data].slice(0, 6).reverse();
	const totalJobs = insights.total_metrics.total_jobs_posted || 1; // Prevent division by zero
	const activeJobsPercentage = (insights.total_metrics.total_active_jobs / totalJobs) * 100;
	const completedJobsPercentage = (insights.total_metrics.total_completed_jobs / totalJobs) * 100;

	// Define custom colors for pub chart (can be re-used in both charts)
	const pieChartColors = ['#1890ff', '#52c41a', '#fa8c16', '#722ed1', '#eb2f96', '#faad14', '#13c2c2', '#f5222d'];

	// Data for the activity line chart - properly formatted from the API response
	const data = recentMonths.map((m) => ({
		month: m.month.split(' ')[0], // Just take the month name without year
		jobs_posted: m.jobs_posted,
		applications_received: m.applications_received,
		hires_made: m.hires_made,
	}));

	function RechartsActivityChart() {
		return (
			<ResponsiveContainer width="100%" height={320}>
				<RechartsLineChart data={data} className="max-w-full">
					<CartesianGrid stroke="#ccc" />
					<XAxis dataKey="month" />
					<YAxis />
					<RechartsTooltip />
					<Legend />
					<Line type="monotone" dataKey="jobs_posted" stroke="#1890ff" name="Jobs posted" />
					<Line type="monotone" dataKey="applications_received" stroke="#52c41a" name="Applications received" />
					{/* <Line type="monotone" dataKey="hires_made" stroke="#fa8c16" name="Hires made" /> */}
				</RechartsLineChart>
			</ResponsiveContainer>
		);
	}

	// New Recharts component for the Pie Chart (Job Categories Distribution)
	function RechartsJobCategoriesPieChart() {
		const pieData = Object.entries(insights.job_categories).map(([category, count], index) => ({
			name: category,
			value: count,
			fill: pieChartColors[index % pieChartColors.length],
		}));

		return (
			<ResponsiveContainer width="100%" height={320}>
				<RechartsPieChart>
					<Pie data={pieData} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={40} outerRadius={80} label>
						{pieData.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={entry.fill} />
						))}
					</Pie>
					<RechartsTooltip formatter={(value) => `${value} jobs`} />
					<Legend layout="horizontal" verticalAlign="bottom" align="center" />
				</RechartsPieChart>
			</ResponsiveContainer>
		);
	}

	return (
		<div className="p-3">
			<Card classNames={{body: 'p-3'}} className="w-full mb-4 shadow-sm">
				<div className="flex items-center justify-between gap-2 flex-wrap">
					<h2 className="text-xl text-darkCharcoal font-semibold flex items-center gap-2 m-0">
						<BarChartOutlined className="text-antBlue" /> Company Insights Dashboard
					</h2>
					<Tooltip title="Update dashboard with latest data">
						<Button type="primary" icon={<RefreshTwoTone className="text-base" />} onClick={fetchCompanyInsights} loading={refreshing}>
							{refreshing ? 'Refreshing...' : 'Refresh Insights'}
						</Button>
					</Tooltip>
				</div>
			</Card>

			{refreshing && <LinearProgress className="mb-4 rounded-md" />}

			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
				{/* Key Metrics Cards */}
				<Card className="shadow-sm hover:shadow-md transition-shadow duration-300">
					<Statistic
						title={<span className="font-medium">Total Jobs Posted</span>}
						value={insights.total_metrics.total_jobs_posted}
						prefix={<ArrowUpOutlined className="text-green-500" />}
						valueStyle={{color: '#3f8600', fontWeight: 'bold'}}
					/>
				</Card>

				<Card className="shadow-sm hover:shadow-md transition-shadow duration-300">
					<Statistic
						title={<span className="font-medium">Active Jobs</span>}
						value={insights.total_metrics.total_active_jobs}
						suffix={<span className="text-gray-500 text-sm">{` of ${totalJobs}`}</span>}
						valueStyle={{fontWeight: 'bold'}}
					/>
					<Progress percent={Math.round(activeJobsPercentage)} strokeColor="#1890ff" status="active" className="mt-2" />
				</Card>

				<Card className="shadow-sm hover:shadow-md transition-shadow duration-300">
					<Statistic
						title={<span className="font-medium">Completed Jobs</span>}
						value={insights.total_metrics.total_completed_jobs}
						suffix={<span className="text-gray-500 text-sm">{` of ${totalJobs}`}</span>}
						valueStyle={{fontWeight: 'bold'}}
					/>
					<Progress percent={Math.round(completedJobsPercentage)} strokeColor="#52c41a" className="mt-2" />
				</Card>
			</div>

			<div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
				{/* Activity Chart */}
				<Card
					title={<span className="font-semibold">6 Month Activity Trends</span>}
					className="shadow-sm hover:shadow-md transition-shadow duration-300"
					extra={<span className="text-xs text-gray-500">Last 6 months</span>}>
					{recentMonths.length > 0 ? <RechartsActivityChart /> : <Empty description="No monthly data available" />}
				</Card>

				{/* Job Categories Distribution using Recharts */}
				<Card title={<span className="font-semibold">Job Categories Distribution</span>} className="shadow-sm hover:shadow-md transition-shadow duration-300">
					{Object.keys(insights.job_categories).length > 0 ? (
						<div className="flex justify-center">
							<RechartsJobCategoriesPieChart />
						</div>
					) : (
						<Empty description="No category data available" />
					)}
				</Card>
			</div>

			<div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
				{/* Location Distribution */}
				<Card title={<span className="font-semibold">Location Distribution</span>} className="shadow-sm hover:shadow-md transition-shadow duration-300">
					{Object.keys(insights.location_metrics).length > 0 ? (
						<div className="max-h-80 overflow-y-auto pr-2">
							{Object.entries(insights.location_metrics)
								.sort(([, a], [, b]) => b - a)
								.map(([location, count], index) => {
									const total = Object.values(insights.location_metrics).reduce((a, b) => a + b, 0);
									const percentage = (count / total) * 100;

									return (
										<div key={location} className="flex justify-between py-2 border-b last:border-0">
											<span className="font-medium text-gray-800">
												{index + 1}. {location}
											</span>
											<div className="flex items-center gap-2 flex-1 ml-4">
												<Progress percent={percentage} size="small" showInfo={false} strokeColor={percentage > 30 ? '#1890ff' : '#52c41a'} />
												<span className="min-w-[80px] text-right">
													{count} <span className="text-xs text-gray-500">({percentage.toFixed(1)}%)</span>
												</span>
											</div>
										</div>
									);
								})}
						</div>
					) : (
						<Empty description="No location data available" />
					)}
				</Card>

				{/* Job Categories List */}
				<Card title={<span className="font-semibold">Top Job Categories</span>} className="shadow-sm hover:shadow-md transition-shadow duration-300">
					{Object.keys(insights.job_categories).length > 0 ? (
						<div className="max-h-80 overflow-y-auto pr-2">
							{Object.entries(insights.job_categories)
								.sort(([, a], [, b]) => b - a)
								.map(([category, count], index) => {
									const total = Object.values(insights.job_categories).reduce((a, b) => a + b, 0);
									const percentage = (count / total) * 100;

									return (
										<div key={category} className="flex justify-between py-2 border-b last:border-0">
											<span className="font-medium text-gray-800">
												{index + 1}. {category}
											</span>
											<div className="flex items-center gap-2 flex-1 ml-4">
												<Progress percent={percentage} size="small" showInfo={false} strokeColor={pieChartColors[index % pieChartColors.length]} />
												<span className="min-w-[80px] text-right">
													{count} <span className="text-xs text-gray-500">({percentage.toFixed(1)}%)</span>
												</span>
											</div>
										</div>
									);
								})}
						</div>
					) : (
						<Empty description="No category data available" />
					)}
				</Card>
			</div>
		</div>
	);
}
