import React, {useState, useEffect} from 'react';
import {Skeleton, Modal, Button} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import {permColors} from '../../utils/colors';

export default function PdfViewer({fileName}: {fileName: string}) {
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(false);

	// Reset states when fileName changes
	useEffect(() => {
		setError(false);
		setIsLoading(true);
	}, [fileName]);

	const handleLoad = () => {
		setIsLoading(false);
		setError(false);
	};

	const handleError = () => {
		setIsLoading(false);
		setError(true);
	};

	const Loading = () => <Skeleton active className="py-20 px-8" />;

	const ErrorMessage = () => (
		<div className="p-8 text-center rounded-lg border border-red-300 bg-red-50 shadow-sm">
			<div className="text-red-600 font-medium text-lg mb-2">Unable to load PDF</div>
			<p className="text-gray-700">The file could not be displayed. Please try downloading it directly or check if the file exists.</p>
			<Button type="primary" className="mt-4" onClick={() => window.open(fileName, '_blank')}>
				Download PDF
			</Button>
		</div>
	);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<div
				className="max-h-[100vh] overflow-y-auto p-2 fun-border scroll-smooth w-full sticky top-0 hover:opacity-70 transition-opacity duration-300 ease-in-out"
				style={{backgroundColor: permColors.backgroundGrey}}>
				{isLoading && <Loading />}
				{error && <ErrorMessage />}
				<div className="relative w-full h-full">
					<div className="relative">
						<iframe
							src={`${fileName}#toolbar=0`}
							width="100%"
							height="600px"
							title="PDF Viewer"
							onLoad={handleLoad}
							onError={handleError}
							style={{display: isLoading ? 'none' : 'block'}}
						/>
						{/* Overlay div to capture clicks */}
						<div className="absolute inset-0 cursor-pointer" onClick={handleClickOpen} aria-label="Click to view PDF in full screen" />
					</div>
				</div>
			</div>
			<Modal
				open={open}
				onCancel={handleClose}
				centered
				className="p-2 w-full sm:w-[90%] "
				classNames={{body: 'h-[85dvh] min-h-[85dvh] overflow-y-auto'}}
				footer={null}
				closeIcon={<Button type="text" className="backdrop-blur-lg bg-white/50" icon={<CloseOutlined />} />}>
				<div className="relative w-full h-full">
					{error && <ErrorMessage />}
					<iframe src={`${fileName}#toolbar=1`} width="100%" height="100%" title="PDF Viewer Modal" onLoad={handleLoad} onError={handleError} />
				</div>
			</Modal>
		</>
	);
}
