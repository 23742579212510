import React, {useEffect, useState} from 'react';
import {Modal, Button, Typography, Image, Row, Col, Card, Divider} from 'antd';
import {AutoAwesome, EmailOutlined, DashboardOutlined, WorkOutline, Psychology} from '@mui/icons-material';
import {permColors} from '../../utils/colors';
import {ROUTES} from '../../utils/ROUTES';

const {Title, Paragraph, Text} = Typography;

const AiInfoModal = ({buttonClassName}) => {
	const [modalVisible, setModalVisible] = useState(false);

	const handleClose = () => {
		setModalVisible(false);
	};
	return (
		<>
			<Button type="link" className={`bg-backgroundGreyBlue ${buttonClassName}`} onClick={() => setModalVisible(true)}>
				Learn more
			</Button>

			<Modal
				title={
					<div className="flex items-center gap-2">
						<AutoAwesome style={{color: permColors.antBlue, fontSize: '24px'}} />
						<span className="text-xl font-semibold">AI-Powered Candidate Feedback</span>
					</div>
				}
				visible={modalVisible}
				centered={true}
				className="mt-2"
				onCancel={handleClose}
				width={1000}
				footer={[
					<div className="flex gap-2 justify-end">
						<Button type="primary" target="_blank" href={ROUTES.POST_JOB}>
							Post Job
						</Button>
						<Button key="close" type="danger" onClick={handleClose}>
							Close
						</Button>
					</div>,
				]}>
				<Typography>
					<Paragraph className="text-base mb-6">
						Our AI-powered candidate feedback system helps employers make better hiring decisions by providing detailed insights on how well candidates match your job requirements.
					</Paragraph>
					<Paragraph className="text-base mb-6">
						Our AI-powered candidate feedback is available with <strong className="text-antBlue">Basic</strong> and <strong className="text-antBlue">Premium</strong> job posts. Experience
						enhanced hiring insights today!{' '}
						<a href={ROUTES.PRICING} target="_blank" className="text-antBlue hover:text-antBlue/80 underline underline-offset-2">
							View our pricing plans
						</a>{' '}
						to learn more.
					</Paragraph>

					<Divider orientation="left">How It Works</Divider>
					<Row gutter={[16, 16]} className="mb-4">
						<Col xs={24} md={12}>
							<Card className="h-full shadow-sm hover:shadow-md transition-shadow">
								<div className="flex items-start gap-2">
									<div className="bg-blue-50 p-1.5 rounded-full flex items-center justify-center">
										<WorkOutline style={{color: permColors.antBlue, fontSize: '18px'}} />
									</div>
									<div>
										<Title level={5}>1. Post Your Job</Title>
										<Paragraph className="text-sm">Create and publish your job listing on our platform with detailed requirements and qualifications.</Paragraph>
									</div>
								</div>
							</Card>
						</Col>
						<Col xs={24} md={12}>
							<Card className="h-full shadow-sm hover:shadow-md transition-shadow">
								<div className="flex items-start gap-2">
									<div className="bg-blue-50 p-1.5 rounded-full flex items-center justify-center">
										<Psychology style={{color: permColors.antBlue, fontSize: '18px'}} />
									</div>
									<div>
										<Title level={5}>2. AI Analysis</Title>
										<Paragraph className="text-sm">
											Our advanced AI analyzes each applicant's CV against your job requirements, evaluating skills, experience, and overall fit.
										</Paragraph>
									</div>
								</div>
							</Card>
						</Col>
						<Col xs={24} md={12}>
							<Card className="h-full shadow-sm hover:shadow-md transition-shadow">
								<div className="flex items-start gap-2">
									<div className="bg-blue-50 p-1.5 rounded-full flex items-center justify-center">
										<EmailOutlined style={{color: permColors.antBlue, fontSize: '18px'}} />
									</div>
									<div>
										<Title level={5}>3. Email Notifications</Title>
										<Paragraph className="text-sm">
											Receive feedback on individial candidate applications via email, highlighting a summary of the candidate's strengths and potential areas of concern.
										</Paragraph>
									</div>
								</div>
							</Card>
						</Col>
						<Col xs={24} md={12}>
							<Card className="h-full shadow-sm hover:shadow-md transition-shadow">
								<div className="flex items-start gap-2">
									<div className="bg-blue-50 p-1.5 rounded-full flex items-center justify-center">
										<DashboardOutlined style={{color: permColors.antBlue, fontSize: '18px'}} />
									</div>
									<div>
										<Title level={5}>4. Dashboard Integration</Title>
										<Paragraph className="text-sm">Access all candidate feedback directly in your Applicants Tracking dashboard for easy comparison and decision-making.</Paragraph>
									</div>
								</div>
							</Card>
						</Col>
					</Row>

					<Divider orientation="left">What You'll Receive</Divider>

					<Row gutter={[24, 24]}>
						<Col xs={24} md={12}>
							<Title level={4}>Email Feedback Example</Title>
							<Paragraph className="mt-2 text-sm text-charcoal">Email application notification and feedback on the candidate's fit for the job.</Paragraph>
							<Image width="100%" src="./images/ai-images/new-app-email.png" alt="Email Feedback Example" className="rounded-md shadow-sm" />
						</Col>
						<Col xs={24} md={12}>
							<Title level={4}>Dashboard View</Title>
							<Paragraph className="mt-2 text-sm text-charcoal">Integrated applicant page that allows you to view all applicants CV, data and feedback in one place.</Paragraph>
							<Image width="100%" src="./images/ai-images/applicant-page-ai.png" alt="Applicants Tracking Dashboard" className="rounded-md shadow-sm" />
						</Col>
					</Row>

					<div className="mt-6 p-4 bg-blue-50 rounded-lg">
						<Text strong className="text-antBlue">
							Included with every Basic and Premium Job Ad
						</Text>
						<Paragraph className="mb-0 mt-1">
							This powerful AI tool is automatically included with all our paid job posting plans, helping you make better and more efficient hiring decisions.
						</Paragraph>
					</div>
				</Typography>
			</Modal>
		</>
	);
};

export default AiInfoModal;
