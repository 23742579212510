import React, {useEffect, useState} from 'react';
import {getAppliedJobsApi} from '../../api/jobsApi';
import {Link} from 'react-router-dom';
import {capitalize} from '@mui/material';
import {textFormatter} from '../../utils/TextAlterations';
import LoadingPartial from '../Loaders/LoadingPartial';
import {Table, Input, Select, Card, Alert} from 'antd';
import {filterByJobType, filterBySearchTerm} from '../../utils/filters';
import {SearchOutlined} from '@ant-design/icons';
import {WorkOutline, WorkOutlined} from '@mui/icons-material';
import {ROUTES} from '../../utils/ROUTES';
const AppliedJobs = () => {
	const [appliedJobs, setAppliedJobs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState('');
	const [jobTypeFilter, setJobTypeFilter] = useState('all');

	async function getLiked() {
		const res = await getAppliedJobsApi();
		console.log(res);
		setAppliedJobs(res);
		setLoading(false);
	}

	useEffect(() => {
		getLiked();
	}, []);

	function applicationStatus(jobOffer) {
		switch (true) {
			case jobOffer?.seen:
				return <Alert banner message="Application viewed" type="success" className="rounded-md" />;
			default:
				return <Alert banner message="Pending review" type="info" className="rounded-md" />;
		}
	}

	if (loading) {
		return <LoadingPartial />;
	}

	const filteredAppliedJobs = appliedJobs.filter(filterBySearchTerm(searchTerm)).filter(filterByJobType(jobTypeFilter));

	const columns = [
		{
			title: 'Job Title',
			dataIndex: 'title',
			key: 'title',
			render: (text, record) => (
				<Link className="underline underline-offset-1 text-antBlue hover:text-antBlue/70" to={`${ROUTES.JOB_PAGE.replace(':jobId', record.id)}`}>
					{capitalize(text)}
				</Link>
			),
		},
		{
			title: 'Company',
			dataIndex: 'company_name',
			key: 'company_name',
			render: (text) => capitalize(text),
		},
		{
			title: 'Review status',
			dataIndex: 'job_offers',
			key: 'status',
			render: (jobOffers) => applicationStatus(jobOffers[0]),
		},
		{
			title: 'Job type',
			dataIndex: 'job_type',
			key: 'job_type',
		},
		{
			title: 'Engagement',
			dataIndex: 'engagement_type',
			key: 'engagement_type',
		},
	];

	return (
		<div className="dashboardComponent">
			<Card classNames={{body: 'p-2'}} className="mb-2">
				<div className="flex justify-between items-center gap-2 flex-wrap">
					<h2 className="flex items-center gap-2 px-2 text-lg font-semibold text-darkCharcoal">
						<WorkOutline className="text-base" /> Applied Jobs
					</h2>
					<div className="flex flex-wrap gap-2">
						<Input
							prefix={<SearchOutlined />}
							placeholder="Search by title or company name"
							onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
							className=" w-[250px] mobile-width-100 min-h-[40px]"
						/>
						<Select
							defaultValue="all"
							className="grow sm:w-[250px] min-h-[42px]"
							options={[
								{label: 'All Job Types', value: 'all'},
								{label: 'Full Time', value: 'full-time'},
								{label: 'Part Time', value: 'part-time'},
							]}
							onChange={(value) => setJobTypeFilter(value)}
						/>
					</div>
				</div>
			</Card>
			<Table bordered dataSource={filteredAppliedJobs} columns={columns} rowKey="id" scroll={{x: true}} />
		</div>
	);
};

export default AppliedJobs;
