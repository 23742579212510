import {useState} from 'react';
import {Input, Card, Avatar, Spin, notification, Pagination, Button, Drawer, Typography, Descriptions, Space, Tag, Image, Table} from 'antd';
import {requestBooleanSearch} from '../../api/admin-api';
import ExportUsersCsv from './helpers/ExportUsersCsv';
import {formatDate2} from '../../utils/DateFunctions';
import {FileTextOutlined, MailOutlined, UserOutlined, CalendarOutlined, ClockCircleOutlined, BuildOutlined} from '@ant-design/icons';
import {permColors} from '../../utils/colors';
import PdfViewer from '../../pages/ApplicantsPage/PdfViewer';

const {Search} = Input;
const {Title, Text, Link} = Typography;

interface UserProfile {
	first_name?: string;
	last_name?: string;
	updated_at?: string;
	user_type?: string;
	company_name?: string | null;
	profile_image?: string;
}

interface UserData {
	id: string;
	email?: string;
	username?: string;
	last_login?: string;
	created_at?: string;
	profile?: UserProfile;
	job_applications_count?: number;
	cv_url?: string;
	cv_text?: string;
}

const BooleanSearch = () => {
	const [search, setSearch] = useState('');
	const [results, setResults] = useState<UserData[]>([]);
	const [loading, setLoading] = useState(false);
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10000,
		total: 0,
	});
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [selectedUser, setSelectedUser] = useState<UserData | null>(null);

	const handleSearch = async (value: string, page = 1, pageSize = 10000) => {
		if (!value) {
			notification.error({
				message: 'Error',
				description: 'Please enter a search query',
				duration: 5,
				closable: true,
			});
			return;
		}
		setLoading(true);
		try {
			const res = await requestBooleanSearch(value, page, pageSize);
			setSearch(value);
			setResults(res.data.results || []);
			setPagination({
				current: res.data.page || 1,
				pageSize: res.data.page_size || 10000,
				total: res.data.count || 0,
			});
		} catch (error) {
			console.error(error);
			notification.error({
				message: 'Error',
				description: error?.message || 'Failed to perform search',
				duration: 5,
				closable: true,
			});
		} finally {
			setLoading(false);
		}
	};

	const handlePageChange = (page: number) => {
		setPagination({
			...pagination,
			current: page,
		});
		handleSearch(search, page, pagination.pageSize);
	};

	const handleAllPageSizeChange = () => {
		setPagination({
			...pagination,
			pageSize: 100000,
		});
		handleSearch(search, 1, 100000);
	};

	const showUserDetails = (user: UserData) => {
		setSelectedUser(user);
		setDrawerVisible(true);
	};

	const closeDrawer = () => {
		setDrawerVisible(false);
	};

	const columns = [
		{
			title: 'User',
			dataIndex: 'user',
			key: 'user',
			render: (_, record: UserData) => (
				<div className="flex items-center">
					<Avatar src={record.profile?.profile_image} className="mr-2" />
					<div>
						<div className="font-medium">
							{record.profile?.first_name || ''} {record.profile?.last_name || ''}
							{!record.profile?.first_name && !record.profile?.last_name && (record?.username || 'Unknown User')}
						</div>
						<div className="text-gray-500">{record?.email || 'No email provided'}</div>
					</div>
				</div>
			),
		},
		{
			title: 'User Type',
			dataIndex: ['profile', 'user_type'],
			key: 'userType',
			render: (text: string) => text || 'N/A',
		},
		{
			title: 'Company',
			dataIndex: ['profile', 'company_name'],
			key: 'company',
			render: (text: string) => text || 'N/A',
		},
		{
			title: 'Last Login',
			dataIndex: 'last_login',
			key: 'lastLogin',
			render: (text: string) => (text ? new Date(text).toLocaleString() : 'Never'),
		},
		{
			title: 'Created At',
			dataIndex: 'created_at',
			key: 'createdAt',
			render: (text: string) => (text ? new Date(text).toLocaleString() : 'Unknown'),
		},
		{
			title: 'Updated At',
			dataIndex: ['profile', 'updated_at'],
			key: 'updatedAt',
			render: (text: string) => (text ? formatDate2(text) : 'Unknown'),
		},
		{
			title: 'Applications',
			dataIndex: 'job_applications_count',
			key: 'applications',
			render: (text: number) => text ?? 'N/A',
		},
	];

	return (
		<div className="p-4 max-w-full w-full max-h-full overflow-y-auto">
			<Search
				placeholder="Enter boolean search query (e.g. python AND (django OR flask))"
				enterButton="Search"
				onChange={(e) => setSearch(e.target.value)}
				size="large"
				onSearch={(value) => handleSearch(value, 1)}
				className="mb-4"
				disabled={loading}
			/>
			{loading ? (
				<Spin size="large" className="flex justify-center items-center" />
			) : (
				<>
					<div className="flex gap-2 mb-3 items-center justify-between">
						<div className="text-gray-500">{pagination.total > 0 ? `Found ${pagination.total} results` : 'No results found'}</div>
						{results.length > 0 && (
							<div className="flex gap-2">
								<ExportUsersCsv users={results as any} />
								<Button type="default" onClick={handleAllPageSizeChange}>
									Show All results
								</Button>
							</div>
						)}
					</div>
					<div className="mb-4 max-w-full overflow-x-auto p-1">
						<Table
							dataSource={results}
							columns={columns}
							rowKey={(record) => record.id || Math.random().toString()}
							pagination={false}
							onRow={(record) => ({
								onClick: () => showUserDetails(record),
							})}
							size="small"
							rowClassName={(record, index) =>
								index % 2 === 0
									? 'cursor-pointer hover:bg-backgroundGreyBlue transition-all duration-300'
									: 'bg-backgroundGreyBlue cursor-pointer hover:bg-backgroundGrey transition-all duration-300'
							}
							locale={{emptyText: 'No rows to display.'}}
							className="p-3 shadow-border-chill-background rounded-lg"
							scroll={{x: '100%'}}
							components={{
								header: {
									cell: (props) => <th {...props} className="border-0  font-semibold text-gray-700 py-2" />,
									row: (props) => <tr {...props} className="bg-gray-100 rounded-md border-0" />,
								},
								body: {
									row: (props) => <tr {...props} style={{borderTop: 'none', borderBottom: 'none'}} />,
									cell: (props) => <td {...props} style={{border: 'none'}} />,
								},
							}}
						/>
					</div>
					{pagination.total > 0 && (
						<div className="flex justify-center mt-4">
							<Pagination current={pagination.current} pageSize={pagination.pageSize} total={pagination.total} onChange={handlePageChange} showSizeChanger={false} />
						</div>
					)}
				</>
			)}

			<Drawer
				title={
					<div className="flex items-center">
						<Avatar src={selectedUser?.profile?.profile_image} size={64} className="mr-4" icon={<UserOutlined />} />
						<div>
							<Title level={4} style={{margin: 0}}>
								{selectedUser?.profile?.first_name || ''} {selectedUser?.profile?.last_name || ''}
								{!selectedUser?.profile?.first_name && !selectedUser?.profile?.last_name && (selectedUser?.username || 'Unknown User')}
							</Title>
							<Text type="secondary">{selectedUser?.email}</Text>
						</div>
					</div>
				}
				styles={{
					header: {backgroundColor: permColors.white, borderRadius: '6px 6px 0 0'},
					wrapper: {marginRight: '12px', marginTop: '12px', marginBottom: '12px'},
					content: {borderRadius: '6px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'},
					mask: {backdropFilter: 'blur(4px)'},
				}}
				placement="right"
				onClose={closeDrawer}
				open={drawerVisible}
				width={500}
				extra={
					<Space>
						<Button onClick={closeDrawer}>Close</Button>
					</Space>
				}>
				{selectedUser && (
					<div className="user-details">
						<Descriptions bordered column={1} size="small" className="mb-6">
							<Descriptions.Item label="User ID">{selectedUser.id}</Descriptions.Item>
							<Descriptions.Item label="Username">{selectedUser.username || 'N/A'}</Descriptions.Item>
							<Descriptions.Item label="Email">
								<Space>
									<MailOutlined />
									{selectedUser.email || 'N/A'}
								</Space>
							</Descriptions.Item>
							<Descriptions.Item label="User Type">
								<Tag color={selectedUser.profile?.user_type === 'Job-Seeker' ? 'blue' : 'green'}>{selectedUser.profile?.user_type || 'N/A'}</Tag>
							</Descriptions.Item>
							{selectedUser.profile?.company_name && (
								<Descriptions.Item label="Company">
									<Space>
										<BuildOutlined />
										{selectedUser.profile.company_name}
									</Space>
								</Descriptions.Item>
							)}
						</Descriptions>

						<Card title="Activity Information" className="mb-6" size="small">
							<p>
								<ClockCircleOutlined className="mr-2" />
								<strong>Last Login:</strong> {selectedUser.last_login ? new Date(selectedUser.last_login).toLocaleString() : 'Never'}
							</p>
							<p>
								<CalendarOutlined className="mr-2" />
								<strong>Created At:</strong> {selectedUser.created_at ? new Date(selectedUser.created_at).toLocaleString() : 'Unknown'}
							</p>
							<p>
								<CalendarOutlined className="mr-2" />
								<strong>Profile Updated At:</strong> {selectedUser.profile?.updated_at ? formatDate2(selectedUser.profile.updated_at) : 'Unknown'}
							</p>
							<p>
								<strong>Job Applications:</strong> {selectedUser.job_applications_count ?? 'N/A'}
							</p>
						</Card>

						{selectedUser.cv_url && (
							<Card title="Resume/CV" size="small">
								<div className="flex items-center">
									<FileTextOutlined className="mr-2 text-lg" />
									<Link href={selectedUser.cv_url} target="_blank">
										View Resume/CV
									</Link>
								</div>
								{!selectedUser.cv_url.includes('.pdf') && <p>This is not a PDF file. Please download the file to view it.</p>}
								<PdfViewer fileName={selectedUser.cv_url} />
							</Card>
						)}
					</div>
				)}
			</Drawer>
		</div>
	);
};

export default BooleanSearch;
