import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import './Popups.scss';
import {sendNewMessageApi} from '../../api/messagingApi';
import {Modal, Input, Button, Alert, Form} from 'antd';
import {ROUTES} from '../../utils/ROUTES';
import {useAtom} from 'jotai';
import {selectedAtom} from '../../utils/atoms';
import {conversationsTriggeredAtom} from '../../utils/atoms/dashboardAtoms';

const {TextArea} = Input;

export default function MessagePopup(props) {
	const {showPopup, message, closePopup, link, linkName, title, textArea, buttonText, disabledButtonText, buttonFunction, user} = props;
	const [responseMessage, setResponseMessage] = useState('');
	const [disabledButton, setDisabledButton] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useAtom(selectedAtom);
	const [conversationsTriggered, setConversationsTriggered] = useAtom(conversationsTriggeredAtom);
	const navigate = useNavigate();

	const handleLinkClick = () => {
		setSelected('inbox');
		navigate(ROUTES.DASHBOARD);
	};

	const submit = async (values) => {
		setLoading(true);
		const res = await sendNewMessageApi(values.textAreaValue, user);
		if (res === true) {
			setResponseMessage(
				<span>
					Message sent successfully. To view your message, see your{' '}
					<Button className="p-0 m-0" type="link" onClick={handleLinkClick}>
						inbox
					</Button>
					.
				</span>
			);
			setDisabledButton(true);
			setLoading(false);
			setConversationsTriggered(false);
		}
		console.log(res);
		setLoading(false);
	};

	const handleCancel = () => {
		closePopup();
		setResponseMessage('');
		setDisabledButton(false);
	};

	if (!showPopup) {
		return null;
	}

	return (
		<Modal open={showPopup} onCancel={handleCancel} title={title} footer={null}>
			<p>{message}</p>
			{textArea && (
				<Form onFinish={submit} initialValues={{textAreaValue: ''}}>
					<div className="mb-2">
						<p className="mb-2">Type your message below</p>
						<Form.Item name="textAreaValue" rules={[{required: true, message: 'Please enter your message'}]}>
							<TextArea rows={3} />
						</Form.Item>
					</div>
					{responseMessage && <Alert message={responseMessage} type="success" showIcon banner className="mb-2 rounded-md" />}
					{buttonText && (
						<Button type="primary" block htmlType="submit" disabled={disabledButton || loading} loading={loading}>
							{disabledButton ? disabledButtonText : buttonText}
						</Button>
					)}
				</Form>
			)}
			{link && <Link to={link}></Link>}
		</Modal>
	);
}
