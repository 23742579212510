import React, {useState, useEffect} from 'react';
import {Card, Table, Statistic, Row, Col, Badge, Spin, Alert, Pagination, Tag, Space, DatePicker} from 'antd';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, Legend} from 'recharts';
import {ExclamationCircleOutlined, ClockCircleOutlined} from '@ant-design/icons';
import api from '../../api/interceptor';
import {mainUrl} from '../../api/Urls';

const {RangePicker} = DatePicker;

interface StatusCodeCount {
	status_code: number;
	count: number;
}

interface TopEndpoint {
	endpoint: string;
	count: number;
}

interface DailyErrorCount {
	day: string;
	count: number;
}

interface DailyErrorByStatusCode {
	day: string;
	'4xx': number;
	'5xx': number;
}

interface ErrorLog {
	id: string;
	endpoint: string;
	method: string;
	status_code: number;
	error_message: string;
	user: string | null;
	user_email: string | null;
	ip_address: string;
	created_at: string;
}

interface PaginationInfo {
	count: number;
	next: string | null;
	previous: string | null;
	current_page: number;
	total_pages: number;
}

interface ErrorLogsResponse {
	stats: {
		total_count: number;
		filtered_count: number;
		today_count: number;
		yesterday_count: number;
		last_7_days_count: number;
		status_code_counts: StatusCodeCount[];
		top_endpoints: TopEndpoint[];
		recent_errors_by_day: DailyErrorCount[];
	};
	results: ErrorLog[];
	pagination: PaginationInfo;
}

const ErrorLogs: React.FC = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const [data, setData] = useState<ErrorLogsResponse | null>(null);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [dailyErrorsByStatusCode, setDailyErrorsByStatusCode] = useState<DailyErrorByStatusCode[]>([]);

	useEffect(() => {
		fetchErrorLogs(currentPage);
	}, [currentPage]);

	const fetchErrorLogs = async (page: number) => {
		setLoading(true);
		try {
			// This would be replaced with your actual API call
			const response = await api.get(`${mainUrl}/auth/admin/api-error-logs/`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			});
			console.log(response.data);
			const data = response.data;
			setData(data);

			// Process data for the status code breakdown by day
			if (data.stats.recent_errors_by_day) {
				// This is a simplified example - in a real implementation,
				// you would get this data from your API or calculate it from raw data
				const statusCodeByDay = data.stats.recent_errors_by_day.map((dayData) => {
					// Randomly split the count between 4xx and 5xx for demonstration
					const fourXXCount = Math.floor(dayData.count * 0.8);
					const fiveXXCount = dayData.count - fourXXCount;

					return {
						day: dayData.day,
						'4xx': fourXXCount,
						'5xx': fiveXXCount,
					};
				});

				setDailyErrorsByStatusCode(statusCodeByDay);
			}

			setError(null);
		} catch (err) {
			setError('Failed to fetch error logs. Please try again later.');
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const getStatusColor = (status: number) => {
		if (status < 400) return 'green';
		if (status < 500) return 'orange';
		return 'red';
	};

	const columns = [
		{
			title: 'Time',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (text: string) => <span className="whitespace-nowrap">{new Date(text).toLocaleString()}</span>,
		},
		{
			title: 'Endpoint',
			dataIndex: 'endpoint',
			key: 'endpoint',
			render: (text: string) => <span className="font-mono text-sm">{text}</span>,
		},
		{
			title: 'Method',
			dataIndex: 'method',
			key: 'method',
			render: (text: string) => {
				const colorMap: Record<string, string> = {
					GET: 'blue',
					POST: 'green',
					PUT: 'orange',
					DELETE: 'red',
					PATCH: 'purple',
				};
				return <Tag color={colorMap[text] || 'default'}>{text}</Tag>;
			},
		},
		{
			title: 'Status',
			dataIndex: 'status_code',
			key: 'status_code',
			render: (code: number) => <Tag color={getStatusColor(code)}>{code}</Tag>,
		},
		{
			title: 'Error Message',
			dataIndex: 'error_message',
			key: 'error_message',
			ellipsis: true,
		},
		{
			title: 'User',
			dataIndex: 'user_email',
			key: 'user_email',
			render: (text: string | null) => text || '-',
		},
		{
			title: 'IP Address',
			dataIndex: 'ip_address',
			key: 'ip_address',
		},
	];

	if (loading && !data) {
		return (
			<div className="flex justify-center items-center h-64">
				<Spin size="large" />
			</div>
		);
	}

	if (error) {
		return <Alert message="Error" description={error} type="error" showIcon />;
	}

	if (!data) {
		return null;
	}

	return (
		<div className="p-6 max-w-full max-h-full overflow-y-auto">
			<h1 className="text-2xl font-bold mb-6">Error Logs</h1>

			{/* Filters */}
			<Card className="mb-6">
				<div className="flex flex-wrap gap-4">
					<RangePicker className="w-64" />
					{/* Add more filters as needed */}
				</div>
			</Card>

			{/* Stats Overview */}
			<div className="mb-6">
				<Row gutter={[16, 16]}>
					<Col xs={24} sm={12} md={8} lg={6} xl={4}>
						<Card>
							<Statistic title="Total Errors" value={data.stats.total_count} valueStyle={{color: '#cf1322'}} prefix={<ExclamationCircleOutlined />} />
						</Card>
					</Col>
					<Col xs={24} sm={12} md={8} lg={6} xl={4}>
						<Card>
							<Statistic title="Today" value={data.stats.today_count} valueStyle={{color: '#3f8600'}} />
						</Card>
					</Col>
					<Col xs={24} sm={12} md={8} lg={6} xl={4}>
						<Card>
							<Statistic title="Yesterday" value={data.stats.yesterday_count} />
						</Card>
					</Col>
					<Col xs={24} sm={12} md={8} lg={6} xl={4}>
						<Card>
							<Statistic title="Last 7 Days" value={data.stats.last_7_days_count} prefix={<ClockCircleOutlined />} />
						</Card>
					</Col>
				</Row>
			</div>

			{/* Charts */}
			<Row gutter={[16, 16]} className="mb-6">
				<Col xs={24} lg={12}>
					<Card title="Errors by Status Code">
						<ResponsiveContainer width="100%" height={300}>
							<BarChart data={data.stats.status_code_counts}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="status_code" />
								<YAxis />
								<Tooltip />
								<Bar dataKey="count" fill="#8884d8" name="Count" isAnimationActive={false} />
							</BarChart>
						</ResponsiveContainer>
					</Card>
				</Col>
				<Col xs={24} lg={12}>
					<Card title="Errors Over Time">
						<ResponsiveContainer width="100%" height={300}>
							<LineChart data={data.stats.recent_errors_by_day}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="day" />
								<YAxis />
								<Tooltip />
								<Line type="monotone" dataKey="count" stroke="#ff7300" name="Errors" isAnimationActive={false} />
							</LineChart>
						</ResponsiveContainer>
					</Card>
				</Col>
			</Row>

			{/* Error Type Trends */}
			<Card title="Error Type Trends (Last 7 Days)" className="mb-6">
				<ResponsiveContainer width="100%" height={300}>
					<LineChart data={dailyErrorsByStatusCode}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="day" />
						<YAxis />
						<Tooltip />
						<Legend />
						<Line type="monotone" dataKey="4xx" stroke="#ff7300" name="Client Errors (4xx)" strokeWidth={2} dot={{r: 4}} activeDot={{r: 6}} isAnimationActive={false} />
						<Line type="monotone" dataKey="5xx" stroke="#ff0000" name="Server Errors (5xx)" strokeWidth={2} dot={{r: 4}} activeDot={{r: 6}} isAnimationActive={false} />
					</LineChart>
				</ResponsiveContainer>
			</Card>

			{/* Top Endpoints */}
			<Card title="Top Error Endpoints" className="mb-6">
				<ResponsiveContainer width="100%" height={200}>
					<BarChart data={data.stats.top_endpoints} layout="vertical" margin={{top: 5, right: 30, left: 20, bottom: 5}}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis type="number" />
						<YAxis type="category" dataKey="endpoint" width={150} tick={{fontSize: 12}} />
						<Tooltip />
						<Bar dataKey="count" fill="#82ca9d" name="Count" isAnimationActive={false} />
					</BarChart>
				</ResponsiveContainer>
			</Card>

			{/* Error Logs Table */}
			<Card title="Error Log Details" className="mb-6">
				<Table columns={columns} dataSource={data.results} rowKey="id" pagination={false} className="overflow-x-auto" scroll={{x: 'max-content'}} />
				<div className="mt-4 flex justify-end">
					<Pagination current={data.pagination.current_page} total={data.pagination.count} pageSize={data.results.length} onChange={setCurrentPage} showSizeChanger={false} showQuickJumper />
				</div>
			</Card>
		</div>
	);
};

export default ErrorLogs;
