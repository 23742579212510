import React from 'react';
import {Card, Input, Button, Form, notification} from 'antd';
import {handleGhostUser} from '../../api/admin-api';

export const useGhosting = () => {
	const [loading, setLoading] = React.useState(false);

	const handleGhost = async (values: {email: string}) => {
		try {
			setLoading(true);
			const data = await handleGhostUser(values.email.toLowerCase());

			// Update tokens
			localStorage.setItem('accessToken', data.tokens.access);
			localStorage.setItem('token', data.tokens.refresh);

			window.location.href = '/';
		} catch (error: any) {
			notification.error({
				message: 'Failed to ghost user',
				description: error.response?.data?.error || 'Failed to ghost user',
				duration: 5,
				placement: 'top',
			});
		} finally {
			setLoading(false);
		}
	};

	return {loading, handleGhost};
};

export default function Ghosting() {
	const [form] = Form.useForm();
	const {loading, handleGhost} = useGhosting();

	return (
		<Card title="Admin Ghost Mode" className="max-w-[500px] w-full h-fit">
			<Form form={form} onFinish={handleGhost} layout="vertical">
				<Form.Item name="email" label="User Email to Ghost" rules={[{required: true, message: 'Please enter an email'}]}>
					<Input placeholder="Enter user email" />
				</Form.Item>

				<Form.Item className="mb-0">
					<div className="space-x-4">
						<Button type="primary" className="w-full" htmlType="submit" loading={loading}>
							Ghost User
						</Button>
					</div>
				</Form.Item>
			</Form>
		</Card>
	);
}
