import api from './interceptor';
import {mainUrl} from './Urls';

export const requestAdminLogs = async (page = 1, selectedTitles = []) => {
	try {
		const res = await api.get(`${mainUrl}/auth/admin/activity-logs?page=${page}`, {
			params: {
				titles: selectedTitles,
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		return res;
	} catch (error) {
		console.error(error);
	}
};

export const requestBooleanSearch = async (search, page = 1, pageSize = 50) => {
	const res = await api.get(`${mainUrl}/auth/admin/boolean-search-cv-text/?query=${search}&page=${page}&page_size=${pageSize}`, {
		headers: {
			Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
		},
	});
	return res;
};

export const handleGhostUser = async (email) => {
	const res = await api.post(
		`${mainUrl}/auth/ghost/`,
		{email},
		{
			headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`},
		}
	);
	return res.data;
};
