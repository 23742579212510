import {useEffect, useState} from 'react';
import './DashboardComponent.scss';
import {Link, useNavigate} from 'react-router-dom';
import {textFormatter, capitalizeFirstLetter} from '../../utils/TextAlterations';
import LoadingPartial from '../Loaders/LoadingPartial';
import {Table, Button, Space, Popconfirm, Input, Select, Tooltip, Card} from 'antd';
import {filterByJobType, filterBySearchTerm} from '../../utils/filters';
import {DeleteOutlined, EditOutlined, WorkOffOutlined, Queue} from '@mui/icons-material';
import {ROUTES} from '../../utils/ROUTES';
import {deleteJobApi, getMyClosedJobsApi} from '../../api/jobsApi';
import {daysAgo, formatDate2} from '../../utils/DateFunctions';
import {closedJobsAtom, closedJobsTriggeredAtom} from '../../utils/atoms/dashboardAtoms';
import {useAtom} from 'jotai';

export default function ClosedJob() {
	const [closedJobs, setClosedJobs] = useAtom(closedJobsAtom);
	const [closedJobsTriggered, setClosedJobsTriggered] = useAtom(closedJobsTriggeredAtom);
	const [loading, setLoading] = useState(true);
	const [pageNumber, setPageNumber] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');
	const [jobTypeFilter, setJobTypeFilter] = useState('all');
	const navigate = useNavigate();

	useEffect(() => {
		if (!closedJobsTriggered) {
			fetchMyJobs();
		} else {
			setLoading(false);
		}
		const resizeObserverError = window.addEventListener('error', (e) => {
			if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
				e.stopImmediatePropagation();
			}
		});
	}, []);

	const fetchMyJobs = async () => {
		setLoading(true);
		const response = await getMyClosedJobsApi();
		setLoading(false);
		if (response) {
			console.log('responsible', response);
			setClosedJobs(response);
		}
		setClosedJobsTriggered(true);
	};

	const navigatePostJob = () => {
		navigate(ROUTES.POST_JOB);
	};

	const columns = [
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			render: (text, record) => (
				<Link className="underline underline-offset-1 text-antBlue hover:text-antBlue/70" to={`${ROUTES.JOB_PAGE.replace(':jobId', record.id)}`}>
					{capitalizeFirstLetter(text)}
				</Link>
			),
		},
		{
			title: 'Company',
			dataIndex: 'company_name',
			key: 'company_name',
			render: capitalizeFirstLetter,
		},
		{
			title: 'Type',
			dataIndex: 'job_type',
			key: 'job_type',
		},
		{
			title: 'Engagement',
			dataIndex: 'engagement_type',
			key: 'engagement_type',
		},
		{
			title: 'Closed Date',
			dataIndex: 'closed_date',
			key: 'closed_date',
			render: (_, record) => (record.completed_at ? formatDate2(record.completed_at) : 'N/A'),
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
			render: (text) => <div dangerouslySetInnerHTML={{__html: textFormatter(text)}} style={{maxHeight: '120px', overflowY: 'auto'}} />,
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<Button onClick={() => navigate(`/applicants/${record.id}`)}>View Applicants</Button>
				</Space>
			),
		},
	];

	const filteredJobs = closedJobs.filter(filterBySearchTerm(searchTerm)).filter(filterByJobType(jobTypeFilter));

	if (loading) {
		return <LoadingPartial />;
	}

	return (
		<div className="dashboardComponent">
			<Card classNames={{body: 'p-2'}} className="mb-2">
				<div className="flex justify-between items-center gap-2 flex-wrap">
					<h2 className="flex items-center gap-2 px-2 text-lg font-semibold text-darkCharcoal">
						<WorkOffOutlined className="text-base" /> My closed jobs
					</h2>
					<div className="flex flex-wrap gap-2">
						<Input type="text" placeholder="Search by job title" onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} className="p-2 w-[250px] mobile-width-100" />
						<Select
							defaultValue="all"
							className="grow sm:w-[250px]"
							options={[
								{label: 'All Job Types', value: 'all'},
								{label: 'Full Time', value: 'full-time'},
								{label: 'Part Time', value: 'part-time'},
							]}
							onChange={(e) => setJobTypeFilter(e || 'all')}
						/>
						<Button onClick={navigatePostJob} className="min-h-[40px] grow" icon={<Queue className="text-base" />}>
							Post a job
						</Button>
					</div>
				</div>
			</Card>
			<Table
				bordered
				dataSource={filteredJobs}
				columns={columns}
				rowKey={(record) => record?.id || Math.random().toString()}
				pagination={{current: pageNumber, onChange: setPageNumber}}
				scroll={{x: true}}
			/>
		</div>
	);
}
