import React, {useEffect, useState} from 'react';
import './Applicants.scss';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {Alert, capitalize, useMediaQuery} from '@mui/material';
import {Alert as AntAlert} from 'antd';
import Hidden from '@mui/material/Hidden';
import {useParams} from 'react-router-dom';
import {deleteJobApi, getJobById, getJobOffersApi, markJobOfferAsSeenApi, updateJobOfferApi} from '../../api/jobsApi';
import {formatDate2} from '../../utils/DateFunctions';
import {useNavigate} from 'react-router-dom';
import {LocationOn, Person, WorkOutlined} from '@mui/icons-material';
import Loading from '../../components/Loaders/Loading';
import {Helmet} from 'react-helmet';
import {permColors} from '../../utils/colors';
import {Button, Card, Checkbox, Dropdown, notification, Tag} from 'antd';
import {Button as AntButton, Input, Select, Modal} from 'antd';
import {filterByApplicantName} from '../../utils/filters';
import {returnApplicantFit, returnApplicantFitSmall, ReturnApplicantOtherButtons, returnApplicantSelectThumbs} from './helper';
import ApplicantComponent from './ApplicantComponent';
import TabSelector from './TabSelector';
import {daysAgo} from '../../utils/DateFunctions';
import {CloseOutlined, EditOutlined, EyeFilled, EyeOutlined, InfoOutlined, SettingOutlined, UserOutlined} from '@ant-design/icons';
import {ROUTES} from '../../utils/ROUTES';

const Applicants = () => {
	const {jobId} = useParams('jobId');
	const [applicants, setApplicants] = useState([]);
	const [selectedApplicant, setSelectedApplicant] = useState(null);
	const [show, setShow] = useState(false);
	const isSmallViewport = useMediaQuery('(max-width: 1024px)');
	const [job, setJob] = useState(null);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [markedAsSeen, setMarkedAsSeen] = useState({});
	const [modalShow, setModalShow] = useState(false);
	const [value, setValue] = React.useState(0);
	const [searchText, setSearchText] = useState('');
	const [candidateFit, setCandidateFit] = useState('All');
	const [selecterLoader, setSelecterLoader] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [checked, setChecked] = useState(false);

	const handleModalClose = () => setModalShow(false);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleClose = () => {
		setShow(false);
	};

	const handleShow = async (applicant) => {
		if (isSmallViewport) {
			setShow(true);
		}
		setSelectedApplicant(applicant);

		if (!markedAsSeen[applicant.id] && applicant.seen === false) {
			await markJobOfferAsSeenApi(applicant.id);
			setMarkedAsSeen((prevState) => ({
				...prevState,
				[applicant.id]: true,
			}));
		}
	};

	async function getApplicants() {
		setSelectedApplicant(null);
		setApplicants([]);

		const res2 = await getJobById(jobId);
		const res = await getJobOffersApi(jobId);
		setApplicants(res);
		setJob(res2);
		setLoading(false);
	}

	useEffect(() => {
		getApplicants();
	}, [jobId]);

	async function submitCandidateFitChange(e) {
		setSelecterLoader(true);
		await updateJobOfferApi(selectedApplicant.id, e);
		const res = await getJobOffersApi(jobId);
		setApplicants(res);
		setSelectedApplicant(res.filter((applicant) => applicant.id === selectedApplicant.id)[0]);
		setSelecterLoader(false);
	}

	if (loading) {
		return <Loading />;
	}

	const deleteJob = async (id) => {
		handleModalClose();
		setIsDeleting(true);
		const res = await deleteJobApi(id);
		if (res === true) {
			navigate('/dashboard');
		} else {
			setIsDeleting(false);
			notification.error({
				message: 'Error',
				description: 'Failed to close job. Please try again.',
				duration: 5,
			});
		}
	};

	const filteredApplicants = applicants.filter(filterByApplicantName(searchText, candidateFit));

	return (
		<div className="applicants" style={{backgroundColor: permColors.backgroundGreyBlue}}>
			<Helmet>
				<title>Applicants | Permworks</title>
				<meta name="description" content="View all the applicants to your job posting on this page. You can also manage your job posting here." />
			</Helmet>
			<Navbar />
			<Container className=" mb-2">
				<Row md={1}>
					<Col className="mb-2 mt-2">
						<Card className=" text-left " classNames={{body: 'p-4'}}>
							<div className="flex flex-col justify-between gap-1">
								<div className="flex items-center justify-between gap-2 mb-4">
									<div className="rounded-full border border-gray-200 p-2 w-fit">
										{job?.created_by_image ? <img src={job?.created_by_image} alt="profile" className="working-icon p-0" /> : <WorkOutlined className="working-icon" />}
									</div>
								</div>

								<p className="text-base font-semibold flex items-center gap-2" style={{color: permColors.charcoal}}>
									{job?.company_name && capitalize(job?.company_name)}
									<span className="text-base font-light opacity-80" style={{color: permColors.grey}}>
										{job?.created_at !== null ? `Posted ${daysAgo(job?.created_at)} days ago` : ''}
									</span>
								</p>

								<span className="text-2xl font-medium mb-2" style={{color: permColors.darkCharcoal}}>
									{job?.title && capitalize(job?.title)}
								</span>

								<div className="flex items-center flex-wrap gap-2">
									<Tag className="text-sm m-0">{job.engagement_type}</Tag>
									<Tag className="text-sm m-0">{job.category}</Tag>
									<Tag className="text-sm m-0">{job.job_type}</Tag>
								</div>

								<div className="flex items-center gap-2 flex-wrap justify-between mt-2">
									<div>
										{(job?.monthly_salary || job?.hourly_rate) && (
											<p className="text-base font-semibold" style={{color: permColors.darkCharcoal}}>
												{job?.monthly_salary && (
													<span>
														${parseFloat(job.monthly_salary).toString()}
														{job?.max_monthly_salary !== null && <span> - ${parseFloat(job.max_monthly_salary).toString()}</span>} /month (USD)
													</span>
												)}
												{job?.hourly_rate && (
													<span>
														${parseFloat(job.hourly_rate).toString()}
														{job?.max_hourly_rate !== null && <span> - ${parseFloat(job.max_hourly_rate).toString()}</span>} /hr (USD)
													</span>
												)}
											</p>
										)}

										<div className="flex items-center gap-2">
											<LocationOn style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
											<span className="text-sm" style={{color: permColors.charcoal}}>
												<span className="font-semibold">Company location:</span> {job.country}
											</span>
										</div>
									</div>
									<div className="flex items-center gap-2">
										<AntButton className="p-3" onClick={() => navigate(ROUTES.JOB_PAGE.replace(':jobId', job?.id))} icon={<EyeOutlined />} style={{color: permColors.charcoal}}>
											View job
										</AntButton>
										{!job?.completed && (
											<div>
												<Dropdown
													menu={{
														items: [
															{
																key: '1',
																label: 'Edit job',
																icon: <EditOutlined />,
																onClick: () => navigate(`/edit-job/${job.id}`),
															},
															{
																key: '2',
																label: 'Close job',
																icon: <CloseOutlined />,
																onClick: () => setModalShow(true),
															},
														],
													}}
													trigger={['click']}>
													<AntButton className="flex items-center gap-2" icon={<SettingOutlined className="text-sm" />}>
														Manage Job
													</AntButton>
												</Dropdown>
											</div>
										)}
									</div>
								</div>
								<hr className="my-3 opacity-10 text-grey" />
								<div className="flex flex-wrap gap-2 w-[550px] max-w-full ">
									<Input type="text" placeholder="Search by name..." value={searchText} onChange={(e) => setSearchText(e.target.value)} className="w-[250px] grow p-2" />
									<Select
										aria-label="Default select example"
										placeholder="Candidate fit"
										defaultValue="All"
										onChange={(e) => setCandidateFit(e)}
										className="w-[250px] grow"
										options={[
											{label: 'All candidates', value: 'All'},
											{label: 'Undecided', value: 'Undecided'},
											{label: 'Good fit', value: 'Good_fit'},
											{label: 'Not a fit', value: 'Not_a_fit'},
										]}
									/>
								</div>
							</div>
						</Card>
					</Col>
				</Row>
				<Row lg={3}>
					<Col style={{flex: 1}} className="pr-3 lg:pr-0">
						<Card
							classNames={{body: 'p-0', header: 'p-3 min-h-fit'}}
							title={
								<div className="text-sm font-medium text-darkCharcoal flex items-center gap-2">
									<Person className="text-antBlue text-base" />
									{filteredApplicants?.length} applicant{filteredApplicants?.length === 1 ? '' : 's'}
								</div>
							}>
							<div style={{maxHeight: '500px', overflowY: 'auto'}}>
								{filteredApplicants.length > 0 ? (
									filteredApplicants?.map((applicant, index) => (
										<div
											key={index}
											onClick={() => handleShow(applicant)}
											className={`
												p-3 cursor-pointer transition-all duration-200
												hover:bg-backgroundGreyBlue border-lightblue border-l-4  
												${selectedApplicant?.id === applicant.id ? 'bg-backgroundGreyBlue border-linkBlue' : ''}
											`}>
											<div className="flex items-center gap-3">
												<Avatar size={40} src={applicant.freelancer?.profile?.profile_image ?? '/static/images/avatar/default.jpg'} />
												<div className="flex-1">
													<div className="flex items-center justify-between">
														<span className="font-medium text-gray-900">
															{applicant.freelancer?.profile?.first_name ?? 'Unknown'} {applicant.freelancer?.profile?.last_name ?? 'Applicant'}
														</span>
														{applicant?.seen === false && <span className="bg-blue-500 h-2 w-2 rounded-full" />}
													</div>
													<div className="text-sm text-gray-500">{applicant.freelancer?.profile?.country ?? ''}</div>
													<div className="flex items-center gap-2 mt-1">
														<span className="text-xs text-gray-400">Applied {formatDate2(applicant.created_at)}</span>
														{applicant?.candidate_fit && <span className="ml-auto">{returnApplicantFitSmall(applicant.candidate_fit)}</span>}
													</div>
												</div>
											</div>
										</div>
									))
								) : (
									<AntAlert message="You have no applicants for this job yet." type="info" className="m-3 rounded" banner showIcon />
								)}
							</div>
						</Card>
					</Col>
					<Hidden mdDown>
						<Col style={{flex: 2}}>
							<Card
								className="text-left "
								style={{backgroundColor: permColors.white}}
								classNames={{body: 'p-0'}}
								title={
									<div className="flex gap-2 items-center justify-between py-2" style={{color: permColors.darkCharcoal}}>
										<div className="flex gap-2 items-center text-base">
											{selectedApplicant
												? `${selectedApplicant.freelancer?.profile?.first_name ?? 'Unknown'} ${selectedApplicant.freelancer?.profile?.last_name ?? 'Applicant'}`
												: 'Select an applicant'}
											{selectedApplicant?.candidate_fit && returnApplicantFit(selectedApplicant.candidate_fit)}
										</div>
										{selectedApplicant && returnApplicantSelectThumbs(submitCandidateFitChange, selectedApplicant, selecterLoader)}
									</div>
								}>
								{selectedApplicant ? (
									<div className="mt-3">
										<ReturnApplicantOtherButtons selectedApplicant={selectedApplicant} />
										<TabSelector value={value} handleChange={handleChange} />
										<ApplicantComponent value={value} selectedApplicant={selectedApplicant} />
									</div>
								) : (
									<AntAlert banner showIcon className="m-3 mt-3 rounded-md" type="info" message="Select an applicant to view their application details." />
								)}
							</Card>
						</Col>
					</Hidden>
				</Row>
			</Container>
			<Modal open={show} onCancel={handleClose} style={{textAlign: 'left'}} footer={false} centered>
				<div closeButton style={{color: permColors.charcoal}}>
					{selectedApplicant ? `${selectedApplicant.freelancer?.profile?.first_name ?? 'Unknown'} ${selectedApplicant.freelancer?.profile?.last_name ?? 'Applicant'}` : 'Select an applicant'}
				</div>
				{selectedApplicant ? (
					<div>
						{selectedApplicant && <div>{returnApplicantSelectThumbs(submitCandidateFitChange, selectedApplicant, selecterLoader)} </div>}
						{selectedApplicant?.candidate_fit && (
							<div className="flex py-2 items-center w-full gap-2">
								<span style={{color: permColors.charcoal}}>Candidate fit </span>
								{returnApplicantFit(selectedApplicant.candidate_fit)}
							</div>
						)}
						<ReturnApplicantOtherButtons selectedApplicant={selectedApplicant} />
						<TabSelector value={value} handleChange={handleChange} />
						<ApplicantComponent value={value} selectedApplicant={selectedApplicant} />
					</div>
				) : (
					<Card.Body>
						<Card.Text>Select an applicant to view their application details.</Card.Text>
					</Card.Body>
				)}
			</Modal>
			<Modal
				title="Close job"
				open={modalShow}
				onCancel={handleModalClose}
				footer={[
					<Button key="cancel" onClick={handleModalClose}>
						No
					</Button>,
					<Button key="submit" onClick={() => deleteJob(job.id)} danger disabled={!checked || isDeleting}>
						Yes
					</Button>,
				]}>
				<p>
					Do you wish to continue to close this job?{' '}
					<Alert className="mt-1" severity="error">
						This action cannot be undone. Once closed, the job will no longer be visible to applicants and you will not be able to re-open it.
					</Alert>
				</p>
				<Checkbox type="checkbox" className="flex items-top gap-1 mt-2" checked={checked} onChange={() => setChecked(!checked)}>
					I understand that this action cannot be undone.
				</Checkbox>
			</Modal>
			<Footer backgroundColor={permColors.lightBlueless} />
		</div>
	);
};

export default Applicants;
