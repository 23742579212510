import React, {useEffect, useRef} from 'react';
import {formatDateAndTime, formatOnlyTime} from '../../utils/DateFunctions';
import {ChatBoxProps} from '../../types/types-main';
import {AvatarWithLoading} from './Inbox';

const ChatBox: React.FC<ChatBoxProps> = ({messagesContent, currentUser}) => {
	const messageOverviewRightRef = useRef<HTMLDivElement>(null);
	const lastMessageRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (lastMessageRef.current) {
			lastMessageRef.current.scrollIntoView({behavior: 'smooth'});
		}
	}, [messagesContent]);

	const renderDateSeparator = (date: string) => (
		<div key={`separator-${date}`} className="flex items-center justify-center my-4">
			<div className="bg-gray-100 rounded-full px-4 py-1">
				<span className="text-xs text-gray-500">
					{new Date(date).toLocaleDateString('en-US', {
						year: 'numeric',
						month: 'long',
						day: 'numeric',
					})}
				</span>
			</div>
		</div>
	);

	const prepareMessagesWithSeparators = () => {
		const items = [];

		messagesContent?.forEach((text, index) => {
			// Add date separator if needed
			if (index === 0 || formatDateAndTime(text.sent_at).split(',')[0] !== formatDateAndTime(messagesContent[index - 1].sent_at).split(',')[0]) {
				items.push({
					type: 'separator',
					date: text.sent_at,
					id: `separator-${text.sent_at}`,
				});
			}

			// Add message
			items.push({
				type: 'message',
				data: text,
				id: text.id,
				isLast: index === messagesContent.length - 1,
			});
		});

		return items;
	};

	const renderMessage = (text: any, isLast: boolean) => {
		const isCurrentUser = currentUser.id === text.sender.id;

		return (
			<div key={text.id} ref={isLast ? lastMessageRef : null} className={`flex items-start gap-2 mb-4 ${isCurrentUser ? 'flex-row-reverse' : 'flex-row'}`}>
				<AvatarWithLoading
					alt={`${text.sender?.profile?.first_name ?? 'Unknown'} ${text.sender?.profile?.last_name ?? 'User'}`}
					src={text.sender?.profile?.profile_image ?? '/static/images/avatar/default.jpg'}
					className="w-8 h-8 text-sm"
				/>
				<div className={`flex flex-col max-w-[70%] ${isCurrentUser ? 'items-end' : 'items-start'}`}>
					<p className="text-xs text-grey mb-1">{text?.sender?.profile?.first_name ? text?.sender?.profile?.first_name + ' ' + text?.sender?.profile?.last_name : 'Unknown'}</p>
					<div
						className={`rounded-xl px-3 py-2 ${
							isCurrentUser ? 'bg-antBlue shadow-border-chill-background rounded-tr-none text-white' : 'bg-white shadow-border-chill-background rounded-tl-none'
						}`}>
						<p className="text-sm whitespace-pre-wrap break-words">{text.message}</p>
					</div>
					<span className="text-xs text-grey mt-1">{formatOnlyTime(text.sent_at)}</span>
				</div>
			</div>
		);
	};

	return (
		<div className="flex flex-col h-full overflow-y-auto p-4 bg-white" ref={messageOverviewRightRef}>
			{prepareMessagesWithSeparators().map((item) => {
				if (item.type === 'separator') {
					return renderDateSeparator(item.date);
				}
				return renderMessage(item.data, item.isLast);
			})}
		</div>
	);
};

export default ChatBox;
