import React, {Fragment, useEffect, useState, useContext, useRef} from 'react';
import {Link} from 'react-router-dom';
import Hamburger from 'hamburger-react';
import './Navbar2.scss';
import {useNavigate} from 'react-router-dom';
import {NotificationContext, UserContext} from '../../utils/UserContext';
import {FullLogoSmallNav, FullLogoSmallNavLight} from '../Logos/Logo';
import Loading from '../Loaders/Loading';
import {formatDate2} from '../../utils/DateFunctions';
import {markAllAsReadApi} from '../../api/notificationsApi';
import {ADMIN_ROUTES, ROUTES} from '../../utils/ROUTES';
import {useMediaQuery} from '@material-ui/core';
import {isAdmin, user_type} from '../../utils/variables/userVariables';
import {Dashboard, DashboardOutlined, LoginOutlined, LogoutOutlined, ManageSearch, PostAdd, Queue} from '@mui/icons-material';
import {permColors} from '../../utils/colors';
import {Avatar, Button, Collapse, Drawer, Dropdown, Empty, List as Listy, Spin, Tooltip} from 'antd';
import {firebaseLogout} from '../../api/authApi';
import {useAtom} from 'jotai';
import {selectedAtom, unreadNotificationsAtom} from '../../utils/atoms';
import {BellOutlined, RightOutlined, TeamOutlined, UserOutlined} from '@ant-design/icons';
import {Badge as AntBadge} from 'antd';

export default function Navbar({style, backgroundColor = '#fffffe', boxShadow = 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px', darkMode = false}) {
	const navigate = useNavigate();
	const [isOpen, setOpen] = useState(false);
	const [menu, setMenu] = useState(false);
	const [auth, setAuth] = useState(false);
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const logo = darkMode ? FullLogoSmallNavLight() : FullLogoSmallNav();
	const [loading, setLoading] = useState(false);
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [profileImage, setProfileImage] = useState('');
	const [notifications, setNotifications] = useContext(NotificationContext);
	const [unreadNotifications, setUnreadNotifications] = useAtom(unreadNotificationsAtom);
	const isMobile = useMediaQuery('(max-width: 1050px)');
	const [selected, setSelected] = useAtom(selectedAtom);
	const URL_PATHNAME = window.location.pathname;
	const ws = useRef(null);
	const [howItWorksVisible, setHowItWorksVisible] = useState(false);
	const [notificationLoading, setNotificationLoading] = useState(false);

	const links = [
		{path: ROUTES.HOME, label: 'Home'},
		{path: ROUTES.BROWSE_JOBS, label: 'Browse jobs'},
		{path: ROUTES.ABOUT_US, label: 'About us'},
		{path: ROUTES.PRICING, label: 'Pricing'},
		{
			label: 'How it works',
			isDropdown: true,
			items: [
				{
					label: 'For employers',
					path: ROUTES.HOW_IT_WORKS,
					icon: <UserOutlined />,
				},
				{
					label: 'For job seekers',
					path: ROUTES.HOW_IT_WORKS_JOB_SEEKER,
					icon: <TeamOutlined />,
				},
			],
		},
		// {path: ROUTES.HOW_IT_WORKS, label: 'How it works'},
		{path: ROUTES.CONTACT_US, label: 'Contact us'},
		...(isAdmin(currentUser) ? [{path: ADMIN_ROUTES.ADMIN, label: 'Admin'}] : []),
		{path: ROUTES.POST_JOB, label: 'Post job'},
	];

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);

	const handleShow = async () => {
		if (unreadNotifications === 0) {
			setShow(true);
			setNotificationLoading(false);
			return;
		}

		setNotificationLoading(true);
		try {
			await markAllAsReadApi();
			setUnreadNotifications(0);
		} catch (error) {
			console.error(error);
		} finally {
			setShow(true);
			setNotificationLoading(false);
		}
	};

	useEffect(() => {
		if (currentUser?.profile) {
			setAuth(true);
			if (currentUser?.profile?.profile_image) {
				const img = new Image();
				img.src = currentUser.profile.profile_image;
				img.onload = () => setProfileImage(currentUser.profile.profile_image);
			}
		}
	}, [currentUser]);

	useEffect(() => {
		if (currentUser?.profile) setAuth(true);
	}, [currentUser]);

	async function logout() {
		try {
			await new Promise((resolve) => {
				localStorage.setItem('currentUser', '');
				localStorage.setItem('accessToken', '');
				localStorage.setItem('token', '');
				setCurrentUser('');
				setNotifications([]);
				setUnreadNotifications(0);
				firebaseLogout();
				resolve();
			});
			setLoading(true);
			window.location.href = ROUTES.AUTHENTICATE;
		} catch (error) {
			console.error(error);
		}
	}

	const handleNotificationClick = (notif) => {
		switch (true) {
			case notif.message.toLowerCase().includes('new application '):
				navigate(ROUTES.APPLICANTS.replace(':jobId', notif?.job_offer?.job_id));
				break;
			case notif.message.toLowerCase().includes('new message'):
				setSelected('inbox');
				navigate(ROUTES.DASHBOARD);
				break;
			case notif.message.toLowerCase().includes('new job offer'):
				navigate(ROUTES.APPLICANTS.replace(':jobId', notif?.job_offer?.job_id));
				break;
			default:
				break;
		}
	};

	const items = [
		{
			key: 'profile',
			label: (
				<div className="flex items-center gap-2">
					<Avatar className="dummyIcon" src={profileImage} alt="Icon" icon={<UserOutlined className="text-sm" />} />
					<div>
						<p className=" max-w-[200px] truncate mb-0 text-sm" style={{color: permColors.darkCharcoal}}>
							{currentUser?.profile?.first_name ? <span className="capitalize">{`${currentUser?.profile?.first_name} ${currentUser?.profile?.last_name}`}</span> : currentUser?.email}
						</p>
						{currentUser?.profile?.user_type === user_type.JOB_SEEKER && (
							<p style={{color: permColors.charcoal}} className="mb-0 text-sm">
								{currentUser?.profile?.freelancer_profile_title}
							</p>
						)}
					</div>
				</div>
			),
			disabled: true,
			hover: false,
		},
		{
			key: 'view-profile',
			label: (
				<Button className="ButtonLinkBlue min-h-fit w-full m-0" onClick={() => navigate(ROUTES.EDIT_PROFILE)}>
					View Profile
				</Button>
			),
			disabled: true,
		},
		{
			key: 'divider-1',
			type: 'divider',
		},
		{
			key: 'dashboard',
			label: 'Dashboard',
			icon: <Dashboard style={{fontSize: '1.2em'}} />,
			onClick: () => navigate(ROUTES.DASHBOARD),
		},
		// {
		// 	key: 'eordashboard',
		// 	label: 'EOR Dashboard',
		// 	icon: <Dashboard style={{fontSize: '1.2em'}} />,
		// 	onClick: () => navigate(ROUTES.EOR_DASHBOARD),
		// },
		currentUser?.profile?.user_type === user_type.EMPLOYER && {
			key: 'post-job',
			label: 'Post Job',
			icon: <PostAdd style={{fontSize: '1.2em'}} />,
			onClick: () => navigate(ROUTES.POST_JOB),
		},
		{
			key: 'browse-jobs',
			label: 'Browse Jobs',
			icon: <ManageSearch style={{fontSize: '1.2em'}} />,
			onClick: () => navigate(ROUTES.BROWSE_JOBS),
		},
		{
			key: 'divider-2',
			type: 'divider',
		},
		{
			key: 'logout',
			label: 'Logout',
			icon: <LogoutOutlined style={{fontSize: '1.2em'}} />,
			onClick: logout,
		},
	].filter(Boolean); // Filter out any falsy values from conditional rendering

	const guestLinks = () => {
		return (
			<>
				{!isMobile && (
					<div>
						<Button
							type={'primary'}
							className={darkMode ? 'bg-backgroundGreyBlue/10 hover:bg-backgroundGreyBlue/20 border-white/10 border-1' : ''}
							icon={<LoginOutlined className="text-sm" />}
							onClick={() => navigate(ROUTES.AUTHENTICATE)}>
							Login
						</Button>
					</div>
				)}
				{isMobile && (
					<div className={'visible mt-2 flex flex-col gap-2 w-full'}>
						{links.map((link) => (
							<div key={link.path || link.label}>
								{link.isDropdown ? (
									<Collapse
										expandIcon={({isActive}) => (
											<RightOutlined
												style={{fontSize: '0.8em'}}
												className={(isActive ? 'rotate-90 transition-all duration-300 ' : ' ') + (darkMode ? 'text-white' : 'text-deepBlue')}
											/>
										)}
										ghost
										items={[
											{
												key: '1',
												label: <span className={(darkMode ? 'text-white' : 'text-deepBlue') + ' text-sm'}>{link.label}</span>,

												children: (
													<div className="flex flex-col gap-2 pl-4">
														{link.items.map((item, index) => (
															<Link
																key={index}
																to={item.path}
																className={
																	(URL_PATHNAME === item.path ? (darkMode ? 'bg-antBlue bg-opacity-20 w-fit' : 'bg-backgroundGrey w-fit') : '') +
																	' text-sm p-2  rounded-md ' +
																	(darkMode ? 'text-white' : 'text-deepBlue')
																}>
																{item.label}
															</Link>
														))}
													</div>
												),
											},
										]}
									/>
								) : (
									<Link
										className={
											(URL_PATHNAME === link.path ? (darkMode ? 'bg-antBlue bg-opacity-20 w-full' : 'bg-backgroundGrey w-full') : '') +
											' text-sm p-2  rounded-md ' +
											(darkMode ? 'text-white' : 'text-deepBlue')
										}
										to={link.path}>
										{link.label}
									</Link>
								)}
							</div>
						))}
						<Button type="primary" icon={<LoginOutlined className="text-base" />} onClick={() => navigate(ROUTES.AUTHENTICATE)}>
							Login
						</Button>
					</div>
				)}
			</>
		);
	};

	const authLinks = () => {
		const authRoutes = [
			{path: ROUTES.EDIT_PROFILE, label: `Hello ${currentUser?.profile?.first_name || ''}!`},
			{path: ROUTES.HOME, label: 'Home'},
			{path: ROUTES.DASHBOARD, label: 'Dashboard'},
			{path: ROUTES.BROWSE_JOBS, label: 'Browse jobs'},
			// {path: ROUTES.EOR_DASHBOARD, label: 'EOR Dashboard'},
			{
				label: 'How it works',
				isDropdown: true,

				items: [
					{
						label: 'For employers',
						path: ROUTES.HOW_IT_WORKS,
						icon: <UserOutlined />,
					},
					{
						label: 'For job seekers',
						path: ROUTES.HOW_IT_WORKS_JOB_SEEKER,
						icon: <TeamOutlined />,
					},
				],
			},
			{path: ROUTES.ABOUT_US, label: 'About us'},
			{path: ROUTES.PRICING, label: 'Pricing'},
			{path: ROUTES.CONTACT_US, label: 'Contact us'},
			{path: ROUTES.POST_JOB, label: 'Post Job'},
			...(isAdmin(currentUser) ? [{path: ADMIN_ROUTES.ADMIN, label: 'Admin'}] : []),
			{path: '#', label: 'Logout', onClick: logout},
		];

		return (
			<Fragment>
				{!isMobile && (
					<Dropdown
						menu={{
							items,
							className: 'rounded-md',
						}}
						placement="bottomRight">
						<Avatar
							className={darkMode ? 'dummyIcon bg-backgroundGreyBlue/10 hover:bg-backgroundGreyBlue/20' : 'dummyIcon'}
							src={profileImage}
							alt="Icon"
							icon={<UserOutlined className="text-sm" />}
							onClick={() => setDropdownVisible(!dropdownVisible)}
						/>
					</Dropdown>
				)}
				{isMobile && (
					<div className={'visible mt-2 flex flex-col gap-2 w-full'}>
						{authRoutes.map((route) => (
							<div key={route.path || route.label}>
								{route.isDropdown ? (
									<Collapse
										expandIcon={({isActive}) => (
											<RightOutlined
												style={{fontSize: '0.8em'}}
												className={(isActive ? 'rotate-90 transition-all duration-300 ' : ' ') + (darkMode ? 'text-white' : 'text-deepBlue')}
											/>
										)}
										ghost
										items={[
											{
												key: '1',
												label: <span className={(darkMode ? 'text-white' : 'text-deepBlue') + ' text-sm'}>{route.label}</span>,
												children: (
													<div className="flex flex-col gap-2 pl-4">
														{route.items.map((item, index) => (
															<Link
																key={index}
																to={item.path}
																className={
																	(URL_PATHNAME === item.path ? (darkMode ? 'bg-antBlue bg-opacity-20 w-fit' : 'bg-backgroundGrey w-fit') : '') +
																	' text-sm p-2  rounded-md ' +
																	(darkMode ? 'text-white' : 'text-deepBlue')
																}>
																{item.label}
															</Link>
														))}
													</div>
												),
											},
										]}
									/>
								) : (
									<Link
										className={
											(URL_PATHNAME === route.path ? (darkMode ? 'bg-antBlue bg-opacity-20 w-full' : 'bg-backgroundGrey w-full') : '') +
											' text-sm p-2 flex text-deepBlue items-center gap-2 rounded-md ' +
											(darkMode ? 'text-white' : 'text-deepBlue')
										}
										to={route.path}
										onClick={route.onClick}>
										{route.path === ROUTES.EDIT_PROFILE && <Avatar className="dummyIcon" src={profileImage} alt="Icon" icon={<UserOutlined />} />}
										{route.label}
									</Link>
								)}
							</div>
						))}
					</div>
				)}
			</Fragment>
		);
	};

	// Apply dark mode styles if darkMode is true
	const navStyles = darkMode
		? {
				backgroundColor: 'transparent',
				boxShadow: 'none',
				...style,
		  }
		: {
				backgroundColor,
				boxShadow,
				...style,
		  };

	// Text color based on dark mode
	const textColor = darkMode ? 'text-white' : 'text-deepBlue';
	const fontStyle = 'font-normal';

	return (
		<nav className={`nav2 ${darkMode ? 'bg-transparent shadow-none' : 'bg-white'}`} style={navStyles}>
			<div>
				<div>
					<ul className="bigNav">
						<div className="inline2">
							<li>{logo}</li>
							<div className={`flex items-end gap-4 2xl:gap-6 ml-7 2xl:ml-12 text-sm rounded-lg p-2 pl-3  ${darkMode ? '' : ''}`}>
								{links.map((link) => (
									<li key={link.path || link.label}>
										{link.isDropdown ? (
											<Dropdown
												onVisibleChange={() => setHowItWorksVisible(!howItWorksVisible)}
												menu={{
													className: 'rounded-md',
													items: link.items.map((item, index) => ({
														key: index,
														label: (
															<Link to={item.path} className={(URL_PATHNAME === item.path ? 'font-semibold' : '') + ' font-normal text-sm text-deepBlue rounded-md'}>
																{item.label}
															</Link>
														),
														icon: item.icon,
													})),
												}}>
												<span
													className={`cursor-pointer font-normal ${darkMode ? 'text-white' : 'text-deepBlue'} flex items-center gap-2 ${
														URL_PATHNAME === link.path ? 'font-bold ' : ''
													}`}>
													<RightOutlined style={{fontSize: '0.8em'}} className={' ' + (howItWorksVisible ? 'rotate-90 transition-all duration-300' : '')} />
													{link.label}
												</span>
											</Dropdown>
										) : link.path !== ROUTES.POST_JOB ? (
											<Link
												className={`${URL_PATHNAME === link.path ? 'font-semibold underline underline-offset-8' : 'font-normal'} ${darkMode ? 'text-white' : 'text-deepBlue'}`}
												to={link.path}>
												{link.label}
											</Link>
										) : (
											<Button
												icon={<Queue className="text-sm" />}
												type="primary"
												href={ROUTES.POST_JOB}
												className={'text-white ' + (darkMode ? 'bg-backgroundGreyBlue/10 hover:bg-backgroundGreyBlue/20 border-white/10 border-1 ' : '')}>
												Post job
											</Button>
										)}
									</li>
								))}
							</div>
						</div>
						<div className="text-sm flex items-center gap-3">
							{auth && (
								<Button
									type={darkMode ? 'primary' : 'default'}
									className={darkMode ? 'bg-backgroundGreyBlue/10 hover:bg-backgroundGreyBlue/20 border-white/10 border-1' : ''}
									icon={<DashboardOutlined className="text-sm" />}
									onClick={() => navigate(ROUTES.DASHBOARD)}>
									Dashboard
								</Button>
							)}
							{auth && (
								<AntBadge count={unreadNotifications}>
									<Tooltip title="Notifications" placement="bottomRight">
										{notificationLoading ? (
											<Spin />
										) : (
											<Button
												type={darkMode ? 'primary' : 'default'}
												icon={<BellOutlined className="text-base" />}
												className={darkMode ? 'bg-backgroundGreyBlue/10 hover:bg-backgroundGreyBlue/20' : ''}
												onClick={handleShow}
											/>
										)}
									</Tooltip>
								</AntBadge>
							)}

							<div className="right-links">{auth ? authLinks() : guestLinks()}</div>
						</div>
					</ul>
					<div className="smallNav">
						<span onClick={() => (menu ? setMenu(false) : setMenu(true))} className="flex items-center justify-between gap-2">
							<Hamburger size={20} color={darkMode ? '#ffffff' : permColors.darkBlue} toggled={isOpen} toggle={setOpen} className="hamburger2 w-[15px]" />
							<div>{logo}</div>
						</span>

						{auth && (
							<div className="rightSide">
								<AntBadge count={unreadNotifications}>
									{notificationLoading ? (
										<Spin />
									) : (
										<Button
											type={darkMode ? 'primary' : 'default'}
											icon={<BellOutlined className="text-base" />}
											onClick={handleShow}
											className={darkMode ? 'bg-backgroundGreyBlue/10 hover:bg-backgroundGreyBlue/20 border-white/10 border-1' : ''}
										/>
									)}
								</AntBadge>
							</div>
						)}
					</div>
					{isMobile && (
						<div
							className={`text-base  text-left transition-all duration-300 overflow-hidden ${
								menu ? 'max-h-[1000px] rounded-md shadow-border-chill-background  backdrop-blur-md  p-2 mt-1 opacity-100' : 'max-h-0 opacity-0'
							} ${darkMode ? 'bg-antBlue/10 ' : ''}`}>
							<ul className="p-1">{auth ? authLinks() : guestLinks()}</ul>
						</div>
					)}
				</div>
			</div>
			{loading && <Loading />}
			<Drawer
				title="Notifications"
				placement="right"
				onClose={handleClose}
				visible={show}
				width={isMobile ? 320 : 400}
				bodyStyle={{padding: 0}}
				// styles={{header: {backgroundColor: permColors.white}}}
				className="bg-backgroundGreyBlue max-w-full"
				styles={{
					header: {backgroundColor: permColors.white, borderRadius: '6px 6px 0 0'},
					wrapper: {marginRight: '12px', marginTop: '12px', marginBottom: '12px'},
					content: {borderRadius: '6px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'},
				}}>
				{notifications?.length > 0 ? (
					<Listy
						itemLayout="horizontal"
						dataSource={notifications}
						className="p-3 bg-backgroundGreyBlue"
						renderItem={(notif) => (
							<Listy.Item
								className="cursor-pointer p-3 duration-300 hover:bg-backgroundGrey bg-white shadow-border-chill-background rounded-md mb-3"
								onClick={() => handleNotificationClick(notif)}>
								<Listy.Item.Meta
									avatar={<BellOutlined className="text-antBlue" />}
									title={notif.message}
									description={<span className="text-xs text-grey">{formatDate2(notif.created_at)}</span>}
								/>
							</Listy.Item>
						)}
					/>
				) : (
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No notifications yet. Check back later." />
				)}
			</Drawer>
		</nav>
	);
}
