import React, {useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {UserContext} from '../UserContext';
import {ROUTES} from '../ROUTES';

const AuthenticatedCheck = ({children}) => {
	const [currentUser] = useContext(UserContext);

	return currentUser?.profile ? children : <Navigate to={ROUTES.AUTHENTICATE} />;
};

export default AuthenticatedCheck;
