import React, {useState} from 'react';
import {Card, Form, Button, Upload, message, Spin, Radio, Tooltip} from 'antd';
import {QuestionCircleOutlined, RobotOutlined} from '@ant-design/icons';
import api from '../../api/interceptor';
import {mainUrl} from '../../api/Urls';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import {AutoAwesome} from '@mui/icons-material';
import Ribbon from 'antd/es/badge/Ribbon';

const {Dragger} = Upload;

export default function CvParser({refreshProfile}: {refreshProfile: () => Promise<void>}) {
	const [file, setFile] = useState<File | null>(null);
	const [loading, setLoading] = useState(false);
	const [parsedData, setParsedData] = useState(null);
	const [deleteAllData, setDeleteAllData] = useState('');
	const [fileList, setFileList] = useState<any[]>([]);

	const [form] = Form.useForm();

	const handleSubmit = async (values: any) => {
		if (!file) {
			message.error('Please upload your CV first');
			return;
		}

		setLoading(true);
		const formData = new FormData();
		formData.append('cv', file);
		formData.append('delete_existing_data', deleteAllData);

		try {
			const response = await api.post(`${mainUrl}/auth/cv-parser/`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			});

			setParsedData(response.data.data);
			await refreshProfile();
			message.success('CV parsed successfully!');
			handleReset();
		} catch (error) {
			console.error('Error parsing CV:', error);
			message.error('Failed to parse CV. Please try again.');
		} finally {
			setLoading(false);
		}
	};

	const handleReset = () => {
		setFile(null);
		setDeleteAllData('');
		setFileList([]);
		form.resetFields();
	};

	return (
		<Spin
			spinning={loading}
			tip={<div className="mt-3 text-antBlue bg-white font-semibold px-10">Our AI is carefully analyzing your CV to extract all the important details...</div>}
			style={{backgroundColor: 'rgba(255, 255, 255, 0.8)'}}>
			<Ribbon
				className={loading ? 'hidden' : ''}
				text={
					<span className="flex items-center gap-2">
						<AutoAwesome className="text-white text-sm" />
						<span>New</span>
					</span>
				}
				color="blue"
				placement="end">
				<Card
					title={
						<div className="flex items-center gap-2">
							<SmartToyIcon className="text-antBlue" />
							<span>AI CV Parser</span>
						</div>
					}
					bordered={false}
					className="mb-2 rounded-md">
					<div className="mb-4 text-sm text-gray-600">
						Our AI will analyze your CV to extract your personal details, work history, education, and skills and update your profile accordingly.
					</div>

					<Form onFinish={handleSubmit} layout="vertical" id="parseForm">
						<Form.Item label={<span className="font-semibold">Upload your CV</span>} name="cv" rules={[{required: true, message: 'Please upload your CV'}]}>
							<Dragger
								beforeUpload={(file) => {
									if (file.type !== 'application/pdf') {
										message.error('You can only upload PDF files!');
										return false;
									}
									setFile(file);
									setFileList([file]);
									return false;
								}}
								fileList={fileList}
								accept=".pdf"
								maxCount={1}
								onRemove={() => {
									setFile(null);
									setFileList([]);
								}}
								style={{border: '1px dashed #1677ff', padding: '0'}}>
								<div className="p-6">
									<RobotOutlined className="text-4xl text-antBlue mb-3" />
									<p className="ant-upload-text font-medium">Click or drag file to this area to upload</p>
									<p className="ant-upload-hint mb-2 text-charcoal opacity-80">
										Support for a single PDF upload. Strictly prohibit from uploading company data or other sensitive files.
									</p>
								</div>
							</Dragger>
						</Form.Item>

						<Form.Item
							label={
								<span className="font-semibold flex items-center gap-2">
									Delete all existing data before parsing?
									<Tooltip title="Selecting 'Yes' will remove all your existing work experience, education history and skills before parsing the new CV. Select 'No' to keep your existing data and append the new information from your CV.">
										<QuestionCircleOutlined className="text-gray-400 cursor-help" />
									</Tooltip>
								</span>
							}
							name="delete_existing_data"
							rules={[{required: true, message: 'Please select an option'}]}>
							<div>
								<Radio.Group value={deleteAllData} onChange={(e) => setDeleteAllData(e.target.value)}>
									<Radio value="true">Yes</Radio>
									<Radio value="false">No</Radio>
								</Radio.Group>
							</div>
						</Form.Item>

						<Form.Item>
							<Button type="primary" htmlType="submit" disabled={!file || !deleteAllData || loading} loading={loading} className="w-full">
								{loading ? 'Parsing...' : 'Parse CV with AI'}
							</Button>
						</Form.Item>
					</Form>
				</Card>
			</Ribbon>
		</Spin>
	);
}
