export function formatCurrency(amountFrom, amountTo, rateType, currency) {
	if (!amountFrom || !amountTo || !rateType || !currency) {
		return '';
	}

	const formattedRateType = rateType ? `/${rateType}` : '';

	switch (currency) {
		case 'USD':
			return (
				<>
					<span className="opacity-70">$</span>
					{amountFrom} - <span className="opacity-70">$</span>
					{amountTo}
					{formattedRateType} <span className="text-xs opacity-70">(USD)</span>
				</>
			);
		case 'PHP':
			return (
				<>
					<span className="opacity-70">₱</span>
					{amountFrom} - <span className="opacity-70">₱</span>
					{amountTo}
					{formattedRateType} <span className="text-xs opacity-70">(PHP)</span>
				</>
			);
		case 'CAD':
			return (
				<>
					<span className="opacity-70">CA$</span>
					{amountFrom} - <span className="opacity-70">CA$</span>
					{amountTo}
					{formattedRateType} <span className="text-xs opacity-70">(CAD)</span>
				</>
			);
		case 'AUD':
			return (
				<>
					<span className="opacity-70">A$</span>
					{amountFrom} - <span className="opacity-70">A$</span>
					{amountTo}
					{formattedRateType} <span className="text-xs opacity-70">(AUD)</span>
				</>
			);
		case 'NZD':
			return (
				<>
					<span className="opacity-70">NZ$</span>
					{amountFrom} - <span className="opacity-70">NZ$</span>
					{amountTo}
					{formattedRateType} <span className="text-xs opacity-70">(NZD)</span>
				</>
			);
		default:
			return (
				<>
					{amountFrom} - {amountTo}
					{formattedRateType} <span className="text-xs opacity-70">({currency})</span>
				</>
			);
	}
}
