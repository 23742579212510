import React, {useEffect, useState} from 'react';
import {getJobInsightsApi} from '../../api/companyInsightsApi';
import {Card, Typography, Space, Timeline} from 'antd';
import {UserOutlined, ClockCircleOutlined, EyeOutlined} from '@ant-design/icons';
import {permColors} from '../../utils/colors';
// Recharts components
import {ResponsiveContainer, LineChart as RechartsLineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip as RechartsTooltip, Legend} from 'recharts';

export default function SingleJobStats({jobId}: {jobId: string}) {
	const [jobInsights, setJobInsights] = useState<any>(null);

	useEffect(() => {
		const fetchJobInsights = async () => {
			const insights = await getJobInsightsApi({jobId});
			setJobInsights(insights);
		};
		fetchJobInsights();
	}, [jobId]);

	if (!jobInsights) return null;

	const StatCard = ({icon: Icon, title, value}: {icon: any; title: string; value: number}) => (
		<Card>
			<Space direction="vertical" size="small">
				<Space>
					<Icon style={{color: permColors.primary, fontSize: '20px'}} />
					<Typography.Text type="secondary">{title}</Typography.Text>
				</Space>
				<Typography.Title level={3} style={{margin: 0}}>
					{value}
				</Typography.Title>
			</Space>
		</Card>
	);

	// Recharts component for "Application Trend (Last 30 Days)"
	function RechartsApplicationTrend() {
		// Sort the data chronologically to ensure the line graph goes from left to right
		const chartData = [...jobInsights.daily_application_trend]
			.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
			.map((item: any) => ({
				date: new Date(item.date).toLocaleDateString('en-US'),
				applications: item.applications,
			}));

		return (
			<ResponsiveContainer width="100%" height={300}>
				<RechartsLineChart data={chartData} margin={{top: 10, right: 20, bottom: 10, left: 0}}>
					<CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
					<XAxis dataKey="date" />
					<YAxis />
					<RechartsTooltip />
					<Legend />
					<Line type="monotone" dataKey="applications" stroke={permColors.primary} />
				</RechartsLineChart>
			</ResponsiveContainer>
		);
	}

	function RechartsImpressionsTrend() {
		const chartData = [...jobInsights.daily_impressions]
			.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
			.map((item: any) => ({
				date: new Date(item.date).toLocaleDateString('en-US'),
				impressions: item.impressions,
			}));

		return (
			<ResponsiveContainer width="100%" height={300}>
				<RechartsLineChart data={chartData} margin={{top: 10, right: 20, bottom: 10, left: 0}}>
					<CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
					<XAxis dataKey="date" />
					<YAxis />
					<RechartsTooltip />
					<Legend />
					<Line type="monotone" dataKey="impressions" stroke={permColors.primary} />
				</RechartsLineChart>
			</ResponsiveContainer>
		);
	}

	return (
		<Space direction="vertical" size="large" style={{width: '100%'}}>
			<Typography.Title level={5}>Job statistics</Typography.Title>
			<div className="flex flex-wrap gap-4">
				<div className="grow  min-w-[250px]">
					<StatCard icon={UserOutlined} title="Total Applications" value={jobInsights.application_metrics.total_applications} />
				</div>
				<div className="grow  min-w-[250px]">
					<StatCard icon={ClockCircleOutlined} title="Days Active" value={jobInsights.job_details.days_active} />
				</div>
				<div className="grow  min-w-[250px]">
					<StatCard icon={EyeOutlined} title="Viewed Applications" value={jobInsights.application_metrics.viewed_applications} />
				</div>
			</div>

			<Card>
				<Typography.Title level={5}>Applications trend (Last 30 Days)</Typography.Title>
				<RechartsApplicationTrend />
			</Card>

			<Card>
				<Typography.Title level={5}>Impressions trend (Last 30 Days)</Typography.Title>
				<RechartsImpressionsTrend />
			</Card>

			{/* <Card>
				<Typography.Title level={5}>Recent Activity</Typography.Title>
				<Timeline>
					{jobInsights.activity_log.map((activity: any, index: number) => (
						<Timeline.Item key={index}>
							<Typography.Text strong>{activity.title}</Typography.Text>
							<br />
							<Typography.Text type="secondary">{new Date(activity.created_at).toLocaleDateString()}</Typography.Text>
						</Timeline.Item>
					))}
				</Timeline>
			</Card> */}
		</Space>
	);
}
