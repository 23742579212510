import React, {useEffect, useContext} from 'react';
import {Alert, Divider, List, ListItem, ListItemText} from '@mui/material';
import {NotificationContext} from '../../../utils/UserContext';
import {formatDate2} from '../../../utils/DateFunctions';
import './Notifications.scss';
import QueueAnim from 'rc-queue-anim';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../../../utils/ROUTES';
import {useAtom} from 'jotai';
import {selectedAtom, unreadNotificationsAtom} from '../../../utils/atoms';
import {permColors} from '../../../utils/colors';
import {markAllAsReadApi} from '../../../api/notificationsApi';
import {BellOutlined} from '@ant-design/icons';
import {Card} from 'antd';
export default function Notifications() {
	const [notifications, setNotifications] = useContext(NotificationContext);

	const navigate = useNavigate();
	const [, setSelected] = useAtom(selectedAtom);

	const textStyles = {
		read: {},
		notRead: {
			backgroundColor: '#d8eefe',
		},
	};

	useEffect(() => {
		document.title = 'Notifications | JobFinder';
	}, []);

	const handleNotificationClick = (notif) => {
		switch (true) {
			case notif.message.toLowerCase().includes('new application '):
				navigate(ROUTES.APPLICANTS.replace(':jobId', notif?.job_offer?.job_id));
				break;
			case notif.message.toLowerCase().includes('new message'):
				setSelected('inbox');
				navigate(ROUTES.DASHBOARD);
				break;
			case notif.message.includes('new job offer'):
				navigate(ROUTES.APPLICANTS.replace(':jobId', notif?.job_offer?.job_id));
				break;
			default:
				break;
		}
	};

	return (
		<div className="dashboardComponent">
			<Card classNames={{body: 'p-2'}} className="mb-2 w-fit">
				<div className="flex justify-between items-center gap-2">
					<h2 className="flex items-center gap-2 px-2 text-lg font-semibold text-darkCharcoal">
						<BellOutlined className="text-base" /> Notifications
					</h2>
				</div>
			</Card>
			<Card classNames={{body: 'p-2'}} className="bg-backgroundGreyLight">
				<List className="flex flex-col gap-2">
					{notifications.length > 0 ? (
						notifications?.map((notif) => (
							<div key={notif?.id}>
								<ListItem
									sx={notif?.read ? textStyles.read : textStyles.notRead}
									className={
										'listItem bg-white flex items-start gap-3 cursor-pointer rounded-md hover:bg-backgroundGreyBlue transition-all duration-100 shadow-border-chill-background p-2'
									}
									onClick={() => handleNotificationClick(notif)}>
									<BellOutlined className="mt-[10px]" style={{color: permColors.linkBlue}} />
									<ListItemText primary={notif?.message} primaryTypographyProps={{color: permColors.darkCharcoal}} secondary={formatDate2(notif?.created_at)} />
								</ListItem>
							</div>
						))
					) : (
						<Alert severity="info" className="rounded-md">
							You have no notifications yet. Check back later.
						</Alert>
					)}
				</List>
			</Card>
		</div>
	);
}
