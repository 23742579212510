import {CheckCircleOutlined, CheckOutlined} from '@ant-design/icons';
import {Badge, Button, Card, Tag} from 'antd';
import {AiOutlineArrowRight} from 'react-icons/ai';
import {EXTERNAL_ROUTES, ROUTES} from '../../utils/ROUTES';
import {permColors} from '../../utils/colors';
import {AutoAwesome} from '@mui/icons-material';

export function pricingCard(pricingData: any) {
	return (
		<div className="flex flex-col md:flex-row gap-4 justify-center">
			{pricingData.map((pricing: any, index: number) => {
				const cardContent = (
					<div className="h-full flex flex-col justify-between">
						<div className="mb-4">
							<h2 className="text-xl font-semibold text-gray-800 mb-2">{pricing.title}</h2>
							<h3 className="text-2xl font-bold text-gray-900 mb-1">{pricing.price}</h3>
							<p className="text-sm text-gray-500">{pricing.priceDescription}</p>
						</div>
						<hr className="border-gray-200 my-4" />
						<div className="flex-grow">
							<ul className="space-y-3 mb-6 text-left">
								{pricing.descriptions.map((description: string, descIndex: number) => {
									const isAIFeature = description.toLowerCase().includes('cv');
									return (
										<li key={descIndex} className="flex items-start">
											{isAIFeature ? (
												<AutoAwesome className={`${isAIFeature ? 'text-antBlue' : 'text-green-500'} mt-1 mr-2 text-base flex-shrink-0`} />
											) : (
												<CheckOutlined className={`mt-1 mr-2 flex-shrink-0`} />
											)}
											<span className={`text-sm ${isAIFeature ? 'text-antBlue font-medium' : 'text-gray-600'}`}>
												{isAIFeature ? (
													<>
														<span className="font-semibold">AI-powered:</span> {description.replace(/AI-powered|Enhanced AI-powered/g, '')}
													</>
												) : description.toLowerCase().includes('higher priority') ? (
													<span className="font-bold">{description}</span>
												) : (
													description
												)}
											</span>
										</li>
									);
								})}
							</ul>
						</div>
						<Button
							icon={<AiOutlineArrowRight />}
							type="primary"
							href={pricing.buttonLink || ROUTES.POST_JOB}
							target="_blank"
							rel="noreferrer"
							className="w-full mt-auto hover:scale-105 transition-transform">
							{pricing.buttonText || 'Post a job'}
						</Button>
					</div>
				);

				return (
					<div key={index}>
						{pricing?.recommended ? (
							<Badge.Ribbon text="Recommended" color="blue">
								<Card className="h-full md:max-w-[400px] w-full " classNames={{body: 'h-full'}}>
									{cardContent}
								</Card>
							</Badge.Ribbon>
						) : (
							<Card className="h-full md:max-w-[400px] w-full" classNames={{body: 'h-full'}}>
								{cardContent}
							</Card>
						)}
					</div>
				);
			})}
		</div>
	);
}

export function eorCard() {
	return (
		<div>
			<h3 style={{color: permColors.charcoal}} className="font-semibold mb-2 mt-32">
				Employer of Record
			</h3>
			<p className="mb-8">For large scale recruitment and compliance work, the Enterprise plan helps you scale your business quickly with expert tailored services.</p>
			<div className="pricing-cards ">
				<div className="pricing-card max-w-[400px] ">
					<div className="pricing-header">
						<h2 className="flex gap-2">Employer of Record</h2>
						<h3 style={{fontSize: '1.1em'}}>$40-$300 USD per month</h3>
						<p>Price varies by country</p>
					</div>
					<hr className="my-3" />
					<div className="pricing-card-bottom">
						<div>
							<div className="individual-description">
								<CheckOutlined />
								<p className="text-sm sm:text-base mb-1" style={{color: permColors.charcoal}}>
									Full employment compliance
								</p>
							</div>
							<div className="individual-description">
								<CheckOutlined />
								<p className="text-sm sm:text-base mb-1" style={{color: permColors.charcoal}}>
									Local payroll management
								</p>
							</div>
							<div className="individual-description">
								<CheckOutlined />
								<p className="text-sm sm:text-base mb-1" style={{color: permColors.charcoal}}>
									Benefits administration
								</p>
							</div>
						</div>
						<a className="linkButtonDarkBlue text-sm" href={EXTERNAL_ROUTES.CALENDLY_PERMHUNT} rel="noreferrer" target="_blank">
							Contact sales
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export function otherSpecializationCards(pricingData: any) {
	return (
		<div className="flex flex-col md:flex-row gap-4 justify-center">
			{pricingData.map((pricing: any, index: number) => {
				const cardContent = (
					<div className="h-full flex flex-col justify-between">
						<div className="mb-4">
							<h2 className="text-xl font-semibold text-gray-800 mb-2">{pricing.title}</h2>
							<h3 className="text-2xl font-bold text-gray-900 mb-1">{pricing.price}</h3>
							<p className="text-sm text-gray-500">{pricing.priceDescription}</p>
						</div>
						<hr className="border-gray-200 my-4" />
						<div className="flex-grow">
							<ul className="space-y-3 mb-6 text-left">
								{pricing.descriptions.map((description: string, descIndex: number) => {
									const isAIFeature = description.toLowerCase().includes('cv');
									return (
										<li key={descIndex} className="flex items-start">
											{isAIFeature ? (
												<CheckOutlined className={`${isAIFeature ? 'text-antBlue' : 'text-green-500'} mt-1 mr-2 flex-shrink-0`} />
											) : (
												<CheckOutlined className={`mt-1 mr-2 flex-shrink-0`} />
											)}
											<span className={`text-sm ${isAIFeature ? 'text-antBlue font-medium' : 'text-gray-600'}`}>
												{isAIFeature ? (
													<>
														<span className="font-semibold">AI-powered:</span> {description.replace(/AI-powered|Enhanced AI-powered/g, '')}
													</>
												) : (
													description
												)}
											</span>
										</li>
									);
								})}
							</ul>
						</div>
						<Button
							icon={<AiOutlineArrowRight />}
							type="default"
							href={EXTERNAL_ROUTES.CALENDLY_PERMHUNT}
							target="_blank"
							rel="noreferrer"
							className="w-full mt-auto hover:scale-105 transition-transform">
							Contact sales
						</Button>
					</div>
				);

				return (
					<div key={index}>
						{pricing?.recommended ? (
							<Badge.Ribbon text="Recommended" color="blue">
								<Card className="h-full md:max-w-[400px] w-full " classNames={{body: 'h-full'}}>
									{cardContent}
								</Card>
							</Badge.Ribbon>
						) : (
							<Card className="h-full md:max-w-[400px] w-full" classNames={{body: 'h-full'}}>
								{cardContent}
							</Card>
						)}
					</div>
				);
			})}
		</div>
	);
}
