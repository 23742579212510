import './LandingPage.scss';
import {capitalizeFirstLetter, textFormatter} from '../../utils/TextAlterations';
import {daysAgo} from '../../utils/DateFunctions';
import Card from 'react-bootstrap/Card';
import {Button, message, Modal, notification, Tag} from 'antd';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import ApplyJobButton from '../../components/ApplyJob/ApplyJobButton';
import {permColors, tailwindPermColors} from '../../utils/colors';
import {formatCurrency} from '../../utils/currency';
import {ROUTES} from '../../utils/ROUTES';
import {CopyOutlined, LinkOutlined} from '@ant-design/icons';
import {AccessTime, AttachMoney, CalendarToday, Computer, HealthAndSafety, LocationOn, Public, Schedule, TimeToLeave, Work} from '@mui/icons-material';
import {capitalize} from '@mui/material';
import {Link} from 'react-router-dom';

interface JobContentProps {
	job: any;
	onNavigate?: (path: string) => void;
	authenticatedButtons: any;
	isModal?: boolean;
}

function JobContent({job, onNavigate, authenticatedButtons, isModal = false}: JobContentProps) {
	return (
		<>
			<div className="flex items-top justify-between gap-2 mb-4">
				<div className={`rounded-full border border-gray-200 p-2 w-fit ${!isModal && 'h-fit'}`}>
					{!job?.created_by_image ? <WorkOutlineOutlinedIcon className="working-icon" /> : <img src={job?.created_by_image} alt="profile" className="working-icon p-0" />}
				</div>
				<div className="flex flex-row items-end gap-2">
					{!isModal && (
						<Button
							className="rounded-md min-h-fit"
							onClick={() => {
								navigator.clipboard.writeText(window.location.origin + ROUTES.JOB_PAGE.replace(':jobId', job.id));
								message.success('Job URL copied to clipboard', 2);
							}}
							icon={<CopyOutlined />}>
							Copy URL
						</Button>
					)}
					{authenticatedButtons(job)}
					<ApplyJobButton job={job} />
				</div>
			</div>

			<p className="text-base font-semibold flex items-center gap-3" style={{color: permColors.charcoal}}>
				{job?.company_name && capitalizeFirstLetter(job.company_name)}
				<span className="text-base font-light opacity-80" style={{color: permColors.grey}}>
					{job.created_at !== null ? `Posted ${daysAgo(job.created_at)} day${daysAgo(job.created_at) === 1 ? '' : 's'} ago` : ''}
				</span>
			</p>

		
				<Link
					className="text-2xl font-medium mb-2 flex items-center gap-2 hover:underline cursor-pointer w-fit"
					style={{color: permColors.darkCharcoal}}
					to={`${ROUTES.JOB_PAGE.replace(':jobId', job.id)}`}>
					{capitalizeFirstLetter(job.title)}
					<LinkOutlined className="text-lg" />
				</Link>

			<div className="flex items-center flex-wrap gap-2">
				<Tag className="text-sm m-0">{job.engagement_type}</Tag>
				<Tag className="text-sm m-0">{job.category}</Tag>
				<Tag className="text-sm m-0">{job.job_type}</Tag>
			</div>

			<hr className="mt-4 mb-2" />

			<div className="flex items-center justify-between">
				<div>
					{job?.payment_rate_from && job?.show_salary_on_job && (
						<div className="flex items-center gap-2">
							<AttachMoney style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
							<span className="text-sm" style={{color: permColors.charcoal}}>
								<span className="font-semibold">Salary:</span> {formatCurrency(job?.payment_rate_from, job?.payment_rate_to, job?.payment_rate_type, job?.payment_rate_currency)}
							</span>
						</div>
					)}

					<div className="flex flex-col gap-2 mt-2">
						<div className="flex items-center gap-2">
							<LocationOn style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
							<span className="text-sm" style={{color: permColors.charcoal}}>
								<span className="font-semibold">Company location:</span> {job.country}
							</span>
						</div>
						{job?.role_location_type && (
							<div className="flex items-center gap-2">
								<Public style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
								<span className="text-sm" style={{color: permColors.charcoal}}>
									<span className="font-semibold">Role location type:</span> <span className="capitalize">{job.role_location_type}</span>
								</span>
							</div>
						)}
						{job?.role_location_type !== 'remote' && (
							<div className="flex items-center gap-2">
								<LocationOn style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
								<span className="text-sm" style={{color: permColors.charcoal}}>
									<span className="font-semibold">Role location:</span> <span className="capitalize">{job.role_location}</span>
								</span>
							</div>
						)}
						{job?.work_schedule_timezone && (job?.work_schedule_type === 'fixed' || job?.work_schedule_type === 'shift-based') && (
							<div className="flex items-center gap-2">
								<AccessTime style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
								<span className="text-sm" style={{color: permColors.charcoal}}>
									<span className="font-semibold">Timezone:</span> {job.work_schedule_timezone}
								</span>
							</div>
						)}
						{job?.work_schedule_type && (
							<div className="flex items-center gap-2">
								<Work style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
								<span className="text-sm" style={{color: permColors.charcoal}}>
									<span className="font-semibold">Working schedule type:</span> <span className="capitalize">{job.work_schedule_type}</span>
								</span>
							</div>
						)}

						{job?.work_schedule_type === 'fixed' && job?.work_schedule_hours_start && (
							<div className="flex items-center gap-2">
								<AccessTime style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
								<span className="text-sm" style={{color: permColors.charcoal}}>
									<span className="font-semibold">Working hours:</span> {job.work_schedule_hours_start} - {job.work_schedule_hours_end}
								</span>
							</div>
						)}

						{job?.work_schedule_days && job?.work_schedule_type === 'fixed' && (
							<div className="flex items-center gap-2">
								<CalendarToday style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
								<p className="text-sm" style={{color: permColors.charcoal}}>
									<span className="font-semibold">Working days:</span>{' '}
									{job.work_schedule_days
										.split(',')
										.map((day: any) => capitalize(day))
										.join(', ')}
								</p>
							</div>
						)}

						{job?.shifts?.length > 0 && (
							<div className="flex flex-col gap-2">
								<div className="flex items-center gap-2">
									<AccessTime style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
									<span className="text-sm font-semibold" style={{color: permColors.charcoal}}>
										Shifts:
									</span>
								</div>
								<div className="flex flex-col gap-1 ml-6">
									{job.shifts.map((shift: any, index: any) => (
										<p key={index} className="text-sm" style={{color: permColors.charcoal}}>
											{shift.day_of_week}: {shift.start_time} - {shift.end_time}
										</p>
									))}
								</div>
							</div>
						)}

						{job?.equipment_allocation && (
							<p className="text-sm" style={{color: permColors.charcoal}}>
								<div className="flex items-center gap-2">
									<Computer style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
									<span className="font-semibold">Equipment:</span> <span className="capitalize">{job.equipment_allocation}</span>
								</div>
							</p>
						)}
						{job.provide_HMO && (
							<p className="text-sm" style={{color: permColors.charcoal}}>
								<div className="flex items-center gap-2">
									<HealthAndSafety style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
									<span className="font-semibold">Benefits:</span> HMO Provided
								</div>
							</p>
						)}
					</div>
				</div>
			</div>

			<div className="pb-2">
				<p className="text-base font-semibold mt-4 mb-2" style={{color: permColors.darkCharcoal}}>
					Description
				</p>
				<div
					className={`grow text-charcoal text-sm [&>p]:text-sm ${isModal ? 'p-3' : '  min-h-[250px] '}`}
					dangerouslySetInnerHTML={{
						__html: job?.description,
					}}
				/>
			</div>
		</>
	);
}

interface SelectedJobCardProps {
	job: any;
	onNavigate: (path: string) => void;
	authenticatedButtons: any;
}

export function SelectedJobCard({job, onNavigate, authenticatedButtons}: SelectedJobCardProps) {
	return (
		<div className="selectedFeed ">
			<Card className=" rounded-lg p-6 flex flex-col  gap-1" style={{height: '95vh', overflowY: 'auto'}}>
				<JobContent job={job} onNavigate={onNavigate} authenticatedButtons={authenticatedButtons} />
			</Card>
		</div>
	);
}

interface JobModalProps {
	job: any;
	show: boolean;
	onHide: any;
	onNavigate: (path: string) => void;
	authenticatedButtons: any;
}

export function JobModal({job, show, onHide, onNavigate, authenticatedButtons}: JobModalProps) {
	return (
		<Modal open={show} onCancel={onHide} footer={null} width={700} centered>
			<div className="p-0 pt-4 flex flex-col justify-between gap-1">
				<JobContent job={job} onNavigate={onNavigate} authenticatedButtons={authenticatedButtons} isModal={true} />
			</div>
		</Modal>
	);
}
