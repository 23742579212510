import React, {useState} from 'react';
import {Button, Checkbox, Modal, Space, Tooltip, message} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';

interface UserData {
	id: string;
	email: string;
	username: string;
	last_login: string;
	created_at: string;
	job_applications_count: number;
	profile: {
		first_name: string;
		last_name: string;
		updated_at: string;
		user_type: string;
		company_name: string | null;
		profile_image: string;
	};
}

interface ExportUsersCsvProps {
	users: UserData[];
}

const ExportUsersCsv: React.FC<ExportUsersCsvProps> = ({users}) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const [selectedFields, setSelectedFields] = useState<string[]>([
		'id',
		'email',
		'username',
		'last_login',
		'created_at',
		'job_applications_count',
		'first_name',
		'last_name',
		'user_type',
		'company_name',
		'cv_text',
		'cv_url',
	]);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleSelectAllUsers = (e: CheckboxChangeEvent) => {
		if (e.target.checked) {
			setSelectedUsers(users.map((user) => user.id));
		} else {
			setSelectedUsers([]);
		}
	};

	const handleUserCheckboxChange = (userId: string) => {
		setSelectedUsers((prev) => {
			if (prev.includes(userId)) {
				return prev.filter((id) => id !== userId);
			} else {
				return [...prev, userId];
			}
		});
	};

	const handleFieldCheckboxChange = (field: string) => {
		setSelectedFields((prev) => {
			if (prev.includes(field)) {
				return prev.filter((f) => f !== field);
			} else {
				return [...prev, field];
			}
		});
	};

	const exportToCsv = () => {
		if (selectedUsers.length === 0) {
			message.error('Please select at least one user to export');
			return;
		}

		if (selectedFields.length === 0) {
			message.error('Please select at least one field to export');
			return;
		}

		// Filter users based on selection
		const filteredUsers = users.filter((user) => selectedUsers.includes(user.id));

		// Create CSV header
		const headers = selectedFields.join(',');

		// Create CSV rows
		const rows = filteredUsers.map((user) => {
			return selectedFields
				.map((field) => {
					// Handle nested profile fields
					if (['first_name', 'last_name', 'user_type', 'company_name'].includes(field)) {
						const value = user.profile[field as keyof typeof user.profile];
						return value ? `"${value}"` : '""';
					} else {
						const value = user[field as keyof typeof user];
						if (field === 'last_login' || field === 'created_at') {
							return value ? `"${new Date(value as string).toLocaleString()}"` : '""';
						}
						return value ? `"${value}"` : '""';
					}
				})
				.join(',');
		});

		// Combine header and rows
		const csvContent = `${headers}\n${rows.join('\n')}`;

		// Create a blob and download
		const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.setAttribute('href', url);
		link.setAttribute('download', `user_export_${new Date().toISOString().slice(0, 10)}.csv`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		message.success(`Successfully exported ${filteredUsers.length} users`);
		setIsModalVisible(false);
	};

	const fieldOptions = [
		{label: 'ID', value: 'id'},
		{label: 'Email', value: 'email'},
		{label: 'Username', value: 'username'},
		{label: 'Last Login', value: 'last_login'},
		{label: 'Created At', value: 'created_at'},
		{label: 'Applications Count', value: 'job_applications_count'},
		{label: 'First Name', value: 'first_name'},
		{label: 'Last Name', value: 'last_name'},
		{label: 'User Type', value: 'user_type'},
		{label: 'Company Name', value: 'company_name'},
		{label: 'CV Text', value: 'cv_text'},
		{label: 'CV URL', value: 'cv_url'},
	];

	return (
		<>
			<Tooltip title="Export selected users to CSV">
				<Button type="primary" icon={<DownloadOutlined />} onClick={showModal} disabled={users.length === 0}>
					Export to CSV
				</Button>
			</Tooltip>

			<Modal
				title="Export Users to CSV"
				open={isModalVisible}
				onCancel={handleCancel}
				footer={[
					<Button key="cancel" onClick={handleCancel}>
						Cancel
					</Button>,
					<Button key="export" type="primary" onClick={exportToCsv} disabled={selectedUsers.length === 0 || selectedFields.length === 0}>
						Export {selectedUsers.length} Users
					</Button>,
				]}
				width={800}>
				<div className="mb-4">
					<h3 className="text-lg font-medium mb-2">Select Fields to Export</h3>
					<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-2">
						{fieldOptions.map((field) => (
							<Checkbox key={field.value} checked={selectedFields.includes(field.value)} onChange={() => handleFieldCheckboxChange(field.value)}>
								{field.label}
							</Checkbox>
						))}
					</div>
				</div>

				<div>
					<div className="flex justify-between items-center mb-2">
						<h3 className="text-lg font-medium">Select Users to Export</h3>
						<Checkbox
							onChange={handleSelectAllUsers}
							checked={selectedUsers.length === users.length && users.length > 0}
							indeterminate={selectedUsers.length > 0 && selectedUsers.length < users.length}>
							Select All
						</Checkbox>
					</div>

					<div className="max-h-60 overflow-y-auto border rounded p-2">
						{users.length > 0 ? (
							users.map((user) => (
								<div key={user.id} className="py-1 border-b last:border-b-0">
									<Checkbox checked={selectedUsers.includes(user.id)} onChange={() => handleUserCheckboxChange(user.id)}>
										<span className="font-medium">
											{user.profile?.first_name || ''} {user.profile?.last_name || ''}
											{!user.profile?.first_name && !user.profile?.last_name && (user.username || 'Unknown User')}
										</span>
										<span className="text-gray-500 ml-2">({user.email || 'No email'})</span>
									</Checkbox>
								</div>
							))
						) : (
							<div className="text-center py-4 text-gray-500">No users available to export</div>
						)}
					</div>
				</div>
			</Modal>
		</>
	);
};

export default ExportUsersCsv;
