import React, {lazy, Suspense, useContext, useEffect, useState} from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbars/Navbar2';
import {countries} from '../../utils/arrayData/CountryArray';
import {categoryOptions} from '../../utils/arrayData/PortfolioArrays';
import './PostJobForm.scss';
import {DeleteOutlined, InfoCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {postJobApi} from '../../api/jobsApi';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Radio, Steps, Form, Input, Select, Button, List, Alert, Space, Typography, Layout, Modal, Tooltip, Card, Popconfirm} from 'antd';
import {Helmet} from 'react-helmet';
import {useNavigate} from 'react-router-dom';
import Payments from '../../components/Dashboard/Payments';
import {permColors} from '../../utils/colors';
import PricingSelect from './PricingSelect';
import {UserContext} from '../../utils/UserContext';
import {isStaffMember} from '../../utils/variables/userVariables';
import {PostAdd} from '@mui/icons-material';
import {SUBSCRIPTION_TYPES} from '../../components/Dashboard/subscription-helpers';
import {getUserApi, refreshTokenApi} from '../../api/profileApi';
import {daysOfWeek, timeSlots, timezoneData} from '../../utils/arrayData/timezone';
import {philippineCities} from '../../utils/arrayData/philippinesCities';
import {Alert as MuiAlert} from '@mui/material';
import {ROUTES} from '../../utils/ROUTES';

const {Title, Text} = Typography;
const {Content} = Layout;

export default function PostJobForm() {
	const [currentUser, setCurrentUser] = useContext(UserContext);

	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const [jobTitle, setJobTitle] = useState('');
	const [description, setDescription] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [country, setCountry] = useState('');
	const [jobType, setJobType] = useState('');
	const [category, setCategory] = useState('');
	const [engagementType, setEngagementType] = useState('');

	const [paymentRateType, setPaymentRateType] = useState('');
	const [paymentRateFrom, setPaymentRateFrom] = useState(null);
	const [paymentRateTo, setPaymentRateTo] = useState(null);
	const [paymentRateCurrency, setPaymentRateCurrency] = useState('');
	const [showSalaryOnJob, setShowSalaryOnJob] = useState(true);
	const [questions, setQuestions] = useState([]);
	const [coverLetterRequired] = useState(true);
	const categories = categoryOptions;
	const countriesList = countries;
	const [currentQuestion, setCurrentQuestion] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [paymentConfirmed, setPaymentConfirmed] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [subscriptionType, setSubscriptionType] = useState('');
	const [isApplying, setIsApplying] = useState(false);
	const [isStaff, setIsStaff] = useState(false);
	const [stepsLocked] = useState(true);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [roleLocationType, setRoleLocationType] = useState('');
	const [roleLocation, setRoleLocation] = useState('');
	const [completedSteps, setCompletedSteps] = useState(new Set());

	const [workScheduleType, setWorkScheduleType] = useState('');
	const [workScheduleTimezone, setWorkScheduleTimezone] = useState('');
	const [workScheduleDays, setWorkScheduleDays] = useState(['monday', 'tuesday', 'wednesday', 'thursday', 'friday']);
	const [workScheduleHoursStart, setWorkScheduleHoursStart] = useState('09:00');
	const [workScheduleHoursEnd, setWorkScheduleHoursEnd] = useState('18:00');
	const [workScheduleFlexibleNotes, setWorkScheduleFlexibleNotes] = useState('');
	const [shifts, setShifts] = useState([]);
	const [currentShift, setCurrentShift] = useState({
		day_of_week: null,
		start_time: null,
		end_time: null,
	});

	const [equipmentAllocation, setEquipmentAllocation] = useState('');
	const [provideHMO, setProvideHMO] = useState(false);
	const [isPreviousDataModalVisible, setIsPreviousDataModalVisible] = useState(false);

	const [useExternalLink, setUseExternalLink] = useState(false);
	const [externalLink, setExternalLink] = useState('');

	useEffect(() => {
		if (currentUser) {
			setCompanyName(currentUser?.profile?.company_name);
			form.setFieldValue('companyName', currentUser?.profile?.company_name);
		}
	}, [currentUser]);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	function orderDaysOfWeekByDay(days) {
		const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
		return daysOfWeek.filter((day) => days?.map((d) => d?.toLowerCase())?.includes(day?.toLowerCase()) || false);
	}

	const loadSavedData = () => {
		const savedFormData = localStorage.getItem('postJobFormData');
		if (savedFormData) {
			const parsedData = JSON.parse(savedFormData);

			// Set all the state values from localStorage
			setJobTitle(parsedData.jobTitle || '');
			setDescription(parsedData.description || '');
			setCompanyName(parsedData.companyName || '');
			setCountry(parsedData.country || '');
			setJobType(parsedData.jobType || '');
			setCategory(parsedData.category || '');
			setEngagementType(parsedData.engagementType || '');
			setPaymentRateType(parsedData.paymentRateType || '');
			setPaymentRateFrom(parsedData.paymentRateFrom || null);
			setPaymentRateTo(parsedData.paymentRateTo || null);
			setPaymentRateCurrency(parsedData.paymentRateCurrency || '');
			setShowSalaryOnJob(parsedData.showSalaryOnJob ?? true);
			setQuestions(parsedData.questions || []);
			setRoleLocationType(parsedData.roleLocationType || '');
			setRoleLocation(parsedData.roleLocation || '');
			setWorkScheduleType(parsedData.workScheduleType || '');
			setWorkScheduleTimezone(parsedData.workScheduleTimezone || '');
			setWorkScheduleDays(parsedData.workScheduleDays || ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']);
			setWorkScheduleHoursStart(parsedData.workScheduleHoursStart || '09:00');
			setWorkScheduleHoursEnd(parsedData.workScheduleHoursEnd || '18:00');
			setWorkScheduleFlexibleNotes(parsedData.workScheduleFlexibleNotes || '');
			setShifts(parsedData.shifts || []);
			setEquipmentAllocation(parsedData.equipmentAllocation || '');
			setProvideHMO(parsedData.provideHMO ?? false);
			setUseExternalLink(parsedData.useExternalLink ?? false);
			setExternalLink(parsedData.externalLink || '');

			// Update form values
			form.setFieldsValue({
				jobTitle: parsedData.jobTitle,
				companyName: parsedData.companyName,
				country: parsedData.country,
				category: parsedData.category,
				jobType: parsedData.jobType,
				engagementType: parsedData.engagementType,
				paymentRateType: parsedData.paymentRateType,
				paymentRateFrom: parsedData.paymentRateFrom,
				paymentRateTo: parsedData.paymentRateTo,
				paymentRateCurrency: parsedData.paymentRateCurrency,
				showSalaryOnJob: parsedData.showSalaryOnJob,
				roleLocationType: parsedData.roleLocationType,
				roleLocation: parsedData.roleLocation,
				workScheduleType: parsedData.workScheduleType,
				workScheduleTimezone: parsedData.workScheduleTimezone,
				workScheduleDays: parsedData.workScheduleDays,
				workScheduleHours: parsedData.workScheduleHours,
				workScheduleFlexibleNotes: parsedData.workScheduleFlexibleNotes,
				equipmentAllocation: parsedData.equipmentAllocation,
				provideHMO: parsedData.provideHMO,
				useExternalLink: parsedData.useExternalLink,
				externalLink: parsedData.externalLink,
			});
		}
		setIsPreviousDataModalVisible(false);
	};

	useEffect(() => {
		const savedFormData = localStorage.getItem('postJobFormData');
		if (savedFormData) {
			const parsedData = JSON.parse(savedFormData);

			// Set all the state values from localStorage
			setJobTitle(parsedData.jobTitle || '');
			setDescription(parsedData.description || '');
			setCompanyName(parsedData.companyName || '');
			setCountry(parsedData.country || '');
			setJobType(parsedData.jobType || '');
			setCategory(parsedData.category || '');
			setEngagementType(parsedData.engagementType || '');
			setPaymentRateType(parsedData.paymentRateType || '');
			setPaymentRateFrom(parsedData.paymentRateFrom || null);
			setPaymentRateTo(parsedData.paymentRateTo || null);
			setPaymentRateCurrency(parsedData.paymentRateCurrency || '');
			setShowSalaryOnJob(parsedData.showSalaryOnJob ?? true);
			setQuestions(parsedData.questions || []);
			setRoleLocationType(parsedData.roleLocationType || '');
			setRoleLocation(parsedData.roleLocation || '');
			setWorkScheduleType(parsedData.workScheduleType || '');
			setWorkScheduleTimezone(parsedData.workScheduleTimezone || '');
			setWorkScheduleDays(parsedData.workScheduleDays || ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']);
			setWorkScheduleHoursStart(parsedData.workScheduleHoursStart || '09:00');
			setWorkScheduleHoursEnd(parsedData.workScheduleHoursEnd || '18:00');
			setWorkScheduleFlexibleNotes(parsedData.workScheduleFlexibleNotes || '');
			setShifts(parsedData.shifts || []);
			setEquipmentAllocation(parsedData.equipmentAllocation || '');
			setProvideHMO(parsedData.provideHMO ?? false);
			setUseExternalLink(parsedData.useExternalLink ?? false);
			setExternalLink(parsedData.externalLink || '');

			// Update form values
			form.setFieldsValue({
				jobTitle: parsedData.jobTitle,
				companyName: parsedData.companyName,
				country: parsedData.country,
				category: parsedData.category,
				jobType: parsedData.jobType,
				engagementType: parsedData.engagementType,
				paymentRateType: parsedData.paymentRateType,
				paymentRateFrom: parsedData.paymentRateFrom,
				paymentRateTo: parsedData.paymentRateTo,
				paymentRateCurrency: parsedData.paymentRateCurrency,
				showSalaryOnJob: parsedData.showSalaryOnJob,
				roleLocationType: parsedData.roleLocationType,
				roleLocation: parsedData.roleLocation,
				workScheduleType: parsedData.workScheduleType,
				workScheduleTimezone: parsedData.workScheduleTimezone,
				workScheduleDays: parsedData.workScheduleDays,
				workScheduleHours: parsedData.workScheduleHours,
				workScheduleFlexibleNotes: parsedData.workScheduleFlexibleNotes,
				equipmentAllocation: parsedData.equipmentAllocation,
				provideHMO: parsedData.provideHMO,
				useExternalLink: parsedData.useExternalLink,
				externalLink: parsedData.externalLink,
			});
		}
	}, []); // Run only once when component mounts

	useEffect(() => {
		const savedFormData = localStorage.getItem('postJobFormData');
		if (savedFormData) {
			const parsedData = JSON.parse(savedFormData);

			// Define fields to ignore (default values)
			const defaultValues = {
				showSalaryOnJob: true,
				workScheduleDays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
				workScheduleFlexibleNotes: '',
				workScheduleHoursEnd: '18:00',
				workScheduleHoursStart: '09:00',
			};

			// Check if any non-default field has meaningful data
			const hasOtherData = Object.keys(parsedData).some((key) => {
				// Skip company name and default value fields
				if (key === 'companyName' || key in defaultValues) return false;

				const value = parsedData[key];

				// Skip empty/null/undefined values
				if (!value) return false;

				// For arrays, check if they have items
				if (Array.isArray(value)) return value.length > 0;
				// For strings, check if non-empty after trim
				if (typeof value === 'string') return value.trim() !== '';
				// For numbers, check if non-null
				if (typeof value === 'number') return value !== null;

				return false;
			});

			// Only show popup if there's other data or company name is different
			if (hasOtherData || (parsedData.companyName && parsedData.companyName !== currentUser?.profile?.company_name)) {
				setIsPreviousDataModalVisible(true);
			}
		}
	}, [currentUser?.profile?.company_name]);

	// Add this useEffect to save data to localStorage whenever relevant states change
	useEffect(() => {
		const formData = {
			jobTitle,
			description,
			companyName,
			country,
			jobType,
			category,
			engagementType,
			paymentRateType,
			paymentRateFrom,
			paymentRateTo,
			paymentRateCurrency,
			showSalaryOnJob,
			questions,
			roleLocationType,
			roleLocation,
			workScheduleType,
			workScheduleTimezone,
			workScheduleDays,
			workScheduleHoursStart,
			workScheduleHoursEnd,
			workScheduleFlexibleNotes,
			shifts,
			equipmentAllocation,
			provideHMO,
			useExternalLink,
			externalLink,
		};

		localStorage.setItem('postJobFormData', JSON.stringify(formData));
	}, [
		jobTitle,
		description,
		companyName,
		country,
		jobType,
		category,
		engagementType,
		paymentRateType,
		paymentRateFrom,
		paymentRateTo,
		paymentRateCurrency,
		showSalaryOnJob,
		questions,
		roleLocationType,
		roleLocation,
		workScheduleType,
		workScheduleTimezone,
		workScheduleDays,
		workScheduleHoursStart,
		workScheduleHoursEnd,
		workScheduleFlexibleNotes,
		shifts,
		equipmentAllocation,
		provideHMO,
		useExternalLink,
		externalLink,
	]);

	const clearSavedData = () => {
		localStorage.removeItem('postJobFormData');
		form.resetFields();
		// Reset all state values to their defaults
		setJobTitle('');
		setDescription('');
		setCountry('');
		setJobType('');
		setCategory('');
		setEngagementType('');
		setPaymentRateType('');
		setPaymentRateFrom(null);
		setPaymentRateTo(null);
		setPaymentRateCurrency('');
		setShowSalaryOnJob(true);
		setQuestions([]);
		setRoleLocationType('');
		setRoleLocation('');
		setWorkScheduleType('');
		setWorkScheduleTimezone('');
		setWorkScheduleDays(['monday', 'tuesday', 'wednesday', 'thursday', 'friday']);
		setWorkScheduleHoursStart('09:00');
		setWorkScheduleHoursEnd('18:00');
		setWorkScheduleFlexibleNotes('');
		setShifts([]);
		setEquipmentAllocation('');
		setProvideHMO(false);
		setUseExternalLink(false);
		setExternalLink('');

		// Set company name and form field after a brief delay to ensure form reset is complete
		setTimeout(() => {
			const companyNameValue = currentUser?.profile?.company_name || '';
			setCompanyName(companyNameValue);
			form.setFieldValue('companyName', companyNameValue);
			form.setFieldValue('useExternalLink', false);
		}, 0);

		setCurrentStep(0);
		setCompletedSteps(new Set());

		setIsPreviousDataModalVisible(false);
	};

	useEffect(() => {
		const orderedDays = orderDaysOfWeekByDay(workScheduleDays).join(',');
		console.log({
			jobTitle,
			description,
			companyName,
			country,
			category,
			engagementType,
			paymentRateType,
			paymentRateFrom,
			paymentRateTo,
			paymentRateCurrency,
			showSalaryOnJob,
			questions,
			currentQuestion,
			errorMsg,
			paymentConfirmed,
			currentStep,
			subscriptionType,
			isApplying,
			isStaff,
			isModalVisible,
			roleLocationType,
			roleLocation,
			completedSteps,
			workScheduleType,
			workScheduleTimezone,
			orderedDays,
			workScheduleHoursStart,
			workScheduleHoursEnd,
			workScheduleFlexibleNotes,
			shifts,
			currentShift,
			equipmentAllocation,
			provideHMO,
			useExternalLink,
			externalLink,
		});
	}, [
		jobTitle,
		description,
		companyName,
		country,
		category,
		engagementType,
		paymentRateType,
		paymentRateFrom,
		paymentRateTo,
		paymentRateCurrency,
		showSalaryOnJob,
		questions,
		currentQuestion,
		errorMsg,
		paymentConfirmed,
		currentStep,
		subscriptionType,
		isApplying,
		isStaff,
		isModalVisible,
		roleLocationType,
		roleLocation,
		completedSteps,
		workScheduleType,
		workScheduleTimezone,
		workScheduleDays,
		workScheduleHoursStart,
		workScheduleHoursEnd,
		workScheduleFlexibleNotes,
		shifts,
		currentShift,
		equipmentAllocation,
		provideHMO,
		useExternalLink,
		externalLink,
	]);

	useEffect(() => {
		if (currentUser) {
			if (isStaffMember(currentUser)) {
				setPaymentConfirmed(true);
				setIsStaff(true);
				setSubscriptionType('premium');
			}
		}
	}, [currentUser, paymentConfirmed]);

	const validateStep = async () => {
		if (!stepsLocked) return true;

		try {
			const currentStepFields = {
				0: ['jobTitle', 'companyName', 'country', 'category', 'roleLocationType', 'roleLocation'],
				1: ['description', 'externalLink', 'useExternalLink'],
				2: ['jobType', 'engagementType'],
				3: ['paymentRateType', 'paymentRateFrom', 'paymentRateTo', 'paymentRateCurrency', 'showSalaryOnJob'],
				4: ['equipmentAllocation', 'provideHMO'],
				5: [], // Questions step has no required fields
				6: [], // Review step has no required fields
				7: ['subscriptionType'], // Publish step
			};

			await form.validateFields(currentStepFields[currentStep]);
			setCompletedSteps((prev) => new Set([...prev, currentStep]));
			return true;
		} catch (error) {
			return false;
		}
	};

	const handleNext = async () => {
		if (await validateStep()) {
			setCurrentStep(currentStep + 1);
		}
	};

	const handleBack = () => {
		setCurrentStep(currentStep - 1);
	};

	const addQuestion = () => {
		if (currentQuestion.trim()) {
			setQuestions([...questions, {question: currentQuestion}]);
			setCurrentQuestion('');
		}
	};

	const removeQuestion = (index) => {
		const newQuestions = [...questions];
		newQuestions.splice(index, 1);
		setQuestions(newQuestions);
	};

	const showPublishConfirm = () => {
		setIsModalVisible(true);
	};

	const handlePublish = async () => {
		try {
			await form.validateFields();
			setIsApplying(true);
			setIsModalVisible(false);

			const orderedDays = orderDaysOfWeekByDay(workScheduleDays);

			const res = await postJobApi(
				jobTitle,
				description,
				companyName || null,
				country,
				jobType,
				category,
				engagementType,
				paymentRateType,
				paymentRateFrom,
				paymentRateTo,
				paymentRateCurrency,
				showSalaryOnJob,
				questions,
				coverLetterRequired,
				subscriptionType,
				roleLocationType,
				roleLocationType !== 'remote' ? roleLocation : null,
				workScheduleType,
				workScheduleTimezone,
				orderedDays.join(','),
				workScheduleHoursStart,
				workScheduleHoursEnd,
				workScheduleFlexibleNotes,
				shifts,
				equipmentAllocation,
				provideHMO,
				useExternalLink,
				externalLink
			);

			if (subscriptionType === SUBSCRIPTION_TYPES.free) {
				try {
					const response = await getUserApi(localStorage.getItem('accessToken'));
					setCurrentUser(response.data);
					console.log('Successfully fetched user on first try');
				} catch (error) {
					console.log(error);
					console.log('Could not fetch the user, trying to refresh token...');
					try {
						const response = await refreshTokenApi(localStorage.getItem('token'));
						localStorage.setItem('accessToken', response.data.access);
						try {
							const response = await getUserApi(localStorage.getItem('accessToken'));
							setCurrentUser(response.data);
							console.log('Successfully fetched user on first try');
						} catch (error) {
							console.log(error);
							console.log('Could not fetch the user, trying to refresh token...');
						}
					} catch (refreshError) {
						console.log(refreshError);
						console.log('Refreshing token also failed, logging out the user...');
						window.location.reload();
					}
				}
			}

			if (res) {
				clearSavedData();
				if (res?.job?.id) {
					navigate(ROUTES.JOB_PAGE.replace(':jobId', res?.job?.id));
				} else {
					navigate(ROUTES.DASHBOARD);
				}
			}
		} catch (error) {
			setErrorMsg('An error occurred while posting the job');
			setIsApplying(false);
		}
	};

	function tooltip(title, description) {
		return (
			<div className="flex items-center gap-2">
				<span className="font-semibold">{title}</span>
				<Tooltip className="cursor-pointer" title={description}>
					<InfoCircleOutlined style={{fontSize: '14px', background: permColors.white}} className="p-1 rounded-lg" />
				</Tooltip>
			</div>
		);
	}

	const steps = [
		{
			title: 'Job Details',
			content: (
				<Form.Item>
					<Space direction="vertical" size="large" style={{width: '100%'}}>
						<Form.Item
							name="jobTitle"
							label={tooltip('Job title', 'This is the title of your job posting. It will be displayed in the job listing and on the job page.')}
							rules={[{required: true, message: 'Please enter a job title'}]}>
							<Input placeholder="Social media manager" onChange={(e) => setJobTitle(e.target.value)} />
						</Form.Item>

						<Form.Item
							name="companyName"
							label={tooltip('Company name', 'This is the name of the company you are hiring from. It will be displayed in the job listing and on the job page.')}
							rules={[{required: true, message: 'Please enter a company name'}]}>
							<Input placeholder="Permworks" onChange={(e) => setCompanyName(e.target.value)} value={companyName} />
						</Form.Item>

						<Form.Item
							name="country"
							label={tooltip('Company location', 'This is the country where the company is located. It will be displayed in the job listing and on the job page.')}
							rules={[{required: true, message: 'Please select a country'}]}>
							<Select placeholder="Select country" onChange={(value) => setCountry(value)} showSearch>
								{countriesList.map((country, index) => (
									<Select.Option key={index} value={country} disabled={country === '----'} className={country === '----' ? 'opacity-50' : ''}>
										{country}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item
							name="category"
							label={tooltip('Category', 'This is the category of the job you are posting. It will be displayed in the job listing and on the job page.')}
							rules={[{required: true, message: 'Please select a category'}]}>
							<Select placeholder="Select category" onChange={(value) => setCategory(value)}>
								{categories.map((category, index) => (
									<Select.Option key={index} value={category}>
										{category}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item
							name="roleLocationType"
							label={tooltip('Role Location', 'Select whether this role is remote, hybrid, or on-site. For hybrid and on-site roles, please specify the location.')}
							rules={[{required: true, message: 'Please select a role location'}]}>
							<Radio.Group
								className="flex flex-col gap-1"
								onChange={(e) => {
									setRoleLocationType(e.target.value);
									if (e.target.value === 'remote') {
										setRoleLocation('');
										form.setFieldValue('roleLocation', '');
									}
								}}
								buttonStyle="solid">
								<Radio value="remote">Remote</Radio>
								<Radio value="hybrid">Hybrid</Radio>
								<Radio value="onsite">On-site</Radio>
							</Radio.Group>
						</Form.Item>
						{(roleLocationType === 'hybrid' || roleLocationType === 'onsite') && (
							<Form.Item
								name="roleLocation"
								rules={[{required: true, message: 'Please enter the work location'}]}
								label={tooltip('Work Location', 'This is the location where the employee will be working from. It will be displayed in the job listing and on the job page.')}>
								<Select placeholder="Select city" onChange={(value) => setRoleLocation(value)} options={philippineCities.map((city) => ({label: city, value: city}))} showSearch />
							</Form.Item>
						)}
					</Space>
				</Form.Item>
			),
		},
		{
			title: 'Description',
			content: (
				<>
					<Form.Item
						name="description"
						label={tooltip('Job Description', 'This is the description of the job you are posting. It will be displayed in the job listing and on the job page.')}
						rules={[
							{
								required: true,
								validator: (_, value) => {
									// Remove HTML tags and trim whitespace
									const cleanContent = description.replace(/<[^>]*>/g, '').trim();
									if (!cleanContent) {
										return Promise.reject('Please enter a job description');
									}
									return Promise.resolve();
								},
							},
						]}>
						<CKEditor
							editor={ClassicEditor}
							data={description} // Add this line to set initial data
							config={{
								toolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList', 'blockQuote'],
								htmlSupport: {
									allow: [
										{
											name: /.*/,
											attributes: true,
											classes: true,
											styles: true,
										},
									],
								},
							}}
							onChange={(event, editor) => {
								const data = editor.getData();
								setDescription(data);
								form.setFieldValue('description', data); // Add this line to update form value
								form.validateFields(['description']);
							}}
							minHeight={500}
						/>
					</Form.Item>
					<Form.Item
						name="useExternalLink"
						className="mb-2"
						label={tooltip('Use External Link', 'Select whether you want to use an external link for this job posting.')}
						rules={[{required: true, message: 'Please select an option'}]}>
						<Radio.Group onChange={(e) => setUseExternalLink(e.target.value)} buttonStyle="solid" value={useExternalLink}>
							<Radio value={true}>Yes</Radio>
							<Radio value={false}>No (Recommended)</Radio>
						</Radio.Group>
					</Form.Item>
					<Alert
						message="Recommended: Keep job applications on Permworks"
						description="Using an external link means candidates will apply outside our platform. You'll miss out on our applicant tracking features and candidate management tools."
						type="info"
						showIcon
						className="rounded-md mb-3"
						banner
					/>
					{useExternalLink && (
						<Form.Item
							name="externalLink"
							label={tooltip('External Link', 'Enter the external link for this job posting.')}
							rules={[
								{
									required: true,
									message: 'Please enter a valid external link (make sure it starts with http:// or https://)',
									type: 'url',
								},
							]}>
							<Input placeholder="Enter the external link (e.g. https://example.com)" type="url" onChange={(e) => setExternalLink(e.target.value)} />
						</Form.Item>
					)}
				</>
			),
		},
		{
			title: 'Hiring Details',
			content: (
				<Space direction="vertical" size="large" style={{width: '100%'}}>
					<Form.Item
						name="jobType"
						label={tooltip('Employment Type', 'This is the type of employment you are offering. It will be displayed in the job listing and on the job page.')}
						rules={[{required: true, message: 'Please select an employment type'}]}>
						<Radio.Group onChange={(e) => setJobType(e.target.value)} buttonStyle="solid">
							<Radio value="Full-Time">Full-Time</Radio>
							<Radio value="Part-Time">Part-Time</Radio>
							<Radio value="Contract">Contract</Radio>
							<Radio value="Temporary">Temporary</Radio>
						</Radio.Group>
					</Form.Item>

					<Form.Item
						className="mb-1"
						name="workScheduleType"
						label={tooltip('Work Schedule Type', 'Select the type of work schedule for this position')}
						rules={[{required: true, message: 'Please select a work schedule type'}]}>
						<Radio.Group
							onChange={(e) => {
								setWorkScheduleType(e.target.value);
								form.setFieldValue('workScheduleType', e.target.value);
							}}
							buttonStyle="solid">
							<Radio value="fixed">Fixed Schedule</Radio>
							<Radio value="shift-based">Shift-Based</Radio>
							<Radio value="flexible">Flexible Schedule</Radio>
							<Radio value="anytime">Anytime</Radio>
						</Radio.Group>
					</Form.Item>
					{workScheduleType && (
						<MuiAlert severity="info" className="mb-4">
							{workScheduleType === 'fixed'
								? 'Employees work the same hours each day, like 9 AM to 6 PM Monday-Friday.'
								: workScheduleType === 'flexible'
								? 'Employees can work different hours while meeting required hours per day/week.'
								: workScheduleType === 'shift-based'
								? 'Work is divided into shifts that can vary by time and day. Shifts may rotate between employees.'
								: 'Employees can work during any hours set by the employer.'}
						</MuiAlert>
					)}
					{workScheduleType && workScheduleType === 'fixed' && (
						<>
							<Form.Item name="workScheduleTimezone" label={tooltip('Timezone', 'Select the timezone for this position')} rules={[{required: true, message: 'Please select a timezone'}]}>
								<Select
									showSearch
									placeholder="Select timezone"
									options={timezoneData.map((timezone) => ({label: timezone.label, value: timezone.label}))}
									onChange={(value) => {
										setWorkScheduleTimezone(value);
										form.setFieldValue('workScheduleTimezone', value);
									}}
									filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								/>
							</Form.Item>
							{workScheduleType === 'fixed' && (
								<Form.Item name="workScheduleDays" label={tooltip('Working Days', 'Select the working days')} rules={[{required: true, message: 'Please select working days'}]}>
									<Select
										className="min-h-[46px]"
										rootClassName="min-h-[46px]"
										style={{minHeight: '46px !important'}}
										mode="multiple"
										placeholder="Select working days"
										defaultValue={['monday', 'tuesday', 'wednesday', 'thursday', 'friday']}
										onChange={(value) => {
											setWorkScheduleDays(value);
											form.setFieldValue('workScheduleDays', value);
										}}
										options={[
											{label: 'Monday', value: 'monday'},
											{label: 'Tuesday', value: 'tuesday'},
											{label: 'Wednesday', value: 'wednesday'},
											{label: 'Thursday', value: 'thursday'},
											{label: 'Friday', value: 'friday'},
											{label: 'Saturday', value: 'saturday'},
											{label: 'Sunday', value: 'sunday'},
										]}
									/>
								</Form.Item>
							)}
						</>
					)}

					{workScheduleType === 'fixed' && (
						<>
							<Form.Item
								name="workScheduleHours"
								label={tooltip(`${workScheduleType === 'fixed' ? 'Working' : 'Core working'} hours`, 'Select the working hours')}
								rules={[{required: true, message: 'Please select working hours'}]}>
								<Space>
									<Select
										placeholder="Start time"
										value={workScheduleHoursStart}
										options={timeSlots.map((time) => ({label: time, value: time}))}
										onChange={(time) => {
											setWorkScheduleHoursStart(time);
											form.setFieldValue('workScheduleHoursStart', time);
										}}
										className="min-w-[120px]"
									/>
									<Select
										placeholder="End time"
										value={workScheduleHoursEnd}
										options={timeSlots.map((time) => ({label: time, value: time}))}
										onChange={(time) => {
											setWorkScheduleHoursEnd(time);
											form.setFieldValue('workScheduleHoursEnd', time);
										}}
										className="min-w-[120px]"
									/>
								</Space>
							</Form.Item>
						</>
					)}

					{/* {workScheduleType === 'flexible' && (
						<Form.Item name="workScheduleFlexibleNotes" label={tooltip('Flexible working hours notes', 'Enter any additional notes for flexible working hours')}>
							<Input placeholder="Enter notes" onChange={(e) => setWorkScheduleFlexibleNotes(e.target.value)} />
						</Form.Item>
					)} */}

					{workScheduleType === 'shift-based' && (
						<Space direction="vertical" size="large" style={{width: '100%'}}>
							<Form.Item label={tooltip('Add Shifts', 'Add the different shifts available for this position')}>
								<Space direction="vertical" style={{width: '100%'}}>
									<div className="flex md:flex-row flex-col gap-2 w-full">
										<Select
											placeholder="Day"
											options={daysOfWeek.map((day) => ({label: day, value: day}))}
											onChange={(day) => setCurrentShift((prev) => ({...prev, day_of_week: day}))}
											className="min-w-[120px]"
											value={currentShift.day_of_week}
										/>
										<Select
											placeholder="Start time"
											options={timeSlots.map((time) => ({label: time, value: time}))}
											onChange={(time) => setCurrentShift((prev) => ({...prev, start_time: time}))}
											value={currentShift.start_time}
										/>
										<Select
											placeholder="End time"
											options={timeSlots.map((time) => ({label: time, value: time}))}
											onChange={(time) => setCurrentShift((prev) => ({...prev, end_time: time}))}
											value={currentShift.end_time}
										/>

										<Button
											icon={<PlusOutlined />}
											className="min-h-[40px]"
											onClick={() => {
												if (currentShift.start_time && currentShift.end_time && currentShift.day_of_week) {
													setShifts([...shifts, {...currentShift}]);
													setCurrentShift({start_time: null, end_time: null, shift_duration: ''});
												}
											}}>
											Add Shift
										</Button>
									</div>

									<List
										dataSource={shifts}
										renderItem={(shift, index) => (
											<List.Item
												actions={[
													<Button
														type="text"
														danger
														icon={<DeleteOutlined />}
														onClick={() => {
															const newShifts = [...shifts];
															newShifts.splice(index, 1);
															setShifts(newShifts);
														}}
													/>,
												]}>
												<Text>{`${shift.day_of_week} - ${shift.start_time} - ${shift.end_time}`}</Text>
											</List.Item>
										)}
									/>
								</Space>
							</Form.Item>
						</Space>
					)}

					<Form.Item
						name="engagementType"
						label={tooltip('Engagement Type', 'This is the type of engagement you are offering. It will be displayed in the job listing and on the job page.')}
						rules={[{required: true, message: 'Please select an engagement type'}]}>
						<div className="flex md:flex-row flex-col gap-2">
							<Card
								className="flex-1 w-1/2"
								title={
									<div className="flex flex-row gap-2">
										<Radio value="Employee" checked={engagementType === 'Employee'} />
										Employee Contract
									</div>
								}
								style={{
									width: '100%',
									cursor: 'pointer',
									border: engagementType === 'employee' ? '2px solid #1890ff' : '1px solid #d9d9d9',
								}}
								onClick={() => {
									setEngagementType('Employee');
									form.setFieldValue('engagementType', 'Employee');
								}}>
								<div className="text-gray-600 text-sm leading-relaxed mb-3 bg-blue-50 p-3 rounded-lg border border-blue-100">
									Applicable for <b>Philippines registered companies</b> or{' '}
									<b>
										foreign companies (US, UK, Canada, Australia, etc.) through an Employer of Record Service (hire under a 3rd party company without needing to set one up
										yourself)
									</b>
								</div>

								<p className="text-sm font-normal mb-1 underline">Typically includes:</p>
								<ul className="list-disc list-inside italic">
									<li>Vacation Leave</li>
									<li>Public Holiday Leave</li>
									<li>Government Mandated Benefits (SSS, PhilHealth, Pag-IBIG)</li>
									<li>13th Month</li>
									<li>Overtime loading (10pm - 6am PH)</li>
								</ul>
							</Card>
							<Card
								className="flex-1 w-1/2"
								title={
									<div className="flex flex-row gap-2">
										<Radio value="Contractor" checked={engagementType === 'Contractor'} />
										Contractor Agreement
									</div>
								}
								style={{
									width: '100%',
									cursor: 'pointer',
									border: engagementType === 'Contractor' ? '2px solid #1890ff' : '1px solid #d9d9d9',
								}}
								onClick={() => {
									setEngagementType('Contractor');
									form.setFieldValue('engagementType', 'Contractor');
								}}>
								<div className="text-gray-600 text-sm leading-relaxed mb-3 bg-blue-50 p-3 rounded-lg border border-blue-100">
									Applicable for <b>Philippines registered companies</b> or <b>Non-Philippines (US, UK, AU, etc) registered companies directly.</b>
								</div>
								<p className="text-sm font-normal mb-1 underline">Typically includes:</p>
								<ul className="list-disc list-inside italic">
									<li>No Vacation Leave</li>
									<li>No Public Holiday Leave</li>
									<li>No Government Mandated Benefits</li>
									<li>No 13th Month</li>
									<li>No nighttime loading</li>
								</ul>
							</Card>
						</div>
					</Form.Item>
				</Space>
			),
		},
		{
			title: 'Salary',
			content: (
				<Space direction="vertical" size="large" style={{width: '100%'}}>
					<Form.Item
						name="paymentRateType"
						label={tooltip('Payment Rate Type', 'Select how you want to specify the payment rate')}
						rules={[{required: true, message: 'Please select a payment rate type'}]}>
						<Radio.Group onChange={(e) => setPaymentRateType(e.target.value)} buttonStyle="solid">
							<Radio value="hourly">Hourly Rate</Radio>
							<Radio value="monthly">Monthly Salary</Radio>
							<Radio value="annual">Annual Salary</Radio>
						</Radio.Group>
					</Form.Item>
					<div className="grid grid-cols-1 md:grid-cols-8 gap-2">
						<Form.Item
							name="paymentRateCurrency"
							label={tooltip('Currency', 'Select the currency for the payment rate')}
							rules={[{required: true, message: 'Please select a currency'}]}
							className="col-span-2 md:col-span-4">
							<Select
								onChange={(value) => setPaymentRateCurrency(value)}
								options={[
									{label: 'PHP - Philippine Peso', value: 'PHP'},
									{label: 'USD - US Dollar', value: 'USD'},
									{label: 'CAD - Canadian Dollar', value: 'CAD'},
									{label: 'AUD - Australian Dollar', value: 'AUD'},
									{label: 'NZD - New Zealand Dollar', value: 'NZD'},
								]}
							/>
						</Form.Item>

						<Form.Item
							className="col-span-2"
							name="paymentRateFrom"
							label={tooltip('Payment Rate From', 'Enter the minimum payment rate')}
							rules={[{required: true, message: 'Please enter the minimum payment rate'}]}>
							<Input type="number" placeholder="Minimum pay" onChange={(e) => setPaymentRateFrom(e.target.value)} />
						</Form.Item>

						<Form.Item
							className="col-span-2"
							name="paymentRateTo"
							label={tooltip('Payment Rate To', 'Enter the maximum payment rate')}
							rules={[{required: true, message: 'Please enter the maximum payment rate'}]}>
							<Input type="number" placeholder="Maximum pay" onChange={(e) => setPaymentRateTo(e.target.value)} />
						</Form.Item>
					</div>
					<Form.Item
						name="showSalaryOnJob"
						label={tooltip('Show Salary', 'Choose whether to display the salary on the job posting')}
						rules={[{required: true, message: 'Please select an option'}]}>
						<Radio.Group onChange={(e) => setShowSalaryOnJob(e.target.value)}>
							<Radio value={true}>Show salary on job post</Radio>
							<Radio value={false}>Hide salary on job post</Radio>
						</Radio.Group>
					</Form.Item>
				</Space>
			),
		},
		{
			title: 'Equipment & benefits',
			content: (
				<Space direction="vertical" size="large" style={{width: '100%'}}>
					<Form.Item
						name="equipmentAllocation"
						label={tooltip('Equipment Allocation', 'Specify any equipment provided to employees')}
						rules={[{required: true, message: 'Please select an option'}]}>
						<Radio.Group onChange={(e) => setEquipmentAllocation(e.target.value)} className="flex flex-col gap-2">
							<Radio value="candidate to use their own laptop">Candidate to use their own laptop</Radio>
							<Radio value="company supplied laptop">Company supplied laptop</Radio>
							<Radio value="monthly allowance for equipment">Monthly allowance for equipment</Radio>
						</Radio.Group>
					</Form.Item>

					<Form.Item
						name="provideHMO"
						label={tooltip('Private health insurance (HMO)', 'HMO is a private health insurance scheme in the Philippines. It is not a government-mandated benefit.')}
						rules={[{required: true, message: 'Please select an option'}]}>
						<Radio.Group onChange={(e) => setProvideHMO(e.target.value)} className="flex flex-col gap-2">
							<Radio value={true}>Company provided HMO</Radio>
							<Radio value={false}>Not provided</Radio>
						</Radio.Group>
					</Form.Item>
				</Space>
			),
		},
		{
			title: 'Questions',
			content: (
				<Space direction="vertical" size="large" style={{width: '100%'}}>
					{tooltip('Questions', 'Add questions for candidates to answer')}
					<Input.Group compact>
						<Input style={{width: 'calc(100% - 60px)'}} value={currentQuestion} onChange={(e) => setCurrentQuestion(e.target.value)} placeholder="Enter a question for candidates" />
						<Button type="primary" className="min-h-[40px]" onClick={addQuestion}>
							Add
						</Button>
					</Input.Group>

					<List
						dataSource={questions}
						renderItem={(item, index) => (
							<List.Item actions={[<Button type="text" danger icon={<DeleteOutlined />} onClick={() => removeQuestion(index)} />]}>
								<Text>{item.question}</Text>
							</List.Item>
						)}
					/>
				</Space>
			),
		},
		{
			title: 'Review',
			content: (
				<Space direction="vertical" size="large" style={{width: '100%'}}>
					<List
						itemLayout="horizontal"
						dataSource={[
							{label: 'Job Title', value: jobTitle || ''},
							{label: 'Company Name', value: companyName || ''},
							{label: 'Country', value: country || ''},
							{label: 'Category', value: category || ''},
							{label: 'Role Location Type', value: roleLocationType || ''},
							...(roleLocationType !== 'remote' ? [{label: 'Work Location', value: roleLocation || ''}] : []),
							{label: 'Job Type', value: jobType || ''},
							{label: 'Engagement Type', value: engagementType === 'employee' ? 'Employee Contract' : 'Contractor Agreement' || ''},
							{label: 'Payment Rate Type', value: paymentRateType === 'hourly' ? 'Hourly Rate' : paymentRateType === 'monthly' ? 'Monthly Salary' : 'Annual Salary' || ''},
							{label: 'Payment Rate Currency', value: paymentRateCurrency || ''},
							{label: 'Payment Rate', value: `${paymentRateFrom || ''} - ${paymentRateTo || ''} ${paymentRateCurrency || ''}` || ''},
							{label: 'Show Salary on Job Post', value: showSalaryOnJob ? 'Yes' : 'No'},
							{
								label: 'Work Schedule Type',
								value:
									workScheduleType === 'fixed'
										? 'Fixed Schedule'
										: workScheduleType === 'flexible'
										? 'Flexible Schedule'
										: workScheduleType === 'shift-based'
										? 'Shift-Based'
										: workScheduleType === 'anytime'
										? 'Anytime'
										: '' || '',
							},
							...(workScheduleType && workScheduleType !== 'anytime' ? [{label: 'Timezone', value: workScheduleTimezone || ''}] : []),
							...(workScheduleType !== 'anytime' && workScheduleType !== 'shift-based' ? [{label: 'Working Days', value: orderDaysOfWeekByDay(workScheduleDays).join(', ') || ''}] : []),
							...(workScheduleType === 'fixed' || workScheduleType === 'flexible'
								? [{label: `${workScheduleType === 'fixed' ? 'Working' : 'Core Working'} Hours`, value: `${workScheduleHoursStart || ''} - ${workScheduleHoursEnd || ''}`}]
								: []),
							...(workScheduleType === 'flexible' && workScheduleFlexibleNotes ? [{label: 'Flexible Schedule Notes', value: workScheduleFlexibleNotes}] : []),
							{label: 'Equipment Allocation', value: equipmentAllocation || ''},
							{label: 'Private Health Insurance (HMO)', value: provideHMO ? 'Company Provided' : 'Not Provided'},
						]}
						renderItem={(item) => (
							<List.Item>
								<List.Item.Meta title={item.label} description={item.value || 'Not specified'} />
							</List.Item>
						)}
					/>

					{questions.length > 0 && (
						<>
							<Title level={5}>Questions</Title>
							<List
								dataSource={questions}
								renderItem={(item, index) => (
									<List.Item>
										<Text strong>{`Question ${index + 1}: `}</Text>
										<Text>{item.question}</Text>
									</List.Item>
								)}
							/>
						</>
					)}
					{shifts.length > 0 && (
						<>
							<Title level={5}>Shifts</Title>
							<List
								dataSource={shifts}
								renderItem={(item, index) => (
									<List.Item>
										<Text strong>{`Shift ${index + 1}: `}</Text>
										<Text>{`${item.day_of_week} - ${item.start_time} - ${item.end_time}`}</Text>
									</List.Item>
								)}
							/>
						</>
					)}
				</Space>
			),
		},
		{
			title: 'Publish',
			content: (
				<Space direction="vertical" size="large" style={{width: '100%'}}>
					<Title level={5}>Ready to publish your job</Title>
					<Text>Please review your payment method below and click publish to post your job.</Text>
					<PricingSelect setSubscriptionType={setSubscriptionType} subscriptionType={subscriptionType} currentUser={currentUser} />

					{!isStaff && subscriptionType !== 'free' && <Payments setPaymentConfirmed={setPaymentConfirmed} headerVisible={false} noPadding={true} />}
					{!paymentConfirmed && subscriptionType !== 'free' && (
						<Alert
							message="Payment Required"
							description="Please add a payment method to continue posting your job. Your card will be charged when you publish the job."
							type="info"
							showIcon
							banner
							className="rounded-md"
						/>
					)}
					{isStaff && (
						<Alert message="Staff Member Benefits" description="You are a staff member, no payment required. Your job will be posted as premium by default." type="success" showIcon />
					)}
				</Space>
			),
		},
	];

	return (
		<Layout style={{background: permColors.white}}>
			<Helmet>
				<title>Post a Job | Permworks</title>
				<meta name="description" content="Advertise your job openings to a global audience. Reach out to talented individuals around the world." />
				<meta name="canonical" content={`https://permworks.com/${ROUTES.POST_JOB}`} />
			</Helmet>

			<Navbar />

			<Content>
				<div className="flex justify-center p-8">
					<div className="w-full max-w-6xl">
						<h1 className="text-left text-2xl sm:text-3xl m-0 font-semibold" style={{color: permColors.darkCharcoal}}>
							Post a job for free
						</h1>
						<h2 className="text-left text-base m-0 font-normal mb-3" style={{color: permColors.darkCharcoal}}>
							Hire the best talent from the Philippines
						</h2>

						<Form form={form} layout="vertical" className="shadow-border rounded-lg p-2 bg-[#fafafa]">
							<div className="flex flex-col md:flex-row gap-6">
								<div className="w-full md:w-1/4 p-3">
									<div className="p-2 flex items-center gap-2 rounded-lg mb-3" style={{color: permColors.charcoal}}>
										<PostAdd style={{fontSize: '20px'}} />
										<h3 className="text-left text-base m-0 font-normal" style={{color: permColors.charcoal}}>
											Post a job form
										</h3>
										<Tooltip className="cursor-pointer" title="Fill out this form to create a new job posting">
											<InfoCircleOutlined style={{fontSize: '14px', background: permColors.backgroundLightCream}} className="p-1 rounded-lg" />
										</Tooltip>
									</div>

									<Steps
										direction={windowWidth <= 768 ? 'horizontal' : 'vertical'}
										current={currentStep}
										items={steps.map((step, index) => ({
											title: step.title,
											disabled: index > currentStep && !completedSteps.has(index - 1),
										}))}
										className="p-2"
										onChange={async (current) => {
											if (current < currentStep) {
												setCurrentStep(current);
												return;
											}
											if (completedSteps.has(current - 1) || current === currentStep + 1) {
												if (current === currentStep + 1) {
													if (await validateStep()) {
														setCurrentStep(current);
													}
												} else {
													setCurrentStep(current);
												}
											}
										}}
									/>
									{/* <Steps
										onChange={setCurrentStep}
										direction={windowWidth <= 768 ? 'horizontal' : 'vertical'}
										current={currentStep}
										items={steps.map((step) => ({title: step.title}))}
										className="p-2"
									/> */}
								</div>

								<div className="w-full md:w-3/4 shadow-border p-4 rounded-md flex flex-col justify-between bg-white">
									{steps[currentStep].content}

									<Space className="mt-4 flex justify-between gap-2 w-full">
										<Popconfirm title="Are you sure you want to clear the form and start again?" onConfirm={clearSavedData} okText="Yes" cancelText="No">
											<Button className="ml-auto" danger icon={<DeleteOutlined />}>
												Start again
											</Button>
										</Popconfirm>
										<div className="flex gap-2">
											{currentStep > 0 && <Button onClick={handleBack}>Back</Button>}

											{currentStep < steps.length - 1 && (
												<Button type="primary" onClick={handleNext}>
													Next
												</Button>
											)}

											{currentStep === steps.length - 1 && (
												<Button
													type="primary"
													onClick={showPublishConfirm}
													loading={isApplying}
													disabled={(!paymentConfirmed && subscriptionType !== 'free') || !subscriptionType}>
													Publish
												</Button>
											)}
										</div>
									</Space>

									{errorMsg && <Alert style={{marginTop: '1rem'}} message={errorMsg} type="error" showIcon />}
								</div>
							</div>
						</Form>
					</div>
				</div>
			</Content>
			<Modal
				closable={false}
				maskClosable={false}
				title="Previous Form Data Found"
				open={isPreviousDataModalVisible}
				onCancel={() => setIsPreviousDataModalVisible(false)}
				footer={[
					<Button key="clear" onClick={clearSavedData} danger>
						Clear form & start again
					</Button>,
					<Button key="load" type="primary" onClick={loadSavedData}>
						Keep previous data
					</Button>,
				]}>
				<p>We found previously saved data for your job posting. Would you like to:</p>
				<ul className="list-disc list-inside">
					<li>Keep the previous data and continue where you left off</li>
					<li>Clear the saved data and start fresh</li>
				</ul>
			</Modal>
			<Modal title="Confirm Publication" open={isModalVisible} onOk={handlePublish} onCancel={() => setIsModalVisible(false)} okText="Yes, Publish" cancelText="Cancel">
				<p>Are you sure you want to publish this job? {subscriptionType !== 'free' && 'Your payment method will be charged.'}</p>
			</Modal>

			<Footer />
		</Layout>
	);
}
