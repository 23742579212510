import {useState, useEffect} from 'react';
import {getConversationsApi, getMessagesApi, sendMessageApi} from '../../api/messagingApi';
import {useWebSocket} from '../../utils/websockets/inboxWebsockets';
import {useAtom} from 'jotai';
import {conversationsAtom, conversationsTriggeredAtom, displayedConversationsAtom} from '../../utils/atoms/dashboardAtoms';

export const useInbox = (currentUser) => {
	const [originalConversations, setOriginalConversations] = useAtom(conversationsAtom);
	const [displayedConversations, setDisplayedConversations] = useAtom(displayedConversationsAtom);
	const [messagesContent, setMessagesContent] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedConversation, setSelectedConversation] = useState(null);
	const [message, setMessage] = useState('');
	const [selectedUser, setSelectedUser] = useState(null);
	const [open, setOpen] = useState(false);
	const [sendMessageLoading, setSendMessageLoading] = useState(false);
	const [fetchingConversations, setFetchingConversations] = useState(false);
	const [fetchingMessages, setFetchingMessages] = useState(false);
	const [conversationsTriggered, setConversationsTriggered] = useAtom(conversationsTriggeredAtom);

	const ws = useWebSocket({
		currentUser,
		selectedConversation,
		setMessagesContent,
		setMessage,
	});

	const handleClose = () => {
		setOpen(false);
		setSelectedConversation(null);
	};

	const sendMessage = async () => {
		if (sendMessageLoading || !message) return;
		setSendMessageLoading(true);
		await sendMessageApi(selectedConversation, message);
		const res2 = await getMessagesApi(selectedConversation);
		setMessagesContent(res2);
		setMessage('');
		setSendMessageLoading(false);
	};

	const fetchMessages = async (id, user) => {
		if (id === selectedConversation || fetchingMessages) return;
		setFetchingMessages(true);
		setMessagesContent([]);
		setSelectedConversation(null);
		setOpen(true);
		const res = await getMessagesApi(id);
		setSelectedUser(user);
		setMessagesContent(res);
		setSelectedConversation(id);
		setFetchingMessages(false);
	};

	const filter = (conversations, filterText) => {
		if (!filterText) return conversations;
		const lowerCaseFilterText = filterText.toLowerCase().trim();
		return conversations.filter((conversation) =>
			conversation.users.some((user) => {
				if (user.email === currentUser.email) return false;
				const {first_name, last_name} = user.profile || {};
				const fullName = `${first_name?.toLowerCase() ?? ''} ${last_name?.toLowerCase() ?? ''}`;
				return fullName.includes(lowerCaseFilterText);
			})
		);
	};

	const runFilter = (e) => {
		const txt = e.target.value || '';
		setDisplayedConversations(filter(originalConversations, txt));
	};

	const fetchInitialConversations = async () => {
		if (fetchingConversations) return;
		setFetchingConversations(true);
		const res = await getConversationsApi();
		if (res === 'error') window.location.reload();
		setOriginalConversations(res);
		setDisplayedConversations(res);
		setFetchingConversations(false);
		setLoading(false);
		setConversationsTriggered(true);

		// Select first conversation if available
		const isMobile = window.innerWidth < 768; // md breakpoint is typically 768px
		if (res?.length > 0 && !isMobile) {
			const firstUser = res[0].users.find((user) => user.id !== currentUser.id);
			fetchMessages(res[0].id, firstUser);
		}
	};

	useEffect(() => {
		if (!conversationsTriggered) {
			fetchInitialConversations();
		} else {
			setLoading(false);
		}
	}, []);

	return {
		originalConversations,
		displayedConversations,
		messagesContent,
		loading,
		selectedConversation,
		message,
		selectedUser,
		open,
		sendMessageLoading,
		fetchingMessages,
		handleClose,
		sendMessage,
		fetchMessages,
		runFilter,
		setMessage,
	};
};
