import {useEffect, useRef, useContext} from 'react';
import {useAtom} from 'jotai';
import {NotificationContext, UserContext} from '../UserContext';
import {wsUrl} from '../../api/Urls';
import {getNotificationsApi} from '../../api/notificationsApi';
import {unreadNotificationsAtom} from '../atoms';

/**
 * Custom hook to handle WebSocket connections for real-time notifications
 * @param {Object} currentUser - The current user object
 * @returns {Object} - Methods and state for notification management
 */
const NotificationsWebSocket = () => {
	const ws = useRef(null);
	const [, setNotifications] = useContext(NotificationContext);
	const [, setUnreadNotifications] = useAtom(unreadNotificationsAtom);
	const [currentUser] = useContext(UserContext);

	const fetchNotifications = async () => {
		try {
			const response = await getNotificationsApi();
			if (response) {
				setNotifications(response);
				const unreadCount = response.filter((notif) => !notif.read).length;
				setUnreadNotifications(unreadCount);
			}
		} catch (error) {
			console.error('Error fetching notifications:', error);
		}
	};

	useEffect(() => {
		// Only establish connection if user is authenticated
		if (currentUser?.id) {
			const userId = currentUser.id;
			const token = localStorage.getItem('accessToken');

			console.log('Starting WebSocket connection...', userId);

			// Create WebSocket connection with authentication token
			ws.current = new WebSocket(`${wsUrl}/ws/notifications/?token=${token}`);

			ws.current.onopen = () => {
				console.log('WebSocket Connected Successfully');
				// Send initial message to identify the user
				ws.current.send(
					JSON.stringify({
						type: 'connect',
						user_id: userId,
					})
				);
			};

			ws.current.onmessage = (event) => {
				console.log('Raw WebSocket message received NotificationsWebSocket');
				// Fetch updated notifications when a new message arrives
				fetchNotifications();
			};

			ws.current.onerror = (error) => {
				console.error('WebSocket connection error:', error);
				console.log('WebSocket State:', ws.current?.readyState);
				console.log('WebSocket URL:', `${wsUrl}/ws/notifications/?token=${token}`);
			};

			ws.current.onclose = (event) => {
				console.log('WebSocket connection closed:', event);
			};

			// Cleanup on unmount
			return () => {
				if (ws.current) {
					ws.current.close(1000, 'Component unmounting');
				}
			};
		}
	}, [currentUser?.id]); // Only re-run if user ID changes

	return null;
};

export default NotificationsWebSocket;
