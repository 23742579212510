import React, {Fragment, useEffect, useState, useContext} from 'react';
import {Link} from 'react-router-dom';
import Hamburger from 'hamburger-react';
import './NavbarDark.scss';
import {useNavigate} from 'react-router-dom';
import {NotificationContext, UserContext} from '../../utils/UserContext';
import {LightLogo} from '../Logos/Logo';
import Loading from '../Loaders/Loading';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {Divider, List, ListItem, ListItemText, ListItemAvatar, Avatar} from '@mui/material';
import {formatDate2} from '../../utils/DateFunctions';
import {markAllAsReadApi} from '../../api/notificationsApi';
import {ROUTES} from '../../utils/ROUTES';
import {useMediaQuery} from '@material-ui/core';
import {firebaseLogout} from '../../api/authApi';

export default function NavbarDark() {
	const navigate = useNavigate();
	const [isOpen, setOpen] = useState(false);
	const [menu, setMenu] = useState(false);
	const [auth, setAuth] = useState(false);
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const logo = LightLogo({fontWeight: 500});
	const [loading, setLoading] = useState(false);
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [profileImage, setProfileImage] = useState('/userIcon.png');
	const [notifications, setNotifications] = useContext(NotificationContext);
	const [unreadNotifications, setUnreadNotifications] = useState();
	const isMobile = useMediaQuery('(max-width: 930px)');

	useState(() => {
		if (currentUser?.profile) {
			setUnreadNotifications(notifications?.filter((notif) => notif.read === false).length);
		}
	}, []);

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => {
		setShow(true);
		try {
			markAllAsReadApi();
			setUnreadNotifications(0);
		} catch (error) {
			return;
		}
	};

	const textStyles = {
		read: {},
		notRead: {
			backgroundColor: '#d8eefe',
		},
	};

	useEffect(() => {
		if (currentUser?.profile) {
			setAuth(true);
			if (currentUser?.profile?.profile_image) {
				const img = new Image();
				img.src = currentUser.profile.profile_image;
				img.onload = () => setProfileImage(currentUser.profile.profile_image);
			}
		}
	}, [currentUser]);

	useEffect(() => {
		if (currentUser?.profile) setAuth(true);
	}, [currentUser]);

	async function logout() {
		try {
			await new Promise((resolve) => {
				localStorage.setItem('currentUser', '');
				localStorage.setItem('accessToken', '');
				localStorage.setItem('token', '');
				setCurrentUser('');
				firebaseLogout();
				resolve();
			});
			setLoading(true);
			window.location.href = ROUTES.AUTHENTICATE;
		} catch (error) {
			console.error(error);
		}
	}

	const guestLinks = () => {
		return (
			<li>
				<Link className="linkButtonDarkBlue" to="/authenticate">
					Login
				</Link>
			</li>
		);
	};
	const authLinks = () => (
		<Fragment>
			<li>
				<img className="dummyIcon" src={profileImage} alt="Icon" onClick={() => setDropdownVisible(!dropdownVisible)} />
				{dropdownVisible && !isMobile && (
					<div className={'visible'}>
						<h6 onClick={() => navigate(ROUTES.EDIT_PROFILE)} style={{cursor: 'pointer'}}>
							Hello {currentUser?.profile && currentUser?.profile?.first_name}!
						</h6>
						<p onClick={() => navigate('/dashboard')}>Dashboard</p>
						<p onClick={logout}>Logout</p>
					</div>
				)}
				{isMobile && (
					<div className={'visible'}>
						<h6 onClick={() => navigate(ROUTES.EDIT_PROFILE)} style={{cursor: 'pointer'}}>
							Hello {currentUser?.profile && currentUser?.profile?.first_name}!
						</h6>
						<p style={{color: '#fffffe'}} onClick={() => navigate('/dashboard')}>
							Dashboard
						</p>
						<p style={{color: '#fffffe'}} onClick={logout}>
							Logout
						</p>
					</div>
				)}
			</li>
		</Fragment>
	);

	return (
		<nav className="navbar-dark">
			<div>
				<div>
					<ul className="bigNav">
						<div className="inline2">
							<li className="logo">{logo}</li>
							<li>
								<Link to={ROUTES.ABOUT_US}>About Us</Link>
							</li>
							<li>
								<Link to={ROUTES.PRICING}>Pricing</Link>
							</li>
							<li>
								<Link to={ROUTES.HOW_IT_WORKS}>How it Works</Link>
							</li>
							<li>
								<Link to={ROUTES.CONTACT_US}>Contact Us</Link>
							</li>
						</div>

						<div className="inline">
							{auth && (
								<li>
									<Badge badgeContent={unreadNotifications} color="error">
										<NotificationsIcon onClick={handleShow} className="notificationsIcon" />
									</Badge>
								</li>
							)}

							<Link className="jobs" to={ROUTES.BROWSE_JOBS}>
								Jobs
							</Link>
							{auth ? authLinks() : guestLinks()}
						</div>
					</ul>
					<ul className="smallNav">
						<li>{logo}</li>
						<li className="rightSide">
							{auth && (
								<Badge badgeContent={unreadNotifications} color="error">
									<NotificationsIcon onClick={handleShow} className="notificationsIcon" />
								</Badge>
							)}
							<span onClick={() => (menu ? setMenu(false) : setMenu(true))}>
								<Hamburger toggled={isOpen} toggle={setOpen} className="hamburger2" color="#fffffe" />
							</span>
						</li>
					</ul>
					{menu && (
						<div className="mobileMenu">
							<ul>
								<li>
									<Link className="jobs" to={ROUTES.BROWSE_JOBS}>
										Jobs
									</Link>
								</li>
								{auth ? authLinks() : guestLinks()}
							</ul>
						</div>
					)}
				</div>
			</div>
			{loading && <Loading />}
			<Offcanvas show={show} onHide={handleClose}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Notifications</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body style={{padding: 0}}>
					<Divider sx={{paddingBottom: '0', marginBottom: '0', backgroundColor: '#5f6c7b80'}} />
					<List sx={{width: '100%', paddingTop: '0', marginTop: '0'}}>
						{notifications?.length > 0 ? (
							notifications?.map((notif) => (
								<div key={notif?.id}>
									<ListItem sx={notif?.read ? textStyles.read : textStyles.notRead}>
										<ListItemText
											primary={notif?.message}
											secondary={formatDate2(notif?.created_at)}
											// primaryTypographyProps={{sx: notif?.read ? textStyles.read : textStyles.notRead}}
										/>
									</ListItem>
									<Divider sx={{backgroundColor: '#5f6c7b80'}} />
								</div>
							))
						) : (
							<>
								<ListItem sx={textStyles.read}>
									<ListItemText
										primary="No notifications yet."
										secondary="Check back later."
										// primaryTypographyProps={{sx: notif?.read ? textStyles.read : textStyles.notRead}}
									/>
								</ListItem>
							</>
						)}
					</List>
				</Offcanvas.Body>
			</Offcanvas>
		</nav>
	);
}
